import { useState } from 'react';
import DataGridItemsRequisicaoEsterno2 from './dataGridItensRequisaoExterno';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetCotacaoQuery } from '../apiSlice';
import { CotacaoFornecedor, CotacaoItem } from '../models';
import { Box, Button, Typography, Modal, Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectIsSidebarOpen } from '../../../layout/layoutSlice';
import AppConfig from '../../../config/app';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40vw',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1
};

const DataGrid = ({
  items,
  cotacaoId,
  updated_at,
  loading,
  cotacaoItems,
  disabled = false
}: {
  items: CotacaoItem[];
  cotacaoId: number;
  updated_at: string;
  loading: boolean;
  disabled: boolean;
  cotacaoItems: CotacaoItem[];
}) => {
  // const dispatch = useDispatch();
  // previne refresh da pagina sem estar salvo
  // const [showDialog, setShowDialog] = useState<boolean>(false);

  const [showCompareDialog, setShowCompareDialog] =
    useState<Record<string, any>>();
  const openCompareDialog = (reqIdx: number) => {
    const filter = cotacaoItems.filter((item) => item.requisicao.id === reqIdx);
    const data = {
      requisicaoId: reqIdx,
      descricao: filter[0].requisicao.descricaoProdutoServico,
      itens: filter.map((item) => ({
        id: item.id,
        fornecedor: item.fornecedor.nome,
        preco: item.precoComImposto,
        qtde: item.qtde,
        total: item.qtde * item.precoComImposto
      }))
    };
    setShowCompareDialog(data);
  };
  const closeCompareDialog = () => {
    setShowCompareDialog(undefined);
  };

  // useEffect(() => {
  //   dispatch(setCotacaoItensChanged(showDialog));
  // }, [showDialog, dispatch]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    }
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));

  return (
    <>
      <DataGridItemsRequisicaoEsterno2
        height="calc(100vh - 200px)"
        disabled={disabled}
        loading={loading}
        items={items}
        cotacaoId={cotacaoId}
        updated_at={updated_at}
        // isChanged={(value) => useCallbackPrompt(value)}
        compare={(row: any) => openCompareDialog(row.requisicaoId)}
      />
      {/* <ComponentModal
        open={showPrompt as boolean}
        close={cancelNavigation as () => void}
        title={'Existem alterações não salvas.'}
        description={'Sair sem salvar?'}
        confirmActionTitle="Não"
        confirmAction={cancelNavigation as () => void}
        degreeActionTitle="Sim"
        degreeAction={confirmNavigation as () => void}
      /> */}
      <Modal
        open={showCompareDialog !== undefined}
        onClose={closeCompareDialog}
        aria-labelledby="compare-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography
            id="compare-modal-title"
            fontWeight={'bold'}
            variant="h6"
            component="h2">
            Comparativo de preços
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {showCompareDialog?.descricao}
          </Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow sx={{ fontWeight: 'bold' }}>
                  <StyledTableCell>Fornecedor</StyledTableCell>
                  <StyledTableCell>Qtde</StyledTableCell>
                  <StyledTableCell>Preço</StyledTableCell>
                  <StyledTableCell>Total</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {showCompareDialog?.itens.map((item: any) => (
                  <StyledTableRow key={item.id}>
                    <TableCell>{item.fornecedor}</TableCell>
                    <TableCell>{item.qtde}</TableCell>
                    <TableCell>
                      {(item.preco as number).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL'
                      })}
                    </TableCell>
                    <TableCell>
                      {(item.total as number).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL'
                      })}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </>
  );
};

export default function TabPanelItemsListDigitar() {
  const drawerOpen = useSelector(selectIsSidebarOpen);
  const { state } = useLocation();

  const navigate = useNavigate();
  const params = useParams<{ id: string; fornecedorId: string }>();

  const { id, fornecedorId } = params;
  const cotacaoId = id ?? '';

  const {
    data: cotacao,
    isLoading,
    isFetching
  } = useGetCotacaoQuery({ id: cotacaoId });
  const filterItens = (item: CotacaoItem) =>
    item.fornecedor.id === parseInt(fornecedorId ?? '0');

  const findFornecedor = (fornecedor: CotacaoFornecedor) =>
    fornecedor.fornecedor.id === parseInt(fornecedorId ?? '0');

  const fornecedor = cotacao?.fornecedores.find(findFornecedor);

  const items = cotacao?.itens ? cotacao.itens.filter(filterItens) : [];

  const goBack = () => {
    const { path, tab } = state as { path: string; tab: number };
    if (!path) {
      return;
    }
    const to = path + '?tab' + tab;

    navigate(to, { replace: true, state: { tab } });
  };

  const loading = isLoading || isFetching;

  return (
    <Box
      sx={(theme) => ({
        maxWidth: drawerOpen
          ? `calc(97.5vw - ${AppConfig.drawerWidth}px)`
          : `calc(97vw - ${theme.spacing(7)})`
      })}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 1,
          justifyContent: 'center'
        }}>
        <Typography variant="h6" component="span" sx={{ flexGrow: 1 }}>
          Pesquisa de preços: <Chip label={fornecedor?.fornecedor.nome} />
        </Typography>
        <Button
          type="button"
          onClick={goBack}
          startIcon={<ReplyAllIcon />}
          variant="outlined">
          Voltar
        </Button>
      </Box>
      <Box>
        <DataGrid
          disabled={
            (fornecedor?.pedidoGerado || cotacao?.situacao?.id === 120) ?? false
          }
          loading={loading}
          items={items}
          cotacaoId={cotacao?.id ?? 0}
          updated_at={cotacao?.updatedAt ?? ''}
          cotacaoItems={cotacao?.itens ?? []}
        />
      </Box>
    </Box>
  );
}
