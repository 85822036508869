import { apiSlice } from '../api/apiSlice';
import { Requisicao, ResultRequisicao } from './models';

const endpointUrl = '/requisicoes';

type FindParams = {
  take: number;
  skip: number;
  orderBy: string;
  sort: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: Record<string, any>;
};

type ManagerRequisicao = {
  id: string;
  updated_at: Date;
  comando: string;
};

function getRequisicoes() {
  return `${endpointUrl}`;
}

function findRequisicoes({ take, skip, orderBy, sort, body }: FindParams) {
  return {
    url: `${endpointUrl}/find/?take=${take}&skip=${skip}&orderBy=${orderBy}&sort=${sort}`,
    method: 'POST',
    body
  };
}

function getRequisicao({ id }: { id: string }) {
  return `${endpointUrl}/${id}`;
}

function createRequisicao(req: Requisicao) {
  return { url: endpointUrl, method: 'POST', body: req };
}

function deleteRequisicao({ id }: { id: string }) {
  return {
    url: `${endpointUrl}/${id}`,
    method: 'DELETE'
  };
}

function updateRequisicao(req: Requisicao) {
  return {
    url: `${endpointUrl}/${req.id}`,
    method: 'PUT',
    body: req
  };
}

function managerRequisicao({ id, updated_at, comando }: ManagerRequisicao) {
  return {
    url: `${endpointUrl}/${id}/${comando}`,
    method: 'PUT',
    body: { id, updated_at }
  };
}

interface TAprovarRequisicaoLote {
  id: number;
  updated_at: Date;
}

function aprovarRequisicaoLote(reqs: TAprovarRequisicaoLote[]) {
  return {
    url: `${endpointUrl}/aprovar/lote`,
    method: 'PUT',
    body: {
      requisicoes: reqs
    }
  };
}

function desaprovarRequisicaoLote(reqs: TAprovarRequisicaoLote[]) {
  return {
    url: `${endpointUrl}/desaprovar/lote`,
    method: 'PUT',
    body: {
      requisicoes: reqs
    }
  };
}

export const requisicaoApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getRequisicoes: query<Requisicao[], void>({
      query: getRequisicoes,
      providesTags: ['Requisicoes']
    }),
    getRequisicao: query<Requisicao, { id: string }>({
      query: getRequisicao,
      providesTags: ['Requisicoes']
    }),
    createRequisicao: mutation<Requisicao, Requisicao>({
      query: createRequisicao,
      invalidatesTags: ['Requisicoes', 'Aprovacoes']
    }),
    deleteRequisicao: mutation<Requisicao, { id: string }>({
      query: deleteRequisicao,
      invalidatesTags: ['Requisicoes', 'Aprovacoes']
    }),
    updateRequisicao: mutation<Requisicao, Requisicao>({
      query: updateRequisicao,
      invalidatesTags: ['Requisicoes', 'Aprovacoes']
    }),
    findRequisicoes: mutation<ResultRequisicao, FindParams>({
      query: findRequisicoes,
      invalidatesTags: ['Requisicoes']
    }),
    managerRequisicao: mutation<void, ManagerRequisicao>({
      query: managerRequisicao,
      invalidatesTags: ['Requisicoes', 'Aprovacoes']
    }),
    aprovarRequisicaoLote: mutation<void, TAprovarRequisicaoLote[]>({
      query: aprovarRequisicaoLote,
      invalidatesTags: ['Requisicoes', 'Aprovacoes']
    }),
    desaprovarRequisicaoLote: mutation<void, TAprovarRequisicaoLote[]>({
      query: desaprovarRequisicaoLote,
      invalidatesTags: ['Requisicoes', 'Aprovacoes']
    })
  })
});

export const {
  useGetRequisicoesQuery,
  useGetRequisicaoQuery,
  useCreateRequisicaoMutation,
  useUpdateRequisicaoMutation,
  useDeleteRequisicaoMutation,
  useFindRequisicoesMutation,
  useManagerRequisicaoMutation,
  useAprovarRequisicaoLoteMutation,
  useDesaprovarRequisicaoLoteMutation
} = requisicaoApiSlice;
