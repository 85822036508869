import { apiSlice } from '../api/apiSlice';
import { User, UserResults } from './models';

const endpointUrl = '/auth/usuarios';

function getUsers() {
  return `${endpointUrl}`;
}

function getUser({ id }: { id: string }) {
  return `${endpointUrl}/${id}`;
}
interface CreateUser {
  user: User;
  type: 'admin' | 'user';
}

function createUser({ user, type }: CreateUser) {
  if (type === 'admin') {
    return { url: endpointUrl + '/admin', method: 'POST', body: user };
  } else {
    return { url: endpointUrl, method: 'POST', body: user };
  }
}

function deleteUser({ id }: { id: string }) {
  return {
    url: `${endpointUrl}/${id}`,
    method: 'DELETE'
  };
}

interface UpdateUser {
  user: User;
  type: 'admin' | 'user';
}

function updateUser({ user, type }: UpdateUser) {
  const body = { ...user, updated_at: user.updatedAt };
  delete body.updatedAt;
  if (type === 'admin') {
    return {
      url: `${endpointUrl}/admin/${user.id}`,
      method: 'PUT',
      body
    };
  } else {
    return {
      url: `${endpointUrl}/${user.id}`,
      method: 'PUT',
      body
    };
  }
}

interface UpdatePassword {
  data: {
    id?: string;
    email: string;
    senha: string;
    updatedAt?: string;
  };
  type: 'admin' | 'user';
}

function updatePassword({ data, type }: UpdatePassword) {
  if (!data.id) throw new Error('Id não informado');
  const body = { ...data, updated_at: data.updatedAt };
  delete body.updatedAt;
  delete body.id;

  if (type === 'admin') {
    return {
      url: `${endpointUrl}/admin/alterarsenha/${data.id}`,
      method: 'PUT',
      body
    };
  } else {
    return {
      url: `${endpointUrl}/alterarsenha/${data.id}`,
      method: 'PUT',
      body
    };
  }
}

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getUsers: query<UserResults, void>({
      query: getUsers,
      providesTags: ['Users']
    }),
    getUser: query<User, { id: string }>({
      query: getUser,
      providesTags: ['User']
    }),
    createUser: mutation<User, CreateUser>({
      query: createUser,
      invalidatesTags: ['Users']
    }),
    deleteUser: mutation<User, { id: string }>({
      query: deleteUser,
      invalidatesTags: ['Users', 'User']
    }),
    updateUser: mutation<User, UpdateUser>({
      query: updateUser,
      invalidatesTags: ['Users', 'User']
    }),
    updatePassword: mutation<User, UpdatePassword>({
      query: updatePassword,
      invalidatesTags: ['Users', 'User']
    })
  })
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useUpdatePasswordMutation
} = usersApiSlice;
