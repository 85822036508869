// material-ui
import { useTheme, styled, alpha } from '@mui/material/styles';
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material';

// styles
const CardWrapper = styled('div')(({ theme }, backgroundColor?: string) => ({
  overflow: 'hidden',
  position: 'relative',
  // backgroundColor: `${alpha(backgroundColor ?? theme.palette.primary.light, 0.5)}`,
  backgroundColor: `${alpha(
    backgroundColor ?? theme.palette.primary.light,
    0.5
  )}`,
  borderRadius: `15px`,
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: `linear-gradient(210.04deg, ${theme.palette.secondary.dark} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
    borderRadius: '50%',
    top: -30,
    right: -180
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: 210,
    height: 210,
    background: `linear-gradient(140.9deg, ${theme.palette.secondary.dark} -14.02%, rgba(144, 202, 249, 0) 70.50%)`,
    borderRadius: '50%',
    top: -160,
    right: -130
  }
}));

// ==============================|| DASHBOARD - TOTAL INCOME LIGHT CARD ||============================== //
interface Props {
  label: string;
  value: number;
  icon?: JSX.Element;
  color?: string;
}
const TotalDashboardCard = ({ label, value, icon, color }: Props) => {
  const theme = useTheme();

  return (
    <CardWrapper color={color}>
      <Box sx={{ p: 2 }}>
        <List sx={{ py: 0 }}>
          <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
            {icon && (
              <ListItemAvatar>
                <Avatar
                  variant="circular"
                  sx={{
                    //   ...theme.typography.commonAvatar,
                    //   ...theme.typography.largeAvatar,
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.secondary.dark
                  }}>
                  {icon}
                </Avatar>
              </ListItemAvatar>
            )}
            <ListItemText
              sx={{
                py: 0,
                mt: 0.45,
                mb: 0.45
              }}
              primary={
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.secondary.dark
                  })}
                  variant="h4">
                  {value}
                </Typography>
              }
              secondary={
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: theme.palette.primary.main,
                    mt: 0.5,
                    fontWeight: 500,
                    fontSize: 11
                  }}>
                  {label}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Box>
    </CardWrapper>
  );
};

export default TotalDashboardCard;
