import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { Fornecedor } from '../../fornecedor/models';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import GridActionConfirmModal from '../../../components/gridActionConfirmModal';
import { Tooltip, Typography } from '@mui/material';

interface Props {
  data?: Partial<Fornecedor>[];
  actionDelete: (id: string | number) => void;
  disabled?: boolean;
  loading?: boolean;
}

export default function TableFornecedor({
  data,
  actionDelete,
  disabled,
  loading = false
}: Props) {
  return (
    <DataGrid
      sx={(style) => ({
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
          fontSize: 13,
          backgroundColor: style.palette.secondary.main,
          color: style.palette.secondary.contrastText
        },
        '& .MuiDataGrid-columnSeparator': {
          color: style.palette.secondary.contrastText
        },
        fontSize: 11,
        overflow: 'hidden',
        '& .MuiDataGrid-columnHeader .MuiCheckbox-root ': {
          color: style.palette.secondary.contrastText
        },
        '& MuiSvgIcon-root, .MuiDataGrid-sortIcon': {
          opacity: 1,
          color: style.palette.secondary.contrastText
        }
      })}
      rows={data ?? []}
      columns={[
        {
          field: 'nome',
          headerName: 'Nome',
          flex: 1,
          sortable: true,
          editable: false,
          filterable: false,
          hideable: false,
          disableColumnMenu: true
        },
        {
          field: 'cnpj',
          headerName: 'CNPJ',
          width: 150,
          sortable: true,
          editable: false,
          filterable: false,
          hideable: false,
          disableColumnMenu: true
        },
        {
          field: 'cidade',
          headerName: 'Cidade',
          width: 120,
          sortable: true,
          editable: false,
          filterable: false,
          hideable: false,
          disableColumnMenu: true
        },
        {
          field: 'uf',
          headerName: 'UF',
          width: 50,
          sortable: true,
          editable: false,
          filterable: false,
          hideable: false,
          disableColumnMenu: true
        },
        {
          field: 'actions',
          headerName: 'Ações',
          width: 100,
          disableColumnMenu: true,
          sortable: true,
          renderCell: function (params: GridRenderCellParams) {
            if (disabled) return <></>;
            return [
              <GridActionConfirmModal
                icon={
                  <Tooltip title="Excluir fornecedor" arrow>
                    <DeleteIcon />
                  </Tooltip>
                }
                label="Exclusão de fornecedor"
                descriptions={
                  <>
                    <Typography variant="body2" color={'red'} gutterBottom>
                      Excluir fornecedor?
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {params.row.nome}
                    </Typography>
                  </>
                }
                className="textError"
                action={() => actionDelete(params.row.id)}
                key={0}
              />
            ];
          }
        }
      ]}
      pageSize={10}
      rowsPerPageOptions={[10]}
      density="compact"
      loading={loading}
      hideFooter
    />
  );
}
