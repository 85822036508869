import { apiSlice } from '../api/apiSlice';
import { Situacao } from './models';

const endpointUrl = '/situacoes';

function getSituacaos() {
  return `${endpointUrl}`;
}

function getSituacao({ id }: { id: string }) {
  return `${endpointUrl}/${id}`;
}

function createSituacao(situacao: Situacao) {
  return { url: endpointUrl, method: 'POST', body: situacao };
}

function deleteSituacao({ id }: { id: string }) {
  return {
    url: `${endpointUrl}/${id}`,
    method: 'DELETE'
  };
}

function updateSituacao(situacao: Situacao) {
  const body: any = { ...situacao, updated_at: situacao.updatedAt };
  delete body.updatedAt;
  delete body.createdAt;

  return {
    url: `${endpointUrl}/${situacao.id}`,
    method: 'PUT',
    body
  };
}

export const situacaoApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getSituacaos: query<Situacao[], void>({
      query: getSituacaos,
      providesTags: ['Situacoes']
    }),
    getSituacao: query<Situacao, { id: string }>({
      query: getSituacao,
      providesTags: ['Situacoes']
    }),
    createSituacao: mutation<Situacao, Situacao>({
      query: createSituacao,
      invalidatesTags: ['Situacoes']
    }),
    deleteSituacao: mutation<Situacao, { id: string }>({
      query: deleteSituacao,
      invalidatesTags: ['Situacoes']
    }),
    updateSituacao: mutation<Situacao, Situacao>({
      query: updateSituacao,
      invalidatesTags: ['Situacoes']
    })
  })
});

export const {
  useGetSituacaosQuery,
  useGetSituacaoQuery,
  useCreateSituacaoMutation,
  useUpdateSituacaoMutation,
  useDeleteSituacaoMutation
} = situacaoApiSlice;
