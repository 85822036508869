import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  alpha
} from '@mui/material';
import { EmailConfig } from './models';
import { FormikProps } from 'formik';

interface Props {
  value: number;
  index: number;
  formik: FormikProps<Partial<EmailConfig>>;
  isLoading?: boolean;
}
export default function TabSMTP({ value, index, formik, isLoading }: Props) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}>
      {value === index && (
        <Box sx={{ p: 2, gap: 2, display: 'flex', flexDirection: 'column' }}>
          {/* smtpServer */}
          <Grid container spacing={2} columns={2}>
            <Grid item xs={1}>
              <Typography>SMTP Server: </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: alpha(theme.palette.background.default, 1),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius
                })}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.smtpServer && formik.errors.smtpServer
                  )}>
                  <TextField
                    name="smtpServer"
                    size="small"
                    disabled={isLoading}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.smtpServer}
                  />
                  {formik.touched.smtpServer && formik.errors.smtpServer && (
                    <FormHelperText error id="error-smtpServer">
                      {formik.errors.smtpServer}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          {/* porta */}
          <Grid container spacing={2} columns={2}>
            <Grid item xs={1}>
              <Typography>Porta/Segurança: </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: alpha(theme.palette.background.default, 1),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius,
                  display: 'flex',
                  gap: 2
                })}>
                <FormControl
                  fullWidth
                  error={Boolean(formik.touched.porta && formik.errors.porta)}>
                  <TextField
                    name="porta"
                    size="small"
                    disabled={isLoading}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.porta}
                  />
                  {formik.touched.porta && formik.errors.porta && (
                    <FormHelperText error id="error-porta">
                      {formik.errors.porta}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth>
                  {/* <FormLabel id="isSecure">Tipo de Pessoa</FormLabel> */}
                  <RadioGroup
                    aria-label="isSecure"
                    sx={{ display: 'flex', flexDirection: 'row' }}
                    name="isSecure"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.isSecure}
                    aria-labelledby="isSecure">
                    <FormControlLabel
                      disabled={isLoading}
                      value={true}
                      control={<Radio />}
                      label="Seguro"
                    />
                    <FormControlLabel
                      disabled={isLoading}
                      value={false}
                      control={<Radio />}
                      label="Não Seguro"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          {/* usuario */}
          <Grid container spacing={2} columns={2}>
            <Grid item xs={1}>
              <Typography>Usuário: </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: alpha(theme.palette.background.default, 1),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius
                })}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.usuario && formik.errors.usuario
                  )}>
                  <TextField
                    name="usuario"
                    size="small"
                    disabled={isLoading}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.usuario}
                  />
                  {formik.touched.usuario && formik.errors.usuario && (
                    <FormHelperText error id="error-usuario">
                      {formik.errors.usuario}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          {/* senha */}
          <Grid container spacing={2} columns={2}>
            <Grid item xs={1}>
              <Typography>Senha: </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: alpha(theme.palette.background.default, 1),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius
                })}>
                <FormControl
                  fullWidth
                  error={Boolean(formik.touched.senha && formik.errors.senha)}>
                  <TextField
                    name="senha"
                    size="small"
                    type="password"
                    disabled={isLoading}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.senha}
                  />
                  {formik.touched.senha && formik.errors.senha && (
                    <FormHelperText error id="error-senha">
                      {formik.errors.senha}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
}
