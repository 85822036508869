import React, { useEffect } from 'react';
import { Fornecedor } from '../../fornecedor/models';
import TableFornecedor from '../common/tableFornecedor';
import { Box } from '@mui/material';
import { CotacaoFornecedor } from '../models';
import {
  useAddCotacaoFornecedorMutation,
  useDeleteCotacaoFornecedorMutation
} from '../apiSlice';
import SearchFornecedorModal from '../../fornecedor/searchFornecedorModal';
import { useSnackbar } from 'notistack';

interface Props {
  value: number;
  index: number;
  situacao: number;
  cotacaoId: number;
  updated_at: string;
  data: CotacaoFornecedor[];
  loading: boolean;
}

export default function TabPanelFornecedores({
  value,
  index,
  cotacaoId,
  updated_at,
  situacao,
  data,
  loading = false
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteCotacaoFornecedor, deleteCotacaoFornecedorStatus] =
    useDeleteCotacaoFornecedorMutation();
  const [addCotacaoFornecedor, addCotacaoFornecedorStatus] =
    useAddCotacaoFornecedorMutation();

  function mapFornecedoresToGridRows(
    data: CotacaoFornecedor[]
  ): Partial<Fornecedor>[] {
    const result = data.map((item: CotacaoFornecedor) => ({
      id: item.fornecedor.id,
      nome: item.fornecedor.nome,
      cnpj: item.fornecedor.cnpj,
      cidade: item.fornecedor.cidade,
      uf: item.fornecedor.uf
    }));

    return result;
  }

  const isLoading =
    loading ||
    addCotacaoFornecedorStatus.isLoading ||
    deleteCotacaoFornecedorStatus.isLoading;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}>
      <Box height={'60vh'} p={1}>
        <Box my={1}>
          <SearchFornecedorModal
            disabled={situacao !== 100}
            action={(fornecedor: Fornecedor) => {
              addCotacaoFornecedor({
                cotacaoId,
                updated_at,
                fornecedorId: Number(fornecedor.id)
              })
                .unwrap()
                .then(() => {
                  enqueueSnackbar('Fornecedor adicionado', {
                    variant: 'success'
                  });
                })
                .catch((error) => {
                  enqueueSnackbar(error.data.message, {
                    variant: 'error'
                  });
                });
            }}
          />
        </Box>
        {data && (
          <TableFornecedor
            data={mapFornecedoresToGridRows(data)}
            disabled={situacao !== 100}
            actionDelete={(id) =>
              deleteCotacaoFornecedor({
                cotacaoId,
                updated_at,
                fornecedorId: Number(id)
              })
                .unwrap()
                .then(() => {
                  enqueueSnackbar('Fornecedor removido', {
                    variant: 'success'
                  });
                })
                .catch((error) => {
                  enqueueSnackbar(error.data.message, {
                    variant: 'error'
                  });
                })
            }
            loading={isLoading}
          />
        )}
      </Box>
    </div>
  );
}
