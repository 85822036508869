import { useEffect, useCallback, useState } from 'react';
import { NavigateNext } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Chip, Paper, Tab, Tabs, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import AppConfig from '../../../config/app';
import { OUTLET_HEIGHT } from '../../../config/theme';
import {
  formRequisicaoValidationSchemaEdit,
  Requisicao,
  REQUISICAO_INITIAL_STATE
} from '../models';
import {
  useGetRequisicaoQuery,
  useManagerRequisicaoMutation,
  useUpdateRequisicaoMutation
} from '../apiSlice';
import { useSnackbar } from 'notistack';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import { useRequisicao } from '../context';
import BlockIcon from '@mui/icons-material/Block';
import DoneIcon from '@mui/icons-material/Done';
import TabPanelEdit from './tabPanelEdit';
import TabPanelLastMovims from './tabPanelLastMovims';
import TabPanelHistory from './tabPanelHistory';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../auth/authSlice';
import { EnumPermissao } from '../../auth/models';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Inner = styled('div')(({ theme }) => ({
  height: OUTLET_HEIGHT
}));

export default function RequisicaoEdit() {
  const id = useParams().id as string;
  const navigate = useNavigate();
  const { state } = useLocation();

  const { filterRequisicoes } = useRequisicao();
  const { data: requisicao, isFetching } = useGetRequisicaoQuery({ id });
  const [managerRequisicao, error] = useManagerRequisicaoMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [updateRequisicao, status] = useUpdateRequisicaoMutation();

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const formik = useFormik({
    initialValues: REQUISICAO_INITIAL_STATE,
    validationSchema: formRequisicaoValidationSchemaEdit,
    onSubmit: (values) => {
      const update = {
        ...values,
        prioridade: values.prioridade?.id,
        situacao: values.situacao?.id,
        departamento: values.departamento?.id,
        solicitante: values.solicitante?.id,
        updated_at: values.updatedAt,
        local: formik.values?.local?.replace(RegExp(/'|\(|\)/, 'g'), '')
      };

      delete update.saldo;
      delete update.updatedAt;
      delete update.createdAt;

      updateRequisicao(update as Requisicao)
        .then(() => {
          filterRequisicoes();
        })
        .then(() => {
          navigate(AppConfig.routes.cadRequisicao);
        });
    }
  });

  const init = useCallback(async () => {
    if (requisicao) {
      await formik.setValues(requisicao);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.setValues, requisicao]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (status.isSuccess) {
      enqueueSnackbar('Requisicao Alterado', { variant: 'success' });
    }
    if (status.error || error.isError) {
      enqueueSnackbar('Error ao Alterar Requisicao', { variant: 'error' });
    }
  }, [enqueueSnackbar, status.error, status.isSuccess, error]);

  const user = useSelector(selectCurrentUser);
  function authorization(action: string) {
    return user.permissoes[EnumPermissao.REQUISICAO].includes(action);
  }

  // funcao para retornar para a ultima tela enviada no state
  function handleBack() {
    const goBack = (state as any)?.goBack;
    if (goBack) {
      navigate(goBack);
    } else {
      navigate(AppConfig.routes.cadRequisicao);
    }
  }

  return (
    <Inner>
      <form>
        <Box display="flex" justifyContent="space-between">
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb">
            <Link color="inherit" to={AppConfig.routes.cadRequisicao}>
              <Typography>Requisições</Typography>
            </Link>
            <Typography>Alterar</Typography>
            <Chip label={formik.values.descricaoProdutoServico} />
          </Breadcrumbs>
          <Box display={'flex'} gap={2}>
            <Button
              type="button"
              onClick={handleBack}
              startIcon={<ReplyAllIcon />}
              variant="outlined">
              Voltar
            </Button>
            {formik.values.situacao?.id === 1 && (
              <Button
                type="button"
                startIcon={<BlockIcon />}
                variant="outlined"
                color="error"
                onClick={() => {
                  if (!authorization('x')) {
                    enqueueSnackbar('Usuário não autorizado', {
                      variant: 'error'
                    });
                    return;
                  }
                  managerRequisicao({
                    id: formik.values.id!.toString(),
                    updated_at: formik.values.updatedAt ?? new Date(),
                    comando: 'cancelar'
                  });
                  filterRequisicoes();
                }}>
                Cancelar
              </Button>
            )}
            {formik.values.situacao?.id === 7 && (
              <Button
                type="button"
                startIcon={<DoneIcon />}
                variant="outlined"
                color="warning"
                onClick={() => {
                  if (!authorization('x')) {
                    enqueueSnackbar('Usuário não autorizado', {
                      variant: 'error'
                    });
                    return;
                  }
                  managerRequisicao({
                    id: formik.values.id!.toString(),
                    updated_at: formik.values.updatedAt ?? new Date(),
                    comando: 'reativar'
                  });
                  filterRequisicoes();
                }}>
                Reativar
              </Button>
            )}
            {formik.values.situacao?.id === 1 && (
              <Button
                type="button"
                startIcon={<DoneAllIcon />}
                variant="contained"
                color="warning"
                onClick={() => {
                  if (!authorization('a')) {
                    enqueueSnackbar('Usuário não autorizado', {
                      variant: 'error'
                    });
                    return;
                  }

                  managerRequisicao({
                    id: formik.values.id!.toString(),
                    updated_at: formik.values.updatedAt ?? new Date(),
                    comando: 'aprovar'
                  });
                  filterRequisicoes();
                }}>
                Aprovar
              </Button>
            )}
            {formik.values.situacao?.id === 2 && (
              <Button
                type="button"
                color="warning"
                startIcon={<RemoveDoneIcon />}
                variant="outlined"
                onClick={() => {
                  if (!authorization('a')) {
                    enqueueSnackbar('Usuário não autorizado', {
                      variant: 'error'
                    });
                    return;
                  }
                  managerRequisicao({
                    id: formik.values.id!.toString(),
                    updated_at: formik.values.updatedAt ?? new Date(),
                    comando: 'desaprovar'
                  });
                  filterRequisicoes();
                }}>
                Desaprovar
              </Button>
            )}
            {formik.values.situacao?.id === 1 && (
              <Button
                type="button"
                onClick={() => {
                  formik.handleSubmit();
                }}
                startIcon={<SaveIcon />}
                variant="contained">
                Salvar
              </Button>
            )}
          </Box>
        </Box>
        <Box height="80vh" padding="10px 0">
          <Paper>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example">
              <Tab label="Dados" />
              <Tab label="Últimas Compras" />
              <Tab label="Histórico" />
            </Tabs>
            <TabPanelEdit
              key={0}
              value={tabValue}
              index={0}
              isFetching={isFetching}
              formik={formik}
            />
            <TabPanelLastMovims
              key={1}
              value={tabValue}
              index={1}
              isFetching={isFetching}
              formik={formik}
            />
            <TabPanelHistory
              key={2}
              value={tabValue}
              index={2}
              isFetching={isFetching}
              formik={formik}
            />
          </Paper>
        </Box>
      </form>
    </Inner>
  );
}
