import { Box, Paper, Stack, Typography } from '@mui/material';
import BackImage from '../../../assets/svg/login_picture.svg';

export default function CotacaoDefault() {
  return (
    <Box width={'98vw'} margin={'0 auto'}>
      <Paper sx={{ p: 2, my: 1 }}>
        <Box
          flex={1}
          height="calc(100vh - 120px)"
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}>
          <Stack gap={2} alignItems={'center'}>
            <Typography fontSize={22} fontWeight={'bold'} color={'secondary'}>
              Coleta de Preços Externa
            </Typography>
            <img src={BackImage} height={'600vh'} width={'100%'} />
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
}
