import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { usersApiSlice } from '../features/users/usersSlice';
import authReducer from '../features/auth/authSlice';
import empresaSlice from '../features/empresa/storeSlice';
import layoutSlice from '../layout/layoutSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    layout: layoutSlice,
    empresa: empresaSlice,
    [usersApiSlice.reducerPath]: usersApiSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(usersApiSlice.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
