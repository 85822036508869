import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Switch,
  TextField
} from '@mui/material';
import { Categoria } from './models';
import { FormikProps } from 'formik';

type Props = {
  formik: FormikProps<Categoria>;
  isLoading?: boolean;
};

export function CategoriaForm({ formik, isLoading = false }: Props) {
  return (
    <Paper>
      <Box height="78vh" py={2} px={2}>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <FormControl
              fullWidth
              error={Boolean(
                formik.touched.descricao && formik.errors.descricao
              )}>
              <TextField
                name="descricao"
                label="Descrição"
                disabled={isLoading}
                inputProps={{ 'data-testid': 'descricao' }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.descricao}
              />
              {formik.touched.descricao && formik.errors.descricao && (
                <FormHelperText error id="error-descricao">
                  {formik.errors.descricao}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              label="Ativo"
              checked={Number(formik.values.ativo) === 1}
              onChange={(e: any) => {
                console.log(e);
                formik.setFieldValue('ativo', e.target.checked ? 1 : 0);
              }}
              onBlur={formik.handleBlur}
              control={<Switch />}
            />
            {formik.touched.descricao && formik.errors.descricao && (
              <FormHelperText error id="error-descricao">
                {formik.errors.descricao}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
