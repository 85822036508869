import { object, string } from 'yup';

export const PRIORIDADE_INITIAL_STATE: Prioridade = {
  id: undefined,
  nome: '',
  corFundo: '',
  corLetra: '',
  style: '',
  ordenacao: 1,
  isActive: false,
  isVisible: false
};

export const formPrioridadeValidationSchemaInsert = object({
  nome: string().required('Campo Requerido')
});

export const formPrioridadeValidationSchemaEdit = object({
  nome: string().required('Campo Requerido')
});

export interface Prioridade {
  id?: number;
  nome: string;
  corFundo: string;
  corLetra: string;
  style: string;
  ordenacao: number;
  isActive: boolean;
  isVisible: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}
