export interface TRow {
  id: number;
  requisicaoId: number;
  descricao: string;
  seuCodigo: string;
  qtde: number;
  preco: number;
  icms: number;
  ipi: number;
  frete: number;
  outrasDespesas: number;
  precoComImposto: number;
  total: number;
  obs: string;
  updated_at: string;
  indisponivel: boolean;
  situacao: {
    id: number;
    nome: string;
    corFundo: string;
    corLetra: string;
  };
  cotado: boolean;
  pedido: string;
}

export function rowKeyGetter(row: TRow): any {
  return row.id;
}
