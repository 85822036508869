import React from 'react';
import { Typography, Box } from '@mui/material';

export default function Page404() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '80vh'
      }}>
      <Typography variant="h1">404</Typography>
      <Typography variant="h6">Página não localizada!</Typography>
    </Box>
  );
}
