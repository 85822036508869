import { apiSlice } from '../api/apiSlice';
import { Departamento } from './models';

const endpointUrl = '/departamentos';

function getDepartamentos() {
  return `${endpointUrl}`;
}

function getDepartamento({ id }: { id: string }) {
  return `${endpointUrl}/${id}`;
}

function createDepartamento(departamento: Departamento) {
  return { url: endpointUrl, method: 'POST', body: departamento };
}

function deleteDepartamento({ id }: { id: string }) {
  return {
    url: `${endpointUrl}/${id}`,
    method: 'DELETE'
  };
}

function updateDepartamento(departamento: Departamento) {
  const body = { ...departamento, updated_at: departamento.updatedAt };
  delete body.updatedAt;
  return {
    url: `${endpointUrl}/${departamento.id}`,
    method: 'PUT',
    body
  };
}

export const departamentoApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getDepartamentos: query<Departamento[], void>({
      query: getDepartamentos,
      providesTags: ['Departamentos']
    }),
    getDepartamento: query<Departamento, { id: string }>({
      query: getDepartamento,
      providesTags: ['Departamentos']
    }),
    createDepartamento: mutation<Departamento, Departamento>({
      query: createDepartamento,
      invalidatesTags: ['Departamentos']
    }),
    deleteDepartamento: mutation<Departamento, { id: string }>({
      query: deleteDepartamento,
      invalidatesTags: ['Departamentos']
    }),
    updateDepartamento: mutation<Departamento, Departamento>({
      query: updateDepartamento,
      invalidatesTags: ['Departamentos', 'Requisicoes']
    })
  })
});

export const {
  useGetDepartamentosQuery,
  useGetDepartamentoQuery,
  useCreateDepartamentoMutation,
  useUpdateDepartamentoMutation,
  useDeleteDepartamentoMutation
} = departamentoApiSlice;
