import { ReactNode } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { IconButton } from '@mui/material';
type TButton = {
  key: number;
  label?: string;
  icon?: ReactNode;
};

interface Props {
  buttons: TButton[];
  selected?: number;
  onSelect: (key: number) => void;
}

export default function SelectButtons({ buttons, selected, onSelect }: Props) {
  if (!buttons) return null;
  if (buttons.length === 0) return null;
  if (!selected) selected = 0;

  return (
    <ButtonGroup variant="contained" aria-label="outlined primary button group">
      {buttons.map((button, index) => {
        if (!button.label && !button.icon) return;
        if (button.label) {
          return (
            <Button
              key={index}
              startIcon={button.icon ?? null}
              sx={(theme) => ({
                backgroundColor:
                  selected === button.key
                    ? theme.palette.primary.main
                    : theme.palette.grey[400]
              })}
              onClick={() => onSelect(button.key)}>
              {button.label}
            </Button>
          );
        } else {
          <IconButton
            key={index}
            sx={(theme) => ({
              backgroundColor:
                selected === index
                  ? theme.palette.primary.main
                  : theme.palette.grey[400]
            })}>
            {button.icon}
          </IconButton>;
        }
      })}
    </ButtonGroup>
  );
}
