import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

interface Props {
  caption?: string;
  value?: string;
  handleSearch: (value: string) => void;
  onFocus?: () => void;
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 1),
  // '&:hover': {
  //   backgroundColor: alpha(theme.palette.primary.main, 0.7)
  // },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  },
  display: 'flex',
  color: theme.palette.common.white
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}));

export default function CustomSearch({
  handleSearch,
  caption,
  value = '',
  onFocus
}: Props) {
  const [searchText, setSearchText] = React.useState(value);
  return (
    <Search>
      <StyledInputBase
        type="search"
        placeholder={caption ?? 'Pesquisar...'}
        inputProps={{ 'aria-label': 'search' }}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearch(searchText);
          }
        }}
        onMouseEnter={() => onFocus && onFocus()}
        onFocus={() => onFocus && onFocus()}
        // quando clicar limpar o campo atualizar o valor
        onBlur={() => {
          if (searchText === '') {
            handleSearch('');
          }
        }}
      />
      <SearchIconWrapper onClick={() => handleSearch(searchText)}>
        <SearchIcon />
      </SearchIconWrapper>
    </Search>
  );
}
