import { apiSlice } from '../api/apiSlice';
import { Classe } from './models';

const endpointUrlSGI = '/classes';

const getClasses = () => {
  return {
    url: endpointUrlSGI,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  };
};

export const produtoApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query }) => ({
    getClasses: query<Classe[], void>({
      query: getClasses,
      providesTags: ['Classes']
    })
  })
});

export const { useGetClassesQuery } = produtoApiSlice;
