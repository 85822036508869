import { apiSlice } from '../api/apiSlice';
import { Categoria } from './models';

const endpointUrl = '/classes';

function getCategorias() {
  return `${endpointUrl}`;
}

function getCategoria({ id }: { id: string }) {
  return `${endpointUrl}/${id}`;
}

function createCategoria(categoria: Categoria) {
  return { url: endpointUrl, method: 'POST', body: categoria };
}

function deleteCategoria({ id }: { id: string }) {
  return {
    url: `${endpointUrl}/${id}`,
    method: 'DELETE'
  };
}

function updateCategoria(categoria: Categoria) {
  const body = { ...categoria };
  return {
    url: `${endpointUrl}/${categoria.id}`,
    method: 'PUT',
    body
  };
}

export const categoriaApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getCategorias: query<Categoria[], void>({
      query: getCategorias,
      providesTags: ['Categorias']
    }),
    getCategoria: query<Categoria, { id: string }>({
      query: getCategoria,
      providesTags: ['Categorias']
    }),
    createCategoria: mutation<Categoria, Categoria>({
      query: createCategoria,
      invalidatesTags: ['Categorias']
    }),
    deleteCategoria: mutation<Categoria, { id: string }>({
      query: deleteCategoria,
      invalidatesTags: ['Categorias']
    }),
    updateCategoria: mutation<Categoria, Categoria>({
      query: updateCategoria,
      invalidatesTags: ['Categorias']
    })
  })
});

export const {
  useGetCategoriasQuery,
  useGetCategoriaQuery,
  useCreateCategoriaMutation,
  useUpdateCategoriaMutation,
  useDeleteCategoriaMutation
} = categoriaApiSlice;
