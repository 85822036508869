import React, { useEffect, useState } from 'react';
import {
  Box,
  Stepper,
  Typography,
  Step,
  StepLabel,
  Button,
  Paper
} from '@mui/material';
import StepDescricao from './stepDescricao';
import StepFornecedores from './stepFornecedores';
import StepRequisicoes from './stepRequisicoes';
import { Fornecedor } from '../../fornecedor/models';
import { Requisicao } from '../../requisicao/models';
import { useFindRequisicoesMutation } from '../../requisicao/apiSlice';
import { useSnackbar } from 'notistack';
import { CreateCotacao, useCreateCotacaoMutation } from '../apiSlice';
import { useNavigate } from 'react-router-dom';
import AppConfig from '../../../config/app';
import { LoadingButton } from '@mui/lab';
import { Classe } from '../../classes/models';

interface ICotacaoInsert {
  obs: string;
  requisicoes: Requisicao[];
  fornecedores: Fornecedor[];
}

export default function CotacaoInsert() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const { enqueueSnackbar } = useSnackbar();
  const [createCotacao, createCotacaoStatus] = useCreateCotacaoMutation();

  const INITAL_VALUES: ICotacaoInsert = {
    obs: '',
    requisicoes: [],
    fornecedores: []
  };

  const [data, setData] = useState<ICotacaoInsert>(INITAL_VALUES);

  const [findRequisicao] = useFindRequisicoesMutation();
  const [requisicoes, setRequisicoes] = useState<Requisicao[]>([]);
  const [rows, setRows] = useState<Requisicao[]>([]);
  const [filterClasse, setFilterClasse] = useState<number[]>([]);
  const [dataClasses, setDataClasses] = useState<Classe[]>([]);

  // load requisicoes
  useEffect(() => {
    findRequisicao({
      take: -1,
      skip: 1,
      orderBy: '',
      sort: '1',
      body: {
        tipoReq: ["'P'", "'S'"],
        situacaoId: [2]
      }
    }).then((res) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (res) {
        setRequisicoes((res as any).data.requisicoes);
        const classes = (res as any).data.requisicoes.map(
          (el: Requisicao) => el.produtoServico?.classe
        );
        const classesFiltered = classes.filter((el: Classe) => el !== null);
        const classesFilteredUnique = classesFiltered
          .reduce((acc: Classe[], cur: Classe) => {
            if (cur === null) return acc;
            const found = acc.find((el: Classe) => el.id === cur.id);
            if (!found) {
              acc.push(cur);
            }
            return acc;
          }, [])
          .filter((el: Classe) => el);
        setDataClasses(classesFilteredUnique);
      }
    });
  }, [findRequisicao]);

  // filtro classes
  useEffect(() => {
    if (requisicoes.length > 0) {
      setRows(
        requisicoes.filter((el) => {
          if (filterClasse.length === 0) return true;
          return filterClasse.includes(
            el.produtoServico?.classe?.id as unknown as number
          );
        })
      );
    }
  }, [requisicoes, filterClasse]);

  // remove data requisicoes quando mudar filtro
  useEffect(() => {
    if (filterClasse.length > 0)
      setData((prev) => ({ ...prev, requisicoes: [] }));
  }, [filterClasse]);

  const steps = ['Início', 'Selecionar Requisições', 'Selecionar Fornecedores'];
  const StepsScreen = [
    <StepDescricao
      key={0}
      value={data.obs}
      onChange={(value) => setData({ ...data, obs: value })}
    />,
    <StepRequisicoes
      key={1}
      values={data.requisicoes}
      onChange={(values) => setData({ ...data, requisicoes: values })}
      requisicoes={rows}
      dataClasses={dataClasses}
      filter={filterClasse}
      setFilterClasse={setFilterClasse}
    />,
    <StepFornecedores
      key={2}
      values={data.fornecedores}
      onChange={(values) => setData({ ...data, fornecedores: values })}
    />
  ];

  const isStepOptional = (step: number) => {
    // return step === 1;
    return false;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    // validacao
    if (activeStep === 0) {
      if (data.obs.length === 0) {
        enqueueSnackbar('Defina uma descrição para a Cotação', {
          variant: 'error'
        });
        return;
      }
    }

    if (activeStep === 1) {
      if (data.requisicoes.length === 0) {
        enqueueSnackbar('Selecione ao menos uma requisição', {
          variant: 'error'
        });
        return;
      }
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleFinish = async () => {
    try {
      if (data.fornecedores.length === 0) {
        enqueueSnackbar('Selecione ao menos um fornecedor', {
          variant: 'error'
        });
        return;
      }

      const format: CreateCotacao = {
        ...data,
        fornecedores: data.fornecedores.map((f) => Number(f.id!)),
        requisicoes: data.requisicoes.map((r) => ({
          requisicaoId: Number(r.id!),
          updated_at: String(r.updatedAt)
        }))
      };

      const result = await createCotacao(format).unwrap();
      console.log('🚀 ~ file: index.tsx:209 ~ handleFinish ~ result:', result);

      enqueueSnackbar('Cotação criada com sucesso', {
        variant: 'success'
      });

      result.id && navigate(AppConfig.routes.cadCotacaoEdit(String(result.id)));
      handleReset();
    } catch (error) {
      enqueueSnackbar('Erro ao criar cotação', {
        variant: 'error'
      });
    }
  };

  return (
    <Paper sx={{ width: '100%', height: '86vh', p: 2 }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Facultativo</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Nova Cotação</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {StepsScreen[activeStep]}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              variant="contained"
              color="warning"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}>
              Voltar
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button
                variant="contained"
                color="warning"
                onClick={handleSkip}
                sx={{ mr: 1 }}>
                Avançar
              </Button>
            )}
            <LoadingButton
              loading={createCotacaoStatus.isLoading}
              variant="contained"
              color={activeStep === steps.length - 1 ? 'success' : 'warning'}
              onClick={
                activeStep === steps.length - 1 ? handleFinish : handleNext
              }>
              {activeStep === steps.length - 1 ? 'Finalizar' : 'Avançar'}
            </LoadingButton>
          </Box>
        </React.Fragment>
      )}
    </Paper>
  );
}
