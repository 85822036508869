import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { Requisicao } from '../../requisicao/models';
import TableRequisicao from '../common/tableRequisicoes';
import { CotacaoRequisicao } from '../models';
import {
  useAddCotacaoRequisicaoMutation,
  useDeleteCotacaoRequisicaoMutation
} from '../apiSlice';
import SearchRequisicaoModal from '../../requisicao/searchRequisicaoModal';
import { useSnackbar } from 'notistack';

interface Props {
  value: number;
  index: number;
  cotacaoId: number;
  updated_at: string;
  situacao: number;
  data: CotacaoRequisicao[];
}

export default function TabPanelRequisicoes({
  value,
  index,
  cotacaoId,
  updated_at,
  situacao,
  data
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [addCotacaoRequisicao] = useAddCotacaoRequisicaoMutation();
  const [deleteCotacaoRequisicao] = useDeleteCotacaoRequisicaoMutation();

  function mapRequisicoesToGridRows(
    data: CotacaoRequisicao[]
  ): Partial<CotacaoRequisicao>[] {
    const result = data.map((item: CotacaoRequisicao) => ({
      id: item.requisicao.id,
      tipoReq: item.requisicao.tipoReq, // P | S
      solicitante: item.requisicao.solicitante,
      departamento: item.requisicao.departamento,
      codigoSgi: item.requisicao?.produtoServico?.codigoSgi,
      descricaoProdutoServico: item.requisicao.descricaoProdutoServico,
      unidade: item.requisicao.unidade,
      qtde: item.requisicao.qtde,
      situacao: item.requisicao.situacao,
      prioridade: item.requisicao.prioridade,
      // prioridadeId: item.requisicao.prioridadeId,
      obs: item.requisicao.obs,
      createdAt: item.createdAt, // data que foi inserida na cotação
      updatedAt: item.requisicao.updatedAt,
      pedido: item.requisicao.pedido?.id ?? null
    }));

    return result as unknown as Partial<Requisicao>[];
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}>
      <Box height={'71vh'} p={1}>
        {/* <Box my={1}>
          {situacao === 100 && (
            <SearchRequisicaoModal
              initialArgs={{ situacaoId: [2] }}
              action={(requisicao: Requisicao) => {
                addCotacaoRequisicao({
                  cotacaoId,
                  updated_at,
                  requisicao: {
                    requisicaoId: Number(requisicao.id),
                    updated_at: requisicao.updatedAt?.toString() || ''
                  }
                });
              }}
            />
          )}
        </Box> */}
        {data && (
          <TableRequisicao
            cotacao={{ cotacaoId, updated_at }}
            data={mapRequisicoesToGridRows(data)}
            disabled={situacao !== 100}
            actionDelete={(id, updatedAt) => {
              deleteCotacaoRequisicao({
                cotacaoId,
                updated_at,
                requisicao: {
                  requisicaoId: Number(id),
                  updated_at: updatedAt
                }
              })
                .unwrap()
                .then(() => {
                  enqueueSnackbar('Requisição removida', {
                    variant: 'success'
                  });
                })
                .catch((error: any) => {
                  enqueueSnackbar(
                    error?.data?.message || 'Error desconhecido',
                    {
                      variant: 'error'
                    }
                  );
                });
            }}
            toolbarComponents={
              <SearchRequisicaoModal
                key={1}
                disabled={situacao !== 100}
                initialArgs={{ situacaoId: [2] }}
                action={(requisicao: Requisicao) => {
                  addCotacaoRequisicao({
                    cotacaoId,
                    updated_at,
                    requisicao: {
                      requisicaoId: Number(requisicao.id),
                      updated_at: requisicao.updatedAt?.toString() || ''
                    }
                  })
                    .unwrap()
                    .then(() => {
                      enqueueSnackbar('Requisição adicionada', {
                        variant: 'success'
                      });
                    })
                    .catch((error: any) => {
                      enqueueSnackbar(
                        error?.data?.message || 'Error desconhecido',
                        {
                          variant: 'error'
                        }
                      );
                    });
                }}
              />
            }
          />
        )}
      </Box>
    </div>
  );
}
