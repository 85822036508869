import { Outlet } from 'react-router-dom';
import HeaderExterno from '../../../layout/headerExterno';

export default function CotacaoExterno() {
  return (
    <>
      <HeaderExterno title="Cotação Externa" />
      <Outlet />
    </>
  );
}
