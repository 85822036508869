import { NavigateNext } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Chip, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

import { Link, useParams } from 'react-router-dom';
import AppConfig from '../../config/app';
import { OUTLET_HEIGHT } from '../../config/theme';
import { PrioridadeForm } from './form';
import {
  formPrioridadeValidationSchemaEdit,
  PRIORIDADE_INITIAL_STATE
} from './models';
import { useGetPrioridadeQuery, useUpdatePrioridadeMutation } from './apiSlice';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Inner = styled('div')(({ theme }) => ({
  height: OUTLET_HEIGHT
}));

export default function PrioridadeEdit() {
  const id = useParams().id as string;
  const { data: user, isFetching } = useGetPrioridadeQuery({ id });
  const { enqueueSnackbar } = useSnackbar();
  const [updatePrioridade, status] = useUpdatePrioridadeMutation();

  const formik = useFormik({
    initialValues: PRIORIDADE_INITIAL_STATE,
    validationSchema: formPrioridadeValidationSchemaEdit,
    onSubmit: (values) => {
      updatePrioridade(values);
    }
  });

  useEffect(() => {
    if (user) {
      formik.setValues(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (status.isSuccess) {
      enqueueSnackbar('Prioridade Alterado', { variant: 'success' });
    }
    if (status.error) {
      enqueueSnackbar('Error ao Alterar Prioridade', { variant: 'error' });
    }
  }, [enqueueSnackbar, status.error, status.isSuccess]);

  return (
    <Inner>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" justifyContent="space-between">
          <Breadcrumbs
            sx={{ py: 1 }}
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb">
            <Typography>Cadastro</Typography>
            <Link color="inherit" to={AppConfig.routes.cadPrioridade}>
              <Typography fontWeight={'bold'}>Prioridades</Typography>
            </Link>
            <Typography>Alterar</Typography>
            <Chip label={formik.values.nome} />
          </Breadcrumbs>
          <Box display={'flex'} gap={2}>
            <Button
              type="button"
              component={Link}
              to={AppConfig.routes.cadPrioridade}
              startIcon={<ReplyAllIcon />}
              variant="outlined">
              Voltar
            </Button>
            <Button type="submit" startIcon={<SaveIcon />} variant="contained">
              Salvar
            </Button>
          </Box>
        </Box>
        <Box height="80vh" padding="10px 0">
          <PrioridadeForm isLoading={isFetching} formik={formik} />
        </Box>
      </form>
    </Inner>
  );
}
