/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import FilterInterface from './filters.interface';
import { Box, Typography, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/pt-br';
import { isEqual } from 'lodash';

export default function PeriodFilter({
  dataKey,
  caption,
  value,
  onChange
}: FilterInterface) {
  const period = React.useRef<[Date | null, Date | null]>(value);

  const lastPeriod = React.useRef<[Date | null, Date | null]>([null, null]);
  const [updateData, setUpdateData] = React.useState(0);

  React.useEffect(() => {
    if (updateData === 0) {
      return;
    }
    if (isEqual(lastPeriod.current, period)) {
      return;
    }
    lastPeriod.current = period.current;
    onChange({ [dataKey]: period.current });
  }, [dataKey, onChange, updateData]);

  return (
    <LocalizationProvider
      localeText={{ clearButtonLabel: 'Limpar', todayButtonLabel: 'Hoje' }}
      dateAdapter={AdapterMoment}
      adapterLocale="pt-br">
      <Box display={'flex'} flexDirection={'column'} gap={1}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}>
          <Typography
            fontSize={12}
            fontWeight={value?.length > 0 ? 'bold' : 'normal'}
            color={'primary'}>
            {caption}
          </Typography>
          {value && JSON.stringify(value) !== JSON.stringify([null, null]) && (
            <Typography
              fontSize={9}
              onClick={() => {
                onChange({ [dataKey]: [null, null] });
              }}
              sx={(theme) => ({
                cursor: 'pointer',
                '&:hover': {
                  color: theme.palette.primary.main,
                  textDecoration: 'underline'
                }
              })}
              variant="overline">
              Limpar
            </Typography>
          )}
        </Box>
        <DatePicker
          label="Inicial"
          openTo="day"
          onChange={(newValue: any) => {
            if (!newValue) {
              const newPeriod = period.current;
              newPeriod[0] = null;
              period.current = newPeriod;
              setUpdateData((prev) => prev + 1);
              return;
            }
            if (newValue.format('YYYY-MM-DD') === 'Invalid date') return;
            if (newValue) {
              const newPeriod = period.current;
              newPeriod[0] = newValue.format('YYYY-MM-DD');
              period.current = newPeriod;
              return;
            }
          }}
          value={value && value[0] ? value[0] : null}
          renderInput={(params: any) => <TextField {...params} size="small" />}
          inputFormat="DD/MM/YYYY"
          componentsProps={{
            actionBar: {
              actions: ['clear', 'today']
            }
          }}
          onAccept={() => {
            setUpdateData((prev) => prev + 1);
          }}
        />
        <DatePicker
          label="Final"
          openTo="day"
          // localeText={{ clearButtonLabel: 'Limpar', todayButtonLabel: 'Hoje' }}
          onChange={(newValue: any) => {
            if (!newValue) {
              const newPeriod = period.current;
              newPeriod[1] = null;
              period.current = newPeriod;
              setUpdateData((prev) => prev + 1);
              return;
            }
            if (newValue.format('YYYY-MM-DD') === 'Invalid date') return;
            if (newValue) {
              const newPeriod = period.current;
              newPeriod[1] = newValue.format('YYYY-MM-DD');
              period.current = newPeriod;
              return;
            }
          }}
          onAccept={() => {
            setUpdateData((prev) => prev + 1);
          }}
          value={value && value[1] ? value[1] : null}
          renderInput={(params: any) => <TextField {...params} size="small" />}
          inputFormat="DD/MM/YYYY"
          componentsProps={{
            actionBar: {
              actions: ['clear', 'today']
            }
          }}
        />
      </Box>
    </LocalizationProvider>
  );
}
