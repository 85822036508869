import React from 'react';
import { Box, CircularProgress } from '@mui/material';

interface Props {
  minHeight?: number;
}

export default function PageLoading({ minHeight = 80 }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: `${minHeight}vh}`
      }}>
      <CircularProgress disableShrink />
    </Box>
  );
}
