import { apiSlice } from '../api/apiSlice';
import { EmailConfig } from './models';

const endpointUrlEmail = '/emailConfig';
const endpointUrlEmpresa = '/empresas';

function getEmailConfig() {
  return `${endpointUrlEmail}`;
}

function setEmailConfig(emailConfig: EmailConfig) {
  return {
    // url: `${endpointUrlEmail}`,
    url: `${endpointUrlEmail}/${emailConfig.id}`,
    method: 'PUT',
    body: emailConfig
  };
}

interface EmpresaConfig {
  id: number;
  qtdeDigitosCampoQtde: number;
  qtdeDigitosCampoUnitário: number;
  updated_at: string;
}

function setEmpresaConfig(empresaConfig: EmpresaConfig) {
  return {
    url: endpointUrlEmpresa,
    method: 'PUT',
    body: { ...empresaConfig }
  };
}

export const configApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getEmailConfig: query<EmailConfig, void>({
      query: getEmailConfig,
      transformResponse: (baseQueryReturnValue: any[]) =>
        baseQueryReturnValue[0],
      providesTags: ['Config']
    }),
    setEmailConfig: mutation<EmailConfig, EmailConfig>({
      query: setEmailConfig,
      invalidatesTags: ['Config']
    }),
    setEmpresaConfig: mutation<void, EmpresaConfig>({
      query: setEmpresaConfig,
      invalidatesTags: ['Config']
    })
  })
});

export const {
  useGetEmailConfigQuery,
  useSetEmailConfigMutation,
  useSetEmpresaConfigMutation
} = configApiSlice;
