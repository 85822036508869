import React from 'react';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import { Switch } from '@mui/material';

export default function GridEditCheck(props: GridRenderEditCellParams) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = () => {
    apiRef.current.setEditCellValue({ id, field, value: !value });
  };

  return <Switch checked={value} onChange={handleChange} />;
}
