import { NavigateNext, Delete } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Chip, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

import { Link, useNavigate, useParams } from 'react-router-dom';
import AppConfig from '../../config/app';
import { OUTLET_HEIGHT } from '../../config/theme';
import { FornecedorForm } from './form';

import {
  formFornecedorValidationSchemaEdit,
  Fornecedor,
  FORNECEDOR_INITIAL_STATE
} from './models';

import {
  useGetFornecedorQuery,
  useUpdateFornecedorMutation,
  useDeleteFornecedorMutation
} from './apiSlice';

import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import ConfirmModal from '../../components/ConfirmModal';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Inner = styled('div')(({ theme }) => ({
  height: OUTLET_HEIGHT
}));

export default function FornecedorEdit() {
  const id = useParams().id as string;
  const { data: fornecedor, isFetching } = useGetFornecedorQuery({ id });
  const { enqueueSnackbar } = useSnackbar();
  const [updateFornecedor] = useUpdateFornecedorMutation();
  const [deleteFornecedor] = useDeleteFornecedorMutation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: FORNECEDOR_INITIAL_STATE,
    validationSchema: formFornecedorValidationSchemaEdit,
    onSubmit: (values) => {
      const newValues: Record<string, any | null> = { ...values };

      newValues.updated_at = newValues.updatedAt;
      delete newValues.updatedAt;
      delete newValues.createdAt;

      if (!newValues.email) {
        Object.assign(newValues, { email: null });
      }

      if (!newValues.fone) {
        Object.assign(newValues, { fone: null });
      }
      if (!newValues.fone2) {
        Object.assign(newValues, { fone2: null });
      }
      if (!newValues.cel) {
        Object.assign(newValues, { cel: null });
      }

      updateFornecedor(newValues as any)
        .unwrap()
        .then(() => {
          enqueueSnackbar('Fornecedor alterado com sucesso', {
            variant: 'success'
          });
        })
        .catch((error) => {
          enqueueSnackbar(error.data.message, {
            variant: 'error'
          });
          // if (fornecedor) {
          //   formik.setValues(fornecedor);
          // }
        });
    }
  });

  console.log(formik.errors);

  const handleDelete = () => {
    if (id) {
      deleteFornecedor({ id })
        .unwrap()
        .then(() => {
          enqueueSnackbar('Fornecedor excluido com sucesso', {
            variant: 'success'
          });
          navigate(AppConfig.routes.cadFornecedor);
        })
        .catch((error) => {
          enqueueSnackbar(error.data.message, {
            variant: 'error'
          });
        });
    }
  };

  useEffect(() => {
    if (fornecedor) {
      const newFornecedor = { ...fornecedor };
      if (newFornecedor.email === null) {
        newFornecedor.email = '';
      }
      if (newFornecedor.cel === null) {
        newFornecedor.cel = '';
      }
      if (newFornecedor.fone === null) {
        newFornecedor.fone = '';
      }
      if (newFornecedor.fone2 === null) {
        newFornecedor.fone2 = '';
      }
      formik.setValues(newFornecedor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fornecedor]);

  return (
    <Inner>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" justifyContent="space-between">
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb">
            <Typography>Cadastro</Typography>
            <Link color="inherit" to={AppConfig.routes.cadFornecedor}>
              <Typography>Fornecedors</Typography>
            </Link>
            <Typography>Alterar</Typography>
            <Chip label={formik.values.nome} />
          </Breadcrumbs>
          <Box display={'flex'} gap={2}>
            <Button
              type="button"
              component={Link}
              to={AppConfig.routes.cadFornecedor}
              startIcon={<ReplyAllIcon />}
              variant="outlined">
              Voltar
            </Button>
            {id && (
              <ConfirmModal
                title="Exclusão de Fornecedor"
                description={`Confirmar exclusão do fornecedor: ${formik.values.nome}`}
                component={
                  <Button
                    startIcon={<Delete />}
                    color="error"
                    type="button"
                    variant="contained">
                    Excluir
                  </Button>
                }
                action={handleDelete}
              />
            )}
            <Button type="submit" startIcon={<SaveIcon />} variant="contained">
              Salvar
            </Button>
          </Box>
        </Box>
        <Box height="80vh" padding="10px 0">
          <FornecedorForm isLoading={isFetching} formik={formik} />
        </Box>
      </form>
    </Inner>
  );
}
