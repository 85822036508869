import { CotacaoFornecedor, CotacaoItem, CotacaoRequisicao } from '../models';
import { fornecedorComPedido } from './utils/funcoesTabResumo';

export type FornecedorItem = {
  fornecedor: CotacaoFornecedor;
  itens: CotacaoItem[];
  total: number;
};

interface ResultMenorPreco {
  itens: CotacaoItem[];
  total: number;
}

interface ResultPorFornecedor {
  itens: FornecedorItem[];
}

export const getItensPorMenorPreco = (
  requisicoes: CotacaoRequisicao[],
  itens: CotacaoItem[]
): ResultMenorPreco => {
  const itensPorMenorPreco: CotacaoItem[] = [];
  let total = 0;

  requisicoes.forEach((requisicao) => {
    const itensDaRequisicao = itens.filter(
      (item) =>
        item.requisicao.id === requisicao.requisicao.id &&
        item.situacao.id === 220
    );
    const itemComMenorPreco = itensDaRequisicao.reduce(
      (acc: CotacaoItem, current) => {
        if (acc.precoComImposto === 0) return acc;
        acc = acc.precoComImposto < current.precoComImposto ? acc : current;

        return acc;
      },
      { preco: 0 } as CotacaoItem
    );

    itensPorMenorPreco.push(itemComMenorPreco);
    total += itemComMenorPreco.preco;
  });

  const filterItensPorMenorPreco = itensPorMenorPreco.filter(
    (item) => item?.situacao?.id === 220 || false
  );

  return { itens: filterItensPorMenorPreco, total };
};

// leva em conta apenas se tem cotação com o menor preço em mais de um fornecedor
export const selectMenorPreco = (
  requisicoes: CotacaoRequisicao[],
  itens: CotacaoItem[],
  fornecedoresList: CotacaoFornecedor[]
) => {
  const ret: CotacaoItem[] = [];

  const requicoesFilter = requisicoes.filter((req) => !req.requisicao.pedido);
  requicoesFilter.forEach((requisicao) => {
    const reqId = requisicao.requisicao.id;
    const requisicaoItensFilter = itens.filter((item) => {
      return (
        item.requisicao.id === reqId &&
        item.situacao.id === 220 &&
        item.precoComImposto > 0 &&
        // se o fornecedor tem pedido gerado fica fora da seleção
        !fornecedorComPedido(item.fornecedor?.id ?? 0, fornecedoresList)
      );
    });

    const precos = requisicaoItensFilter.map((item) => item.precoComImposto);
    const menorPreco = Math.min(...precos);

    if (requicoesFilter.length === 0) {
      return;
    }

    if (requisicaoItensFilter.length === 1) {
      ret.push(requisicaoItensFilter[0]);
    } else {
      const filter = requisicaoItensFilter.filter(
        (item) => item.precoComImposto === menorPreco
      );
      // console.log('🚀 ~ filter:', filter);
      ret.push(filter[0]);
    }
  });

  // console.log({ ret: ret.map((r) => ({ [`${r.requisicao.id}`]: r })) });

  return ret;
};

export const getItemsPorFornecedor = (
  fornecedores: CotacaoFornecedor[],
  itens: CotacaoItem[]
): ResultPorFornecedor => {
  const itensPorFornecedor: FornecedorItem[] = [];

  fornecedores.forEach((fornecedor) => {
    const itensDoFornecedor = itens.filter(
      (item) => item.fornecedor.id === fornecedor.fornecedor.id
    );

    const total = itensDoFornecedor.reduce((acc, current) => {
      return acc + current.preco;
    }, 0);

    itensPorFornecedor.push({
      fornecedor,
      itens: itensDoFornecedor,
      total
    });
  });
  return { itens: itensPorFornecedor };
};

export function formatTotalItem(item: CotacaoItem) {
  const value = item.preco * item.qtde;
  return value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });
}

export function formatTotalItems(items: CotacaoItem[]) {
  const value = items.reduce((acc, cur) => acc + cur.qtde * cur.preco, 0);
  return value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });
}
