import { NavigateNext } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

import { Link, useNavigate } from 'react-router-dom';
import AppConfig from '../../config/app';
import { OUTLET_HEIGHT } from '../../config/theme';
import { UserForm } from './form';
import { formUserValidationSchemaInsert, USER_INITIAL_STATE } from './models';
import { useCreateUserMutation } from './usersSlice';
import { useSnackbar } from 'notistack';
// import { useSelector } from 'react-redux';
// import { selectCurrentUser } from '../auth/authSlice';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Inner = styled('div')(({ theme }) => ({
  height: OUTLET_HEIGHT
}));

export default function UsersInsert() {
  const navigate = useNavigate();
  // const authUser = useSelector(selectCurrentUser);
  const { enqueueSnackbar } = useSnackbar();
  const [createUser, status] = useCreateUserMutation();

  const formik = useFormik({
    initialValues: USER_INITIAL_STATE,
    validationSchema: formUserValidationSchemaInsert,
    onSubmit: (values) => {
      if (!values.senha || !values.senha2) {
        enqueueSnackbar('Preencha os campos', { variant: 'error' });
        return;
      }

      if (values?.senha.length < 6) {
        enqueueSnackbar('A senha deve ter no mínimo 6 caracteres', {
          variant: 'error'
        });
        return;
      }

      if (values.senha !== values.senha2) {
        enqueueSnackbar('As senhas não conferem', { variant: 'error' });
        return;
      }
      delete values.id;
      delete values.senha2;
      const type = values.perfil === 'ADMIN' ? 'admin' : 'user';
      createUser({
        user: values,
        type
      })
        .unwrap()
        .then(() => {
          navigate(AppConfig.routes.cadUser);
        })
        .catch((err) => {
          enqueueSnackbar(err.data.message, { variant: 'error' });
        });
    }
  });

  return (
    <Inner>
      <form onSubmit={formik.handleSubmit} autoComplete={'false'}>
        <Box display="flex" justifyContent="space-between">
          <Breadcrumbs
            sx={{ py: 1 }}
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb">
            <Typography>Cadastro</Typography>
            <Link color="inherit" to={AppConfig.routes.cadUser}>
              <Typography fontWeight={'bold'}>Usuários</Typography>
            </Link>
            <Typography fontWeight={'bold'}>Inserir</Typography>
          </Breadcrumbs>
          <Box display={'flex'} gap={2}>
            <Button
              type="button"
              component={Link}
              to={AppConfig.routes.cadUser}
              startIcon={<ReplyAllIcon />}
              variant="outlined">
              Voltar
            </Button>
            <Button type="submit" startIcon={<SaveIcon />} variant="contained">
              Salvar
            </Button>
          </Box>
        </Box>
        <Box height="80vh" padding="10px 0">
          <UserForm
            isLoading={status.isLoading}
            formik={formik}
            reset={() => null}
            applyAll={() => null}
          />
        </Box>
      </form>
    </Inner>
  );
}
