import { Pedido } from '../../../requisicao/models';
import { Situacao } from '../../../situacao/models';

export type TRow = {
  id: number;
  situacao: Situacao;
  updated_at: string;
  fornecedorId: number;
  requisicaoId: number;
  situacaoId: number;
  descricao: string;
  qtde: number;
  ipi: number;
  icms: number;
  frete: number;
  outrasDespesas: number;
  seuCodigo: string;
  preco: number;
  precoComImposto: number;
  total: number;
  obs: string;
  previsao: string | null;
  pedido: Pedido | null;
};

export function rowKeyGetter(row: TRow): any {
  return row.id;
}
