import { CheckCircle } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

interface Props {
  item: Record<string, any>;
  showMarcador?: boolean;
  selectItem?: (item: Record<string, any>) => void;
  deselectItem?: (item: Record<string, any>) => void;
  novaCor?: string;
}
const ShowPrecoPercentual = ({
  item,
  showMarcador = true,
  novaCor,
  selectItem,
  deselectItem
}: Props) => {
  return (
    <div
      style={{
        fontWeight: item.isSelected ? 'bold' : 'normal',
        display: 'grid',
        gridTemplateColumns: '2fr 1fr 1fr',
        gap: '0.5em',
        justifyItems: 'center',
        alignItems: 'center',
        width: '100%'
      }}>
      <strong
        style={{
          width: '100%',
          textAlign: 'right',
          color: novaCor ? novaCor : item.isMenorPreco ? '#46c346' : '#c34a46'
        }}>
        {(item.precoComImposto ?? 0).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </strong>
      <span
        style={{
          fontSize: '0.7em',
          color:
            (item.percentual ?? 0) === 0
              ? novaCor
                ? novaCor
                : '#46c346'
              : '#c34a46'
        }}>
        {`${
          (item.percentual ?? 0) > 0
            ? ' ⇡ ' +
              item.percentual.toLocaleString('pt-BR', {
                // alterar o arredondamento do percentual
                minimumFractionDigits: 1,
                maximumFractionDigits: 1
              }) +
              '%'
            : ''
        }`}
      </span>

      {showMarcador ? (
        <span key={0} style={{ textAlign: 'right' }}>
          {item.isSelected ? (
            <Tooltip title="Desmarcar este item" arrow>
              <button
                onClick={() => {
                  deselectItem?.(item);
                }}
                style={{
                  border: 'none',
                  background: 'none',
                  cursor: 'revert',
                  padding: '5px'
                }}>
                <CheckCircle color="success" fontSize="small" />
              </button>
            </Tooltip>
          ) : (
            <Tooltip title="Selecionar este item" arrow>
              <button
                className="buttom-selecionar"
                style={{
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                  padding: '5px'
                }}
                onClick={() => {
                  selectItem?.(item);
                }}>
                <CheckCircle fontSize="small" color="disabled" />
              </button>
            </Tooltip>
          )}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ShowPrecoPercentual;
