import { Box, Grid, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

import { useSnackbar } from 'notistack';
import { Pedido } from '../../pedido/models';

interface Props {
  value: number;
  index: number;
  pedido: Pedido;
}

export default function TabPanelInfo({ index, value, pedido }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}>
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2} columns={2}>
            {/* situacao */}
            <Grid item xs={1}>
              <Typography>Situação: </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: alpha(theme.palette.grey[500], 0.15),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius
                })}>
                <Typography>{pedido?.situacao?.nome ?? ' '}</Typography>
              </Box>
            </Grid>

            {/* data */}
            <Grid item xs={1}>
              <Typography>Data: </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: alpha(theme.palette.grey[500], 0.15),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius
                })}>
                <Typography>
                  {new Date(pedido.createdAt).toLocaleDateString('pt-BR')}
                </Typography>
              </Box>
            </Grid>

            {/* fornecedor */}
            <Grid item xs={1}>
              <Typography>Fornecedor: </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: alpha(theme.palette.grey[500], 0.15),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius
                })}>
                <Typography>
                  {pedido.fornecedor?.nome ?? 'Não informado'}
                </Typography>
              </Box>
            </Grid>

            {/* Previsão de entrega */}
            <Grid item xs={1}>
              <Typography>Previsão de entrega: </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: alpha(theme.palette.grey[500], 0.15),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius
                })}>
                <Typography>
                  {new Date(pedido.previsao).toLocaleDateString('pt-BR')}
                </Typography>
              </Box>
            </Grid>

            {/* Movimento */}
            <Grid item xs={1}>
              <Typography>Movimento: </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: alpha(theme.palette.grey[500], 0.15),
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius
                })}>
                <Typography>{pedido?.movimento ?? 'Sem Movimento'}</Typography>
              </Box>
            </Grid>
            {/* Transmissão */}
            <Grid item xs={1}>
              <Typography>Transmissão: </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={(theme) => ({
                  background: pedido?.transmissao
                    ? theme.palette.success.main
                    : theme.palette.warning.main,
                  color: pedido?.transmissao
                    ? theme.palette.success.contrastText
                    : theme.palette.warning.contrastText,
                  py: 1,
                  px: 2,
                  borderRadius: theme.shape.borderRadius
                })}>
                <Typography>
                  {pedido?.transmissao
                    ? new Date(pedido.transmissao).toLocaleDateString('pt-br', {
                        hour: '2-digit',
                        minute: '2-digit'
                      })
                    : 'Não Transmitido'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
}
