/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import {
  Box,
  FormControl,
  InputLabel,
  FormHelperText,
  Grid,
  Select,
  TextField,
  MenuItem,
  TextareaAutosize
} from '@mui/material';
import { FormikProps } from 'formik';
import { ProdutoCadastro } from '../models';
import { useEffect, useRef } from 'react';
import { useEmpresa } from '../../../hooks/useEmpresa';
import CurrencyInput from 'react-currency-input-field';
import { useGetCategoriasQuery } from '../../categoria/apiSlice';

// campos da tabela
// id: number;
// codigoSgi: string;
// descricao: string;
// descricaoCompleta: null;
// referencia: string;
// unidade: string;
// preco: number;
// peso: number;
// exibeNaPesquisa: number;
// classe: Classe;

type Props = {
  formik: FormikProps<Partial<ProdutoCadastro>>;
  isLoading?: boolean;
};

export function ProdutoForm({ formik, isLoading = false }: Props) {
  const { qtdeDigitosCampoQtde } = useEmpresa();

  const { isLoading: loadingCategorias, data: categorias } =
    useGetCategoriasQuery();

  const ref = useRef<HTMLInputElement>(null);
  function setInitialfocus() {
    if (ref.current) {
      ref.current.focus();
      ref.current.select();
    }
  }
  // ajusta o focus
  useEffect(() => {
    setInitialfocus();
  }, []);

  const loading = isLoading || loadingCategorias;

  return (
    <Box p={2}>
      {/* <div>{JSON.stringify(formik.errors)}</div> */}
      <Grid container spacing={3}>
        {/* codigo e descriçao */}
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {/* codigo */}
            <Grid item xs={3}>
              <FormControl fullWidth>
                <TextField
                  name="codigoSgi"
                  label="Cód."
                  disabled={loading}
                  inputProps={{ 'data-testid': 'codigoSgi' }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.codigoSgi ?? ''}
                />
              </FormControl>
            </Grid>
            {/* descricao */}
            <Grid item xs={9} display={'flex'} gap={1} alignItems={'center'}>
              <FormControl
                focused
                fullWidth
                error={Boolean(
                  formik.touched.descricao && formik.errors.descricao
                )}>
                <TextField
                  tabIndex={0}
                  autoFocus
                  inputRef={ref}
                  name="descricao"
                  label="Produto/Serviço"
                  disabled={loading}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.descricao}
                />
                {formik.touched.descricao && formik.errors.descricao && (
                  <FormHelperText error id="error-descricao">
                    {formik.errors.descricao}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {/* descricao completa */}
        <Grid item xs={12}>
          {/* descricaoCompleta */}
          <Grid item xs={12}>
            <InputLabel shrink id="descricaoCompleta">
              Descrição Completa
            </InputLabel>
            <TextareaAutosize
              style={{ width: '100%', padding: '10px' }}
              name="descricaoCompleta"
              disabled={loading}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.descricaoCompleta || ''}
              minRows={5}
              maxRows={10}
            />
          </Grid>
        </Grid>
        {/* categorias */}
        <Grid item xs={12}>
          {/* classe */}
          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={Boolean(formik.touched.classe && formik.errors.classe)}>
              <InputLabel shrink id="classe">
                Categoria
              </InputLabel>

              <Select
                id="classe"
                name="classeId"
                label="Solicitante"
                disabled={loading}
                onChange={(e) =>
                  formik.setFieldValue('classe.id', e.target.value)
                }
                onBlur={formik.handleBlur}
                labelId={'classe'}
                value={formik.values?.classe?.id ?? ''}>
                {categorias &&
                  categorias.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                      {el.descricao}
                    </MenuItem>
                  ))}
              </Select>

              {formik.touched.classe && formik.errors.classe && (
                <FormHelperText error id="error-classe">
                  {String(formik.errors.classe)}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        {/* referencia/unidade/peso/preco */}
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {/* referencia */}
            <Grid item xs={3}>
              <FormControl fullWidth>
                <TextField
                  name="referencia"
                  label="Referência"
                  disabled={loading}
                  inputProps={{ 'data-testid': 'referencia' }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.referencia}
                />
              </FormControl>
            </Grid>
            {/* unidade */}
            <Grid item xs={3}>
              <FormControl
                fullWidth
                error={Boolean(
                  formik.touched.unidade && formik.errors.unidade
                )}>
                <TextField
                  name="unidade"
                  label="Unidade"
                  disabled={loading}
                  inputProps={{ 'data-testid': 'unidade' }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.unidade}
                />
                {formik.touched.unidade && formik.errors.unidade && (
                  <FormHelperText error id="error-unidade">
                    {formik.errors.unidade}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {/* peso */}
            <Grid item xs={3}>
              <FormControl fullWidth>
                <TextField
                  name="peso"
                  label="Peso"
                  disabled={loading}
                  inputProps={{ 'data-testid': 'peso' }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.peso}
                />
              </FormControl>
            </Grid>
            {/* preço */}
            <Grid item xs={3}>
              <TextField
                fullWidth
                name="preco"
                label="Preço"
                disabled={loading}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.preco}
                type="number"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
