import { NavigateNext, Delete } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Chip, Paper, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

import { Link, useNavigate, useParams } from 'react-router-dom';

import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import {
  useDeleteProdutoMutation,
  useGetProdutoQuery,
  useUpdateProdutoMutation
} from '../apiSlice';
import { OUTLET_HEIGHT } from '../../../config/theme';
import {
  PRODUTO_INITIAL_FORM,
  PRODUTO_VALIDATION_FORM,
  ProdutoCadastro
} from '../models';
import AppConfig from '../../../config/app';
import { ProdutoForm } from '../components/form';
import { EnumPermissao } from '../../auth/models';

import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../auth/authSlice';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Inner = styled('div')(({ theme }) => ({
  height: OUTLET_HEIGHT
}));

export default function ProdutoEdit() {
  const { id } = useParams<{ id: string }>();
  const { data: produto, isFetching } = useGetProdutoQuery(id as string);
  const { enqueueSnackbar } = useSnackbar();
  const [updateProduto] = useUpdateProdutoMutation();
  const [deleteProduto] = useDeleteProdutoMutation();
  const navigate = useNavigate();
  const confirm = useConfirm();

  const formik = useFormik({
    initialValues: PRODUTO_INITIAL_FORM,
    validationSchema: PRODUTO_VALIDATION_FORM,
    onSubmit: (values: Partial<ProdutoCadastro>) => {
      values.images = [];
      updateProduto(values)
        .unwrap()
        .then(() => {
          enqueueSnackbar('Produto Atualizado', { variant: 'success' });
        })
        .catch((error: any) => {
          console.log('🚀 ~ ProdutoEdit ~ error:', error);
          enqueueSnackbar(`Erro ao atualizar Produto\n${error.data.message} `, {
            variant: 'error'
          });
        });
    }
  });

  const user = useSelector(selectCurrentUser);
  function authorization(action: string) {
    return user.permissoes[EnumPermissao.REQUISICAO].includes(action);
  }

  const handleDelete = () => {
    // confirmar permissoes
    if (!authorization('d')) {
      enqueueSnackbar('Usuário não autorizado', {
        variant: 'error'
      });
      return;
    }

    if (id) {
      confirm({
        title: 'Exclusão de Produto',
        description:
          'Confirmar exclusão do Produto (' + formik.values.descricao + ')?',
        confirmationText: 'Sim',
        cancellationText: 'Não'
      }).then(() => {
        deleteProduto(id).then(() => {
          enqueueSnackbar('Produto Excluído', { variant: 'success' });
        });
        navigate(AppConfig.routes.cadProduto);
      });
    }
  };

  useEffect(() => {
    if (id && produto) {
      console.log('🚀 ~ useEffect ~ produto:', produto);
      formik.setValues((produto as any).produto as Partial<ProdutoCadastro>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [produto, id]);

  return (
    <Inner>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" justifyContent="space-between" mb={3}>
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb">
            <Typography>Cadastro</Typography>
            <Link color="inherit" to={AppConfig.routes.cadProduto}>
              <Typography>Produtos</Typography>
            </Link>
            <Typography>Alterar</Typography>
            <Chip label={formik.values.descricao} />
          </Breadcrumbs>
          <Box display={'flex'} gap={2}>
            <Button
              type="button"
              component={Link}
              to={AppConfig.routes.cadProduto}
              startIcon={<ReplyAllIcon />}
              variant="outlined">
              Voltar
            </Button>
            {id && (
              <Button
                onClick={handleDelete}
                startIcon={<Delete />}
                color="error"
                type="button"
                variant="contained">
                Excluir
              </Button>
            )}
            <Button type="submit" startIcon={<SaveIcon />} variant="contained">
              Salvar
            </Button>
          </Box>
        </Box>
        <Paper>
          <Box height="80vh" padding="10px 0">
            <ProdutoForm isLoading={isFetching} formik={formik} />
          </Box>
        </Paper>
      </form>
    </Inner>
  );
}
