import { apiSlice } from '../api/apiSlice';
import { Prioridade } from './models';

const endpointUrl = '/prioridades';

function getPrioridades() {
  return `${endpointUrl}`;
}

function getPrioridade({ id }: { id: string }) {
  return `${endpointUrl}/${id}`;
}

function createPrioridade(prioridade: Prioridade) {
  return { url: endpointUrl, method: 'POST', body: prioridade };
}

function deletePrioridade({ id }: { id: string }) {
  return {
    url: `${endpointUrl}/${id}`,
    method: 'DELETE'
  };
}

function updatePrioridade(prioridade: Prioridade) {
  const body = { ...prioridade, updated_at: prioridade.updatedAt };
  delete body.updatedAt;
  return {
    url: `${endpointUrl}/${prioridade.id}`,
    method: 'PUT',
    body
  };
}

export const prioridadeApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getPrioridades: query<Prioridade[], void>({
      query: getPrioridades,
      providesTags: ['Prioridades']
    }),
    getPrioridade: query<Prioridade, { id: string }>({
      query: getPrioridade,
      providesTags: ['Prioridades']
    }),
    createPrioridade: mutation<Prioridade, Prioridade>({
      query: createPrioridade,
      invalidatesTags: ['Prioridades']
    }),
    deletePrioridade: mutation<Prioridade, { id: string }>({
      query: deletePrioridade,
      invalidatesTags: ['Prioridades']
    }),
    updatePrioridade: mutation<Prioridade, Prioridade>({
      query: updatePrioridade,
      invalidatesTags: ['Prioridades']
    })
  })
});

export const {
  useGetPrioridadesQuery,
  useGetPrioridadeQuery,
  useCreatePrioridadeMutation,
  useUpdatePrioridadeMutation,
  useDeletePrioridadeMutation
} = prioridadeApiSlice;
