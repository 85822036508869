import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import TextsmsIcon from '@mui/icons-material/Textsms';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
import { Log } from '../features/pedido/models';

type Mode = 'edit' | 'add';

interface Props {
  open: boolean;
  onClose: () => void;
  action?: (iteracao: string, mode: Mode, id?: number) => void;
  interacao?: Log;
  mode: Mode;
  actionDelete?: (id: number) => void;
}

export default function IteracaoModalManager({
  open,
  onClose,
  action,
  interacao,
  mode = 'add',
  actionDelete
}: Props) {
  const [_id, setId] = useState<number>();
  const [_interacao, setInteracao] = useState<string>();

  function handleSave() {
    if (action) {
      action(_interacao ?? '', mode, _id);
    }
    onClose();
  }
  function handleDelete() {
    if (actionDelete && _id) {
      actionDelete(_id);
    }
    onClose();
  }

  useEffect(() => {
    if (interacao) {
      setId(interacao.id);
      setInteracao(interacao.obs);
    } else {
      setInteracao('');
      setId(undefined);
    }
  }, [interacao]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '430px',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 2,
          borderRadius: 1,
          height: '260px',
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
        <Typography id="modal-modal-title" variant="h6" component="h4">
          {mode === 'edit' ? 'Editar' : 'Nova'} Interação
        </Typography>
        <TextField
          id="outlined-multiline-static"
          label="Digite a interação"
          fullWidth
          multiline
          value={_interacao}
          onChange={(e) => setInteracao(e.target.value)}
          rows={4}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="inherit" onClick={onClose}>
            Fechar
          </Button>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'end',
              gap: 2
            }}>
            {mode === 'edit' && (
              <Button
                variant="contained"
                color="warning"
                startIcon={<DeleteIcon />}
                onClick={handleDelete}>
                Excluir
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              startIcon={<TextsmsIcon />}
              onClick={handleSave}>
              Salvar
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
