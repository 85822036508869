import { NavigateNext, Delete } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Chip, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

import { Link, useNavigate, useParams } from 'react-router-dom';
import AppConfig from '../../config/app';
import { OUTLET_HEIGHT } from '../../config/theme';
import { SolicitanteForm } from './form';
import {
  formSolicitanteValidationSchemaEdit,
  SOLICITANTE_INITIAL_STATE
} from './models';
import {
  useGetSolicitanteQuery,
  useUpdateSolicitanteMutation,
  useDeleteSolicitanteMutation
} from './apiSlice';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Inner = styled('div')(({ theme }) => ({
  height: OUTLET_HEIGHT
}));

export default function SolicitanteEdit() {
  const id = useParams().id as string;
  const { data: user, isFetching } = useGetSolicitanteQuery({ id });
  const { enqueueSnackbar } = useSnackbar();
  const [updateSolicitante, status] = useUpdateSolicitanteMutation();
  const [deleteSolicitante, statusDel] = useDeleteSolicitanteMutation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: SOLICITANTE_INITIAL_STATE,
    validationSchema: formSolicitanteValidationSchemaEdit,
    onSubmit: (values) => {
      updateSolicitante(values);
    }
  });

  const handleDelete = () => {
    if (id) {
      deleteSolicitante({ id });
      navigate(AppConfig.routes.cadSolicitante);
    }
  };

  useEffect(() => {
    if (user) {
      formik.setValues(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (status.isSuccess || statusDel.isSuccess) {
      enqueueSnackbar('Solicitante Alterado', { variant: 'success' });
    }
    if (status.error || statusDel.error) {
      enqueueSnackbar('Error ao Alterar Solicitante', { variant: 'error' });
    }
  }, [
    enqueueSnackbar,
    status.error,
    status.isSuccess,
    statusDel.error,
    statusDel.isSuccess
  ]);

  return (
    <Inner>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" justifyContent="space-between">
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb">
            <Typography>Cadastro</Typography>
            <Link color="inherit" to={AppConfig.routes.cadSolicitante}>
              <Typography>Solicitantes</Typography>
            </Link>
            <Typography>Alterar</Typography>
            <Chip label={formik.values.nome} />
          </Breadcrumbs>
          <Box display={'flex'} gap={2}>
            <Button
              type="button"
              component={Link}
              to={AppConfig.routes.cadSolicitante}
              startIcon={<ReplyAllIcon />}
              variant="outlined">
              Voltar
            </Button>
            {id && (
              <Button
                onClick={handleDelete}
                startIcon={<Delete />}
                color="error"
                type="button"
                variant="contained">
                Excluir
              </Button>
            )}
            <Button type="submit" startIcon={<SaveIcon />} variant="contained">
              Salvar
            </Button>
          </Box>
        </Box>
        <Box height="80vh" padding="10px 0">
          <SolicitanteForm isLoading={isFetching} formik={formik} />
        </Box>
      </form>
    </Inner>
  );
}
