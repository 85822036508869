import React, { useCallback, useEffect } from 'react';
import {
  Paper,
  Box,
  Typography,
  Chip,
  FormControlLabel,
  Pagination,
  Radio,
  RadioGroup,
  IconButton
} from '@mui/material';
import {
  DataGrid,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer
} from '@mui/x-data-grid';
// import { GridToolbar } from '@mui/x-data-grid/components';
import AppConfig from '../../config/app';
import { Cotacao } from './models';
import { useNavigate } from 'react-router-dom';
import { TCotacaoParams, useCotacao } from './context';
import { useLocalStorage } from '../../hooks/useLocalStorage';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { CustomNoTableRowsOverlay } from '../../components/NoCustomRowOverlay';
import { writeFile, utils } from 'xlsx';
import { GetApp } from '@mui/icons-material';

export default function Table() {
  const navigate = useNavigate();
  const {
    filterParams,
    searchParams,
    setParams,
    paramsQtdeRows,
    paramsQtdePage,
    type,
    data,
    loading: isLoading
  } = useCotacao();
  const [rows, setRows] = React.useState<Partial<Cotacao>[]>([]);

  function mapDataToGridRows(data: Cotacao[]): any[] {
    return data.map((d: Cotacao) => ({
      id: d.id,
      createdAt: d.createdAt
        ? new Date(d.createdAt).toLocaleDateString('pt-BR')
        : '',
      obs: d.obs,
      // requisicoes: d?.requisicoes?.length ?? 0,
      // fornecedores: d?.fornecedores?.length ?? 0,
      situacao: d.situacao
    }));
  }

  useEffect(() => {
    if (data) {
      const ret = mapDataToGridRows(data);
      setRows(ret);
    }
  }, [data]);

  const [pageSize, setPageSize] = useLocalStorage('table-cotacao-pagesize', 10);
  function pageSizeChange(newPageSize: number) {
    updateParams({ take: newPageSize, skip: 0 });
    setPageSize(newPageSize);
  }

  const updateParams = useCallback(
    (param: Partial<TCotacaoParams>) => {
      setParams({ ...filterParams, ...param });
    },
    [filterParams, setParams]
  );

  useEffect(() => {
    if (type === 'filter') {
      if (pageSize !== filterParams?.take) updateParams({ take: pageSize });
    } else {
      if (pageSize !== searchParams?.take) updateParams({ take: pageSize });
    }
  }, [filterParams, pageSize, searchParams, type, updateParams]);

  function CustomPagination() {
    return (
      <Box
        minWidth={'96%'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={2}
        mx={2}
        px={0}>
        {/* <Typography>{selectedRows?.length} Registro(s)</Typography> */}

        {type === 'filter' ? (
          <Chip
            sx={{
              backgroundColor: 'primary.main',
              color: 'secondary.contrastText'
            }}
            icon={<FilterAltIcon />}
            label="Por Filtro"
            size="small"
          />
        ) : (
          <Chip
            sx={{
              backgroundColor: 'primary.main',
              color: 'secondary.contrastText'
            }}
            icon={<SearchIcon />}
            label="Por Texto"
            size="small"
          />
        )}
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <Typography fontSize={12}>Qtde/página:</Typography>
          <RadioGroup
            sx={{ display: 'flex', flexDirection: 'row' }}
            name="controlled-radio-buttons-group"
            value={pageSize.toString()}
            onChange={(e) => pageSizeChange(Number(e.target.value))}>
            <FormControlLabel
              value="10"
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18
                    }
                  }}
                />
              }
              label="10"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: 12
                }
              }}
            />
            <FormControlLabel
              value="25"
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18
                    }
                  }}
                />
              }
              label="25"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: 12
                }
              }}
            />
            <FormControlLabel
              value="50"
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18
                    }
                  }}
                />
              }
              label="50"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: 12
                }
              }}
            />
          </RadioGroup>
        </Box>
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <Pagination
            sx={{
              '.MuiPaginationItem-rounded': {
                color: '#fff'
              }
            }}
            shape="rounded"
            showFirstButton
            showLastButton
            color="primary"
            count={paramsQtdePage}
            page={
              type === 'filter' ? filterParams.skip + 1 : searchParams.skip + 1
            }
            onChange={(event, value) => {
              let params: Partial<TCotacaoParams> = {};
              if (type === 'filter') {
                params = {
                  ...filterParams,
                  skip: value - 1,
                  take: pageSize
                };
              } else {
                params = {
                  ...searchParams,
                  skip: value - 1,
                  take: pageSize
                };
              }
              setParams(params as TCotacaoParams);
            }}
          />
          <Typography fontSize={12}>Qtde: {paramsQtdeRows}</Typography>
        </Box>
      </Box>
    );
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ py: 1 }}>
        <GridToolbarColumnsButton variant="text" />
        {/* <GridToolbarExport variant="text" /> */}
        <IconButton onClick={handleExport}>
          <GetApp color="primary" />
        </IconButton>
      </GridToolbarContainer>
    );
  }

  const mapToExcel = data.map((d) => {
    return {
      ID: d.id,
      Data: d.createdAt
        ? new Date(d.createdAt).toLocaleDateString('pt-BR')
        : '',
      Descrição: d.obs,
      Situação: d.situacao?.nome
    };
  });

  function handleExport() {
    const ws = utils.json_to_sheet(mapToExcel);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Cotacao');
    writeFile(wb, 'Cotacao-Cotacoes.xlsx');
  }

  return (
    <Paper>
      <Box height="80vh">
        <DataGrid
          sx={(style) => ({
            '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
              fontSize: 11,
              backgroundColor: style.palette.secondary.main,
              color: style.palette.secondary.contrastText
            },
            '& .MuiDataGrid-columnSeparator': {
              color: style.palette.secondary.contrastText
            },
            fontSize: 13,
            overflow: 'hidden',
            '& .MuiDataGrid-columnHeader .MuiCheckbox-root ': {
              color: style.palette.secondary.contrastText,
              borderRadius: 0
            },
            '& .MuiDataGrid-columnHeaders': {
              borderRadius: 0
            },
            '& MuiSvgIcon-root, .MuiDataGrid-sortIcon': {
              opacity: 1,
              color: style.palette.secondary.contrastText
            }
          })}
          loading={isLoading}
          // paginationMode="server"
          rows={rows}
          density="compact"
          columns={[
            {
              field: 'id',
              headerName: 'ID',
              width: 15,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'createdAt',
              headerName: 'Data',
              width: 120,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            {
              field: 'obs',
              headerName: 'Descrição',
              flex: 1,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true
            },
            // {
            //   field: 'requisicoes',
            //   headerName: 'Requisições',
            //   width: 80,
            //   sortable: true,
            //   editable: false,
            //   filterable: false,
            //   hideable: true,
            //   disableColumnMenu: true
            // },
            // {
            //   field: 'fornecedores',
            //   headerName: 'Fornecedores',
            //   width: 80,
            //   sortable: true,
            //   editable: false,
            //   filterable: false,
            //   hideable: true,
            //   disableColumnMenu: true
            // },
            {
              field: 'situacao',
              headerName: 'Situação',
              width: 200,
              sortable: true,
              editable: false,
              filterable: false,
              hideable: true,
              disableColumnMenu: true,
              valueFormatter: ({ value }) => `${value.nome}`,
              renderCell: (params) => {
                return (
                  <Typography
                    fontSize={10}
                    sx={(theme) => ({
                      color: params.row.situacao?.corLetra || '#000000',
                      background: params.row.situacao?.corFundo,
                      py: theme.spacing(0.5),
                      px: theme.spacing(1.5),
                      borderRadius: theme.shape.borderRadius
                    })}>
                    {params.row.situacao?.nome}
                  </Typography>
                );
              }
            }
          ]}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableColumnMenu
          components={{
            Toolbar: CustomToolbar,
            Pagination: CustomPagination,
            // LoadingOverlay: LinearProgress,
            NoRowsOverlay: CustomNoTableRowsOverlay
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              csvOptions: {
                delimiter: ';',
                utf8WithBom: true,
                fileName: 'sistema-cotação'
              }
            },
            footer: {
              sx: {
                backgroundColor: 'secondary.main',
                color: 'secondary.contrastText',
                '& .MuiPaginationItem-root': {
                  fontSize: 12
                },
                '& *': {
                  color: 'secondary.contrastText'
                }
              }
            }
          }}
          pageSize={pageSize}
          rowCount={paramsQtdeRows}
          rowsPerPageOptions={[10, 25, 50, 100]}
          paginationMode="server"
          // checkboxSelection
          // disableSelectionOnClick
          // isRowSelectable={(params: GridRowParams) =>
          //   params.row.situacao.id === 1
          // }
          // onSelectionModelChange={(ids) => {
          //   if (!selectedRows) return;
          //   const selectedIDs = new Set(ids);
          //   if (!data) return;
          //   const selectedRowsData = data.filter((row) => {
          //     if (row.id) return selectedIDs.has(row.id);
          //   });

          //   selectedRows(selectedRowsData);
          // }}
          onRowDoubleClick={(params: GridRowParams) => {
            navigate(AppConfig.routes.cadCotacaoEdit(params.id as string));
          }}
          hideFooterSelectedRowCount
        />
      </Box>
    </Paper>
  );
}
