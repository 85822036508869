import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import { CotacaoFornecedor, CotacaoItem } from '../models';
import { AddAlert, Grading, Info, Visibility } from '@mui/icons-material';
import CircularProgressLabel from '../../../components/CircularProgressLabel';
import { useLocation, useNavigate } from 'react-router-dom';
import AppConfig from '../../../config/app';
import PageLoading from '../../../components/PageLoading';
import ChipPedido from '../common/chipPedido';
import {
  GetDisabledInfoComponent,
  GetDisabledInfoSemPedidoComponent,
  mapPanelResumeListObjects
} from './utils/funcoesTabResumo';
// import ModalUpdateItem from './modalUpdateItem';
// import DataGridItemsRequisicao from './dataGridItemsRequisicao';

interface Props {
  value: number;
  index: number;
  cotacaoId: number;
  fornecedores: CotacaoFornecedor[];
  situacao: number;
  items: CotacaoItem[];
  loading?: boolean;
  updated_at: string;
}

export default function TabPanelItemsList({
  index,
  value,
  cotacaoId,
  situacao,
  fornecedores,
  items,
  loading = false,
  updated_at
}: Props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  function goToGerarPedido(fornecedorId: number) {
    const id = String(cotacaoId);
    const fId = String(fornecedorId);
    navigate(AppConfig.routes.cadCotacaoEditGerarPedido(id, fId), {
      state: { path: pathname.replace('//', '/'), tab: index }
    });
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}>
      {!loading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            px: 2,
            py: 2,
            overflowY: 'auto',
            height: '72vh'
          }}>
          {fornecedores.map((fornecedor) => {
            const {
              total,
              totalCotado,
              totalSelecionado,
              totalGeral,
              requisicoesVencedoras,
              requisicoesSelecionadas
            } = mapPanelResumeListObjects({
              fornecedor,
              items,
              fornecedoresList: fornecedores
            });

            const getDisabledInfoVencedor = GetDisabledInfoComponent({
              total,
              totalCotado,
              requisicoesVencedoras
            });

            const getDisabledInfoSemPedido = GetDisabledInfoSemPedidoComponent({
              total,
              totalCotado,
              requisicoesSelecionadas
            });

            // caso tenhar pedido gerado
            if ((fornecedor as any).pedido) {
              return (
                <Paper
                  key={fornecedor.id}
                  sx={{
                    p: 0.8,
                    height: 70
                  }}>
                  <Grid container>
                    {/* fornecedor - xs-4 */}
                    <Grid item xs={4}>
                      <Box
                        sx={{ height: '100%' }}
                        display={'flex'}
                        alignItems={'center'}
                        gap={1}>
                        <Typography
                          fontSize={12}
                          variant="h6"
                          component="span"
                          fontWeight={'bold'}>
                          {fornecedor.fornecedor.nome}
                        </Typography>
                        {(fornecedor as any).pedido && (
                          <ChipPedido pedidoId={(fornecedor as any).pedido} />
                        )}
                      </Box>
                    </Grid>
                    {/* cotadas xs-2 */}
                    <Grid
                      item
                      xs={2}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}>
                      <Box
                        sx={{ height: '100%' }}
                        display={'flex'}
                        flexDirection={'column'}
                        alignContent={'center'}
                        alignItems={'center'}>
                        <Typography fontSize={12} variant="h6" component="span">
                          Cotadas:
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1
                          }}>
                          <Typography
                            fontSize={12}
                            fontWeight={'bold'}
                            variant="h6"
                            component="span">
                            {totalCotado}/{total}
                          </Typography>
                          <Divider orientation="vertical" flexItem />
                          <CircularProgressLabel
                            size={35}
                            color="success"
                            value={Math.round((totalCotado / total) * 100)}
                          />
                        </Box>
                      </Box>
                      {/* <Divider orientation="vertical" flexItem /> */}
                    </Grid>
                    {/* vencedoras xs-2 */}
                    <Grid
                      item
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      xs={2}>
                      <Typography fontSize={12} variant="h6" component="span">
                        Req. Vencedoras:
                      </Typography>
                      <Typography
                        fontSize={12}
                        fontWeight={'bold'}
                        variant="h6"
                        component="span">
                        {requisicoesVencedoras}/{total}
                      </Typography>
                      {/* <Divider orientation="vertical" flexItem /> */}
                    </Grid>
                    {/* total xs-2 */}
                    <Grid
                      item
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      gap={1}
                      xs={2}>
                      <Box
                        sx={{ height: '100%' }}
                        display={'flex'}
                        flexDirection={'column'}
                        alignContent={'center'}
                        alignItems={'center'}>
                        <Typography fontSize={12} variant="h6" component="span">
                          Total:
                        </Typography>
                        <Typography
                          fontSize={12}
                          fontWeight={'bold'}
                          variant="h6"
                          component="span">
                          {(totalGeral ?? 0).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                          })}
                        </Typography>
                      </Box>
                    </Grid>
                    {/* gerar pedido xs-2 */}
                    <Grid
                      item
                      xs={2}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}>
                      <Box
                        sx={{ height: '100%' }}
                        gap={0.5}
                        display={'flex'}
                        flexDirection={'column'}
                        alignContent={'center'}
                        alignItems={'center'}>
                        <Button
                          size="small"
                          disabled={
                            fornecedor.pedidoGerado
                              ? false
                              : totalCotado !== total ||
                                requisicoesVencedoras === 0
                          }
                          variant="contained"
                          color={
                            !fornecedor.pedidoGerado ? 'success' : 'secondary'
                          }
                          startIcon={
                            !fornecedor.pedidoGerado ? (
                              <Grading />
                            ) : (
                              <Visibility />
                            )
                          }
                          onClick={() =>
                            goToGerarPedido(fornecedor.fornecedor.id as number)
                          }>
                          {!fornecedor.pedidoGerado
                            ? 'Gerar Pedido'
                            : 'Ver Pedido'}
                        </Button>
                        {getDisabledInfoVencedor}
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              );
            }
            // caso o pedido não tenha sido gerado
            else {
              return (
                <Paper
                  key={fornecedor.id}
                  sx={{
                    p: 0.8,
                    height: 70
                  }}>
                  <Grid container>
                    {/* fornecedor - xs-4 */}
                    <Grid item xs={4}>
                      <Box
                        sx={{ height: '100%' }}
                        display={'flex'}
                        alignItems={'center'}
                        gap={1}>
                        <Typography
                          fontSize={12}
                          variant="h6"
                          component="span"
                          fontWeight={'bold'}>
                          {fornecedor.fornecedor.nome}
                        </Typography>
                        {(fornecedor as any).pedido && (
                          <ChipPedido pedidoId={(fornecedor as any).pedido} />
                        )}
                      </Box>
                    </Grid>
                    {/* cotadas xs-2 */}
                    <Grid
                      item
                      xs={2}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}>
                      <Box
                        sx={{ height: '100%' }}
                        display={'flex'}
                        flexDirection={'column'}
                        alignContent={'center'}
                        alignItems={'center'}>
                        <Typography fontSize={12} variant="h6" component="span">
                          Cotadas:
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1
                          }}>
                          <Typography
                            fontSize={12}
                            fontWeight={'bold'}
                            variant="h6"
                            component="span">
                            {totalCotado}/{total}
                          </Typography>
                          <Divider orientation="vertical" flexItem />
                          <CircularProgressLabel
                            size={35}
                            color="success"
                            value={Math.round((totalCotado / total) * 100)}
                          />
                        </Box>
                      </Box>
                      {/* <Divider orientation="vertical" flexItem /> */}
                    </Grid>
                    {/* vencedoras xs-2 */}
                    <Grid
                      item
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      xs={2}>
                      <Typography fontSize={12} variant="h6" component="span">
                        Req. Selecionadas:
                      </Typography>
                      <Typography
                        fontSize={12}
                        fontWeight={'bold'}
                        variant="h6"
                        component="span">
                        {requisicoesSelecionadas}/{total}
                      </Typography>
                      {/* <Divider orientation="vertical" flexItem /> */}
                    </Grid>
                    {/* total xs-2 */}
                    <Grid
                      item
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      gap={1}
                      xs={2}>
                      <Box
                        sx={{ height: '100%' }}
                        display={'flex'}
                        flexDirection={'column'}
                        alignContent={'center'}
                        alignItems={'center'}>
                        <Typography fontSize={12} variant="h6" component="span">
                          Total:
                        </Typography>
                        <Typography
                          fontSize={12}
                          fontWeight={'bold'}
                          variant="h6"
                          component="span">
                          {(totalSelecionado ?? totalGeral).toLocaleString(
                            'pt-BR',
                            {
                              style: 'currency',
                              currency: 'BRL'
                            }
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                    {/* gerar pedido xs-2 */}
                    <Grid
                      item
                      xs={2}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}>
                      <Box
                        sx={{ height: '100%' }}
                        gap={0.5}
                        display={'flex'}
                        flexDirection={'column'}
                        alignContent={'center'}
                        alignItems={'center'}>
                        <Button
                          size="small"
                          disabled={
                            fornecedor.pedidoGerado
                              ? false
                              : totalCotado !== total ||
                                requisicoesSelecionadas === 0
                          }
                          variant="contained"
                          color={
                            !fornecedor.pedidoGerado ? 'success' : 'secondary'
                          }
                          startIcon={
                            !fornecedor.pedidoGerado ? (
                              <Grading />
                            ) : (
                              <Visibility />
                            )
                          }
                          onClick={() =>
                            goToGerarPedido(fornecedor.fornecedor.id as number)
                          }>
                          {!fornecedor.pedidoGerado
                            ? 'Gerar Pedido'
                            : 'Ver Pedido'}
                        </Button>
                        {getDisabledInfoSemPedido}
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              );
            }
          })}
        </Box>
      ) : (
        <PageLoading minHeight={70} />
      )}
    </div>
  );
}
