import { object, string } from 'yup';

export const AUTH_INITIAL_STATE: Auth = {
  email: '',
  senha: ''
};

export const fromAuthValidationSchema = object({
  email: string().email('E-mail inválido.').required(),
  senha: string().required('Campo Requerido')
});

export interface Auth {
  email: string;
  senha: string;
}

// posição do array de permissões no usuario
export enum EnumPermissao {
  'REQUISICAO' = 0,
  'COTACAO' = 1,
  'PENDENCIA' = 2,
  'PRIORIDADE' = 3,
  'SITUACAO' = 4,
  'SOLICITANTE' = 5,
  'FORNECEDOR' = 6,
  'DEPARTAMENTO' = 7,
  'CATEGORIA' = 8,
  'PRODUTO' = 9
}

export const INITIAL_PERMISSAO = [
  'cru_ax_', // REQUISICAO
  'cru___f', // COTACAO
  '_r_____', // PENDENCIA
  'cru____', // PRIORIDADE
  'cru____', // SITUACAO
  'cru____', // SOLICITANTE
  'cru____', // FORNECEDOR
  'cru____', // DEPARTAMENTO
  '_r_____', // CATEGORIA
  '_r_____' // PRODUTO
];

export const ALL_PERMISSAO = [
  'crudaxf', // REQUISICAO
  'crudaxf', // COTACAO
  'crudaxf', // PENDENCIA
  'crudaxf', // PRIORIDADE
  'crudaxf', // SITUACAO
  'crudaxf', // SOLICITANTE
  'crudaxf', // FORNECEDOR
  'crudaxf', // DEPARTAMENTO
  'crud', // CATEGORIA
  'crud' // PRODUTO
];

export type TPermissao = [
  'c' | '', // create
  'r' | '', // read
  'u' | '', // update
  'd' | '', // delete
  'a' | '', // aprovar
  'x' | '', // cancel
  'f' | '' // finish
];

export type TPermissaoAcion = 'c' | 'r' | 'u' | 'd' | 'a' | 'x' | 'f';
