import { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material';

import { Log, Pedido } from '../../pedido/models';
import EditNoteIcon from '@mui/icons-material/Edit';
import TextsmsIcon from '@mui/icons-material/Textsms';
import IteracaoModalManager from '../../../components/IteracaoModalManager';
import {
  useAddLogMutation,
  useDelLogMutation,
  useUpdLogMutation
} from '../../pedido/apiSlice';
import { usePedido } from '../context';

interface Props {
  value: number;
  index: number;
  pedido: Pedido;
}

export default function TabPanelLogs({ index, value, pedido }: Props) {
  const { filterPedidos } = usePedido();
  const [addLog] = useAddLogMutation();
  const [edtLog] = useUpdLogMutation();
  const [delLog] = useDelLogMutation();
  const [open, setOpen] = useState(false);
  const [interacaoRef, setInteracaoRef] = useState<Log>();
  const [modalMode, setModalMode] = useState<'add' | 'edit'>('add');

  function handleOpen(inter?: Log) {
    if (inter) {
      setInteracaoRef(inter);
      setModalMode('edit');
    } else {
      setModalMode('add');
      setInteracaoRef(undefined);
    }
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}>
      {value === index && (
        <Box
          sx={{
            height: '70vh',
            p: 2,
            gap: 2,
            display: 'flex',
            flexDirection: 'column'
          }}>
          <Box>
            <Button
              size="small"
              variant="contained"
              onClick={() => handleOpen(undefined)}>
              <TextsmsIcon /> &nbsp; Nova Interação
            </Button>
          </Box>
          <Divider />
          <List
            component="nav"
            sx={{
              width: '100%',
              height: '60vh',
              overflow: 'hidden',
              overflowY: 'auto'
            }}>
            {pedido.logs
              .map((log) => (
                <Box key={log.id}>
                  <ListItem
                    secondaryAction={
                      <IconButton onClick={() => handleOpen(log)}>
                        <EditNoteIcon />
                      </IconButton>
                    }>
                    <ListItemAvatar>
                      <Avatar>
                        <TextsmsIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={log.obs}
                      secondary={
                        <Typography fontSize={9}>
                          {new Date(log.createdAt).toLocaleDateString('pt-BR', {
                            hour: '2-digit',
                            minute: '2-digit'
                          })}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <Divider />
                </Box>
              ))
              .reverse()}
          </List>

          <IteracaoModalManager
            interacao={interacaoRef}
            open={open}
            mode={modalMode}
            onClose={handleClose}
            action={(inter, mode, id) => {
              if (mode === 'add') {
                addLog({
                  pedidoId: pedido?.id,
                  updated_at: pedido.updatedAt,
                  obs: inter
                }).then(() => {
                  filterPedidos();
                });
              }
              if (mode === 'edit' && id) {
                edtLog({
                  logId: id,
                  pedidoId: pedido?.id,
                  updated_at: pedido.updatedAt,
                  obs: inter
                }).then(() => {
                  filterPedidos();
                });
              }
            }}
            actionDelete={(id) => {
              delLog({
                logId: id,
                pedidoId: pedido?.id,
                updated_at: pedido.updatedAt
              }).then(() => {
                filterPedidos();
              });
            }}
          />
        </Box>
      )}
    </div>
  );
}
