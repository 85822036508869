/* eslint-disable prettier/prettier */
import { Add, FilterAltOutlined, NavigateNext } from '@mui/icons-material';
import { Box, Button, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { Link } from 'react-router-dom';
import AppConfig from '../../../config/app';
import { OUTLET_HEIGHT } from '../../../config/theme';
import Table from '../table';
import { useCotacao } from '../context';
import RightDrawer from '../../../components/RightDrawer';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import SelectFilter from '../../../components/filters/selectFilter';
import { useGetSituacaosQuery } from '../../situacao/apiSlice';
import PeriodFilter from '../../../components/filters/periodFilter';
import CustomSearch from '../../../components/CustomSearch';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';

const drawerWidth = 240;
const Inner = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  height: OUTLET_HEIGHT,
  flexGrow: 1,
  position: 'relative',
  zIndex: 0,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginRight: 60,
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: drawerWidth
  })
}));

export default function CotacaoList() {
  const { enqueueSnackbar } = useSnackbar();
  const { setFilters, filters, filterCotacoes, searchFor, searchCotacoes } =
    useCotacao();

  // filtro situacoes
  const { data: dataSituacoes, isLoading: isLoadingSituacoes } =
    useGetSituacaosQuery(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      {},
      {
        refetchOnMountOrArgChange: true
      }
    );

  const [openDrawer, setOpenDrawer] = useLocalStorage(
    'cotacao-right-drawer',
    false
  );

  const handlerFilter = (filter: Record<string, any>) => {
    // verifica se a data inicial é maior que a data final
    const oldFilters = filters;
    const [dt1, dt2] = filter.created_at ?? [null, null];
    const dtIni = dt1 ? new Date(dt1) : null;
    const dtFim = dt2 ? new Date(dt2) : null;
    if (dtIni && dtFim && dtIni > dtFim) {
      enqueueSnackbar('A data inicial não pode ser maior que a data final', {
        variant: 'warning'
      });
      setFilters(oldFilters);
      return;
    }

    const newfilters = { ...filters, ...filter };
    setFilters(newfilters);
  };

  async function toFilter() {
    filterCotacoes();
  }

  const initialLoading = useCallback(() => {
    return filterCotacoes();
  }, [filterCotacoes]);

  useEffect(() => {
    initialLoading();
  }, [initialLoading]);

  return (
    <Inner open={openDrawer}>
      <Box
        sx={{ my: 1 }}
        display="flex"
        justifyContent="space-between"
        alignItems={'center'}>
        <Breadcrumbs
          sx={{ py: 1 }}
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb">
          {/* <Typography>Cotações</Typography> */}
          <Typography fontWeight={'bold'}>Cotações</Typography>
        </Breadcrumbs>
        <Box display={'flex'} gap={2}>
          <CustomSearch
            value={searchFor}
            handleSearch={(value) => {
              searchCotacoes(value);
            }}
          />
          <Button
            startIcon={<Add />}
            component={Link}
            variant="contained"
            to={AppConfig.routes.cadCotacaoInsert}>
            Nova Cotação
          </Button>
        </Box>
      </Box>
      <Paper>
        <Box height="78vh">
          <Table />
        </Box>
      </Paper>
      <RightDrawer
        caption="Filtros"
        drawerWidth={drawerWidth}
        openDrawer={openDrawer}
        setOpen={setOpenDrawer}>
        <Box
          display="flex"
          flexDirection={'column'}
          gap={1}
          sx={{ overflow: 'hidden', overflowY: 'auto' }}>
          {/* filtro de data */}
          <PeriodFilter
            dataKey="created_at"
            value={filters?.created_at ?? [null, null]}
            onChange={handlerFilter}
            caption="Período"
          />
          {/* filtro de situações */}
          <SelectFilter
            dataKey="situacaoId"
            value={filters?.situacaoId ?? []}
            loading={isLoadingSituacoes}
            data={
              dataSituacoes
                ? dataSituacoes
                    .filter((s: any) => s.tela === 'C')
                    .map((el: any) => ({
                      id: el.id,
                      desc: el.nome.toUpperCase(),
                      corFundo: el.corFundo,
                      corLetra: el.corLetra
                    }))
                : []
            }
            onChange={handlerFilter}
            caption="Situações"
            width={drawerWidth - 20}
          />
        </Box>
        {/* ação de filtrar */}
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.grey[50]
          })}
          py={1}
          display={'flex'}
          justifyContent={'space-between'}>
          {/* <Typography
            onClick={clearFilters}
            sx={(theme) => ({
              cursor: 'pointer',
              '&:hover': {
                color: theme.palette.primary.main,
                textDecoration: 'underline'
              }
            })}
            variant="overline">
            Limpar Filtros
          </Typography> */}
          <Button
            fullWidth
            onClick={toFilter}
            variant="contained"
            color="primary"
            size={'small'}>
            <FilterAltOutlined amplitude={8} /> Filtrar
          </Button>
        </Box>
      </RightDrawer>
    </Inner>
  );
}
