import { GridColumns } from '@mui/x-data-grid';

// campos da tabela
// id: number;
// codigoSgi: string;
// descricao: string;
// descricaoCompleta: null;
// referencia: string;
// unidade: string;
// preco: number;
// peso: number;
// exibeNaPesquisa: number;
// classe: Classe;

export default function ProdutoColumns(): GridColumns {
  return [
    {
      field: 'id',
      headerName: 'ID',
      width: 60,
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true
    },
    {
      field: 'classe',
      headerName: 'Categoria',
      width: 150,
      sortable: true,
      editable: false,
      filterable: true,
      hideable: true,
      disableColumnMenu: true
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      flex: 1,
      sortable: true,
      editable: false,
      filterable: true,
      hideable: true,
      disableColumnMenu: true
    },
    {
      field: 'descricaoCompleta',
      headerName: 'Descrição Completa',
      flex: 1,
      sortable: true,
      editable: false,
      filterable: true,
      hideable: true,
      disableColumnMenu: true
    },
    {
      field: 'referencia',
      headerName: 'Referência',
      width: 150,
      sortable: true,
      editable: false,
      filterable: true,
      hideable: true,
      disableColumnMenu: true
    },
    {
      field: 'unidade',
      align: 'center',
      headerName: 'Unidade',
      width: 70,
      sortable: true,
      editable: false,
      filterable: true,
      hideable: true,
      disableColumnMenu: true
    },
    {
      field: 'peso',
      headerName: 'Peso',
      align: 'center',
      width: 70,
      sortable: true,
      editable: false,
      filterable: true,
      hideable: true,
      disableColumnMenu: true
    },
    {
      field: 'preco',
      align: 'right',
      headerName: 'Preço',
      width: 100,
      sortable: true,
      editable: false,
      filterable: true,
      hideable: true,
      disableColumnMenu: true
    }
    // {
    //   field: 'exibeNaPesquisa',
    //   headerName: 'Exibe Pesquisa',
    //   width: 100,
    //   sortable: true,
    //   editable: false,
    //   filterable: true,
    //   hideable: true,
    //   disableColumnMenu: true,
    //   type: 'boolean'
    // }
  ];
}
