import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CircularProgress, CircularProgressProps } from '@mui/material';

function CircularProgressLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Typography
          variant="caption"
          component="span"
          color="text.secondary">{`${Math.round(
          props.value || 0
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressLabel;
