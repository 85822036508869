import React, { createContext, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import AppRouter from './app/router';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { CssBaseline } from '@mui/material';
import { baseUrl } from './features/api/apiSlice';
import { Empresa } from './features/empresa/models';
import { ConfirmProvider } from 'material-ui-confirm';
import { ErrorBoundary } from 'react-error-boundary';

interface IContextConfig extends Empresa {
  loading: boolean;
  error: boolean;
}

function Fallback({
  error,
  resetErrorBoundary
}: {
  error: Error;
  resetErrorBoundary: any;
}) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  );
}

const ContextConfig = createContext<IContextConfig>({} as IContextConfig);

const ProviderConfig = ({ children }: any) => {
  const url = baseUrl;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rest, setRest] = useState<Empresa>({} as Empresa);

  useEffect(() => {
    fetch(url + '/empresas')
      .then((res) => {
        if (res.status === 200) {
          res
            .json()
            .then((data) => {
              setRest(data[0]);
            })
            .catch((err) => {
              setError(true);
              console.log(err);
            });
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url]);

  return (
    <ContextConfig.Provider value={{ loading, error, ...rest }}>
      {children}
    </ContextConfig.Provider>
  );
};
export { ContextConfig };

const container = document.getElementById('root')!;

const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <CssBaseline />
    <ProviderConfig>
      <ConfirmProvider>
        <ErrorBoundary FallbackComponent={Fallback}>
          <AppRouter />
        </ErrorBoundary>
      </ConfirmProvider>
    </ProviderConfig>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
