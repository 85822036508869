import { object, string } from 'yup';

export const DEPARTAMENTO_INITIAL_STATE: Departamento = {
  id: undefined,
  nome: '',
  numeroSerie: '',
  ordenacao: 1,
  isActive: true,
  isVisible: true
};

export const formDepartamentoValidationSchemaInsert = object({
  nome: string().required('Campo Requerido')
});

export const formDepartamentoValidationSchemaEdit = object({
  nome: string().required('Campo Requerido')
});

export interface Departamento {
  id?: number;
  nome: string;
  numeroSerie: string;
  ordenacao: number;
  isActive: boolean;
  isVisible: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}
