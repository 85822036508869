import { NavigateNext } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Chip, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

import { Link, useParams } from 'react-router-dom';
import AppConfig from '../../config/app';
import { OUTLET_HEIGHT } from '../../config/theme';
import { SituacaoForm } from './form';
import {
  formSituacaoValidationSchemaEdit,
  SITUACAO_INITIAL_STATE
} from './models';
import { useGetSituacaoQuery, useUpdateSituacaoMutation } from './apiSlice';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Inner = styled('div')(({ theme }) => ({
  height: OUTLET_HEIGHT
}));

export default function SituacaoEdit() {
  const id = useParams().id as string;
  const { data: user, isFetching } = useGetSituacaoQuery({ id });
  const { enqueueSnackbar } = useSnackbar();
  const [updateSituacao] = useUpdateSituacaoMutation();

  const formik = useFormik({
    initialValues: SITUACAO_INITIAL_STATE,
    validationSchema: formSituacaoValidationSchemaEdit,
    onSubmit: (values) => {
      // const update = {
      //   ...values,
      //   updated_at: values.updatedAt
      // };
      // delete update.updatedAt;
      // delete update.createdAt;
      updateSituacao(values)
        .unwrap()
        .then(() => {
          enqueueSnackbar('Situacao Alterado', { variant: 'success' });
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          enqueueSnackbar(error?.data?.message ?? 'Error ao Alterar Situacao', {
            variant: 'error'
          });
        });
    }
  });

  useEffect(() => {
    if (user) {
      formik.setValues(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Inner>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" justifyContent="space-between">
          <Breadcrumbs
            sx={{ py: 1 }}
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb">
            <Typography>Cadastro</Typography>
            <Link color="inherit" to={AppConfig.routes.cadSituacao}>
              <Typography fontWeight={'bold'}>Situações</Typography>
            </Link>
            <Typography>Alterar</Typography>
            <Chip label={formik.values.nome} />
          </Breadcrumbs>
          <Box display={'flex'} gap={2}>
            <Button
              type="button"
              component={Link}
              to={AppConfig.routes.cadSituacao}
              startIcon={<ReplyAllIcon />}
              variant="outlined">
              Voltar
            </Button>
            <Button type="submit" startIcon={<SaveIcon />} variant="contained">
              Salvar
            </Button>
          </Box>
        </Box>
        <Box height="80vh" padding="10px 0">
          <SituacaoForm isLoading={isFetching} formik={formik} />
        </Box>
      </form>
    </Inner>
  );
}
