import { apiSlice } from '../api/apiSlice';
import { Empresa } from './models';

const endpointUrl = '/empresas';

function getEmpresa() {
  return `${endpointUrl}`;
}

export const empresaApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getEmpresa: query<Empresa, void>({
      query: getEmpresa
    }),
    loadEmpresa: mutation<Empresa, void>({
      query: getEmpresa
    })
  })
});

export const { useGetEmpresaQuery, useLoadEmpresaMutation } = empresaApiSlice;
