import { IconButton, Typography } from '@mui/material';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MuiDrawer from '@mui/material/Drawer';
import { OUTLET_HEIGHT } from '../config/theme';
import { ReactNode } from 'react';
import VerticalText from './VerticalText';

interface Props {
  drawerWidth: number;
  openDrawer: boolean;
  setOpen: (val: boolean) => void;
  children: ReactNode;
  caption: string;
  onFocus?: () => void;
}

export default function RightDrawer({
  drawerWidth,
  openDrawer,
  setOpen,
  children,
  caption,
  onFocus
}: Props) {
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDraweToggle = () => {
    if (openDrawer) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  };

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`
    }
  });

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: openDrawer ? 'space-between' : 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open'
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  }));

  const Inner = styled('div')(() => ({
    height: OUTLET_HEIGHT,
    overflow: 'hidden',
    overflowY: 'auto',
    padding: 8
    // display: openDrawer ? 'inline-flex' : 'none'
  }));
  return (
    <Drawer
      variant="permanent"
      anchor="right"
      open={openDrawer}
      onMouseEnter={() => onFocus && onFocus()}
      onFocus={() => onFocus && onFocus()}>
      <DrawerHeader></DrawerHeader>
      <DrawerHeader>
        {openDrawer && (
          <Typography fontWeight={'bold'} color={'primary'}>
            {caption}
          </Typography>
        )}
        <IconButton onClick={handleDraweToggle} title={caption}>
          {openDrawer ? (
            <FilterAltIcon color="primary" />
          ) : (
            <FilterAltIcon color="disabled" />
          )}
        </IconButton>
      </DrawerHeader>
      {openDrawer ? (
        <Inner>{children}</Inner>
      ) : (
        <VerticalText
          text={caption.toUpperCase()}
          fontSize={16}
          fontWeight="bold"
          color="#dadada"
          gap={3}
        />
      )}
    </Drawer>
  );
}
