import {
  styled,
  Theme,
  CSSObject,
  alpha,
  useTheme
} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { Outlet } from 'react-router-dom';
import SideMenu from './sideMenu';
import Header from './header';
import { useLocalStorage } from '../hooks/useLocalStorage';
import AppConfig from '../config/app';
import { GridMenuIcon } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setDrawerWidth, toggleSidebar } from './layoutSlice';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  backgroundColor: alpha(theme.palette.primary.main, 1),
  color: alpha(theme.palette.primary.contrastText, 0.8),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  backgroundColor: alpha(theme.palette.secondary.main, 1),
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

export default function Layout({
  currentTheme,
  toggleCurrentTheme
}: {
  currentTheme: Theme;
  toggleCurrentTheme?: () => void;
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [open, setOpen] = useLocalStorage<boolean>('sideMenu', true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    dispatch(toggleSidebar(open));
    dispatch(setDrawerWidth(open ? drawerWidth : theme.spacing(7)));
  }, [dispatch, open, theme]);

  return (
    <Box display={'flex'}>
      {/* <AppBar position="fixed" open={open}> */}

      {/* </AppBar> */}
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          {open && (
            <Typography variant="h6" noWrap component="span">
              {AppConfig.name}
            </Typography>
          )}
          <IconButton sx={{ color: 'inherit' }} onClick={handleDrawerToggle}>
            <GridMenuIcon titleAccess="Menu" />
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ color: 'inherit' }} />
        <SideMenu menuOpened={open} />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Header
          handleDrawerOpen={handleDrawerOpen}
          open={open}
          toggleTheme={toggleCurrentTheme}
          theme={currentTheme.palette.mode === 'dark' ? 'dark' : 'light'}
        />
        <Box sx={{ flexGrow: 1, px: 2 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
