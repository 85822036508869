/* eslint-disable @typescript-eslint/no-empty-function */
import crypto from 'crypto-js';

const AES = process.env.REACT_APP_AES || 'GRGO@APP@DEVLOPMENT@FULL';

const encryptor = (value: string) => {
  try {
    return crypto.AES.encrypt(value, AES).toString();
  } catch (e) {
    console.error(e);
    return null;
  }
};

const decryptor = (value: string) => {
  try {
    return crypto.AES.decrypt(value, AES).toString(crypto.enc.Utf8);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export { encryptor, decryptor };
