import { Add, NavigateNext } from '@mui/icons-material';
import { Box, Button, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import {
  DataGrid,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid';
// import { useSnackbar } from 'notistack';
import { useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import AppConfig from '../../config/app';
import { OUTLET_HEIGHT } from '../../config/theme';
import { Fornecedor } from './models';
import { useGetFornecedoresQuery } from './apiSlice';
import Paper from '@mui/material/Paper';
import CustomSearch from '../../components/CustomSearch';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Inner = styled('div')(({ theme }) => ({
  height: OUTLET_HEIGHT
}));

export default function Fornecedores() {
  const { isLoading, data } = useGetFornecedoresQuery(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    {},
    {
      refetchOnMountOrArgChange: true
    }
  );
  const navigate = useNavigate();
  const [searchFor, setSearchFor] = useState('');

  function mapDataToGridRows(data: Fornecedor[]) {
    return data.map((d) => ({
      id: d.id,
      nome: d.nome,
      apelido: d.apelido,
      cnpj: d.cnpj,
      cidade: d.cidade,
      uf: d.uf
    }));
  }

  const handleSearch = (value: string) => {
    setSearchFor(value);
  };

  // regex buscando por . , ? ! + - * / ( ) [ ] { } \ | ^ $ :
  // eslint-disable-next-line no-useless-escape
  const regex = RegExp(/[.,?!+*\/(){}\[\]\\^$]/, 'g');

  return (
    <Inner>
      <Box
        sx={{ my: 1 }}
        display="flex"
        justifyContent="space-between"
        alignItems={'center'}>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb">
          <Typography>Cadastro</Typography>
          <Typography fontWeight={'bold'}>Fornecedores</Typography>
        </Breadcrumbs>
        <Box display={'flex'} gap={2}>
          <CustomSearch value={searchFor} handleSearch={handleSearch} />
          <Button
            startIcon={<Add />}
            component={Link}
            variant="contained"
            to={AppConfig.routes.cadFornecedorInsert}>
            Inserir
          </Button>
        </Box>
      </Box>
      <Paper>
        <Box height="78vh">
          <DataGrid
            sx={{
              '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                fontSize: 12
              },
              fontSize: 12
            }}
            loading={isLoading}
            paginationMode="server"
            rows={
              data
                ? mapDataToGridRows(data).filter((f) =>
                    searchFor.length > 1
                      ? f.nome
                          .toLowerCase()
                          .replace(regex, '')
                          .includes(searchFor.toLowerCase().replace(regex, ''))
                      : true
                  )
                : []
            }
            columns={[
              {
                field: 'nome',
                headerName: 'Nome',
                flex: 1,
                sortable: true,
                editable: false,
                filterable: false,
                hideable: false,
                renderCell(params: GridRenderCellParams) {
                  return <Typography>{params.value}</Typography>;
                },
                disableColumnMenu: true
              },
              {
                field: 'apelido',
                headerName: 'Fantasia',
                flex: 1,
                sortable: true,
                editable: false,
                filterable: false,
                hideable: false,
                renderCell(params: GridRenderCellParams) {
                  return <Typography>{params.value}</Typography>;
                },
                disableColumnMenu: true
              },
              {
                field: 'cnpj',
                headerName: 'CNPJ',
                width: 200,
                sortable: true,
                editable: false,
                filterable: false,
                hideable: false,
                disableColumnMenu: true,
                renderCell(params: GridRenderCellParams) {
                  return <Typography>{params.value}</Typography>;
                }
              },
              {
                field: 'cidade',
                headerName: 'Cidade',
                width: 120,
                sortable: true,
                editable: false,
                filterable: false,
                hideable: false,
                disableColumnMenu: true,
                renderCell(params: GridRenderCellParams) {
                  return <Typography>{params.value}</Typography>;
                }
              },
              {
                field: 'uf',
                headerName: 'UF',
                width: 50,
                sortable: true,
                editable: false,
                filterable: false,
                hideable: false,
                disableColumnMenu: true,
                renderCell(params: GridRenderCellParams) {
                  return <Typography>{params.value}</Typography>;
                }
              }
              // {
              //   field: 'id',
              //   headerName: 'Ações',
              //   width: 100,
              //   disableColumnMenu: true,
              //   sortable: false,
              //   renderCell: RenderActionsCell
              // }
            ]}
            pageSize={10}
            onRowDoubleClick={(params: GridRowParams) =>
              navigate(AppConfig.routes.cadFornecedorEdit(params.id as string))
            }
            hideFooterSelectedRowCount
          />
        </Box>
      </Paper>
    </Inner>
  );
}
