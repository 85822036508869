import { number, object, string } from 'yup';
import { Departamento } from '../departamento/models';
import { Prioridade } from '../prioridade/models';
import { Situacao } from '../situacao/models';
import { Solicitante } from '../solicitante/models';
import { Fornecedor } from '../fornecedor/models';
import { Classe } from '../classes/models';

export const REQUISICAO_INITIAL_STATE: Requisicao = {
  id: undefined,
  descricaoProdutoServico: '',
  codigoSgi: undefined,
  local: undefined,
  saldo: 0,
  tipoReq: 'P',
  solicitante: null,
  departamento: null,
  unidade: '',
  qtde: 1,
  situacao: { id: 1 } as unknown as Situacao,
  prioridade: null,
  obs: ''
};

export interface IRequisicaoFilters {
  tipoReq: string[];
  classeId: string[];
  situacaoId: number[];
  prioridadeId: number[];
  solicitanteId: number[];
  departamentoId: number[];
  created_at: [string | null, string | null];
  searchFor?: string;
}

export const REQUISICAO_INITIAL_FILTERS: IRequisicaoFilters = {
  tipoReq: [],
  classeId: [],
  situacaoId: [],
  prioridadeId: [],
  solicitanteId: [],
  departamentoId: [],
  created_at: [null, null],
  searchFor: ''
};

export const formRequisicaoValidationSchemaInsert = object({
  descricaoProdutoServico: string().required('Campo Requerido'),
  tipoReq: string().required('Campo Requerido'),
  solicitante: object().required('Campo Requerido'),
  departamento: object().required('Campo Requerido'),
  unidade: string().required('Campo Requerido'),
  qtde: number(),
  situacao: object().required('Campo Requerido'),
  prioridade: object().required('Campo Requerido')
});

export const formRequisicaoValidationSchemaEdit = object({
  descricaoProdutoServico: string().required('Campo Requerido')
});

export interface ResultRequisicao {
  info: Info;
  requisicoes: Requisicao[];
}

interface Info {
  registros: number;
  pagina: string;
  qtdePorPagina: string;
  orderBy: string;
  sort: string;
  pesquisaPor: PesquisaPor;
}

interface PesquisaPor {
  situacao: number[];
  prioridade: number[];
  solicitante: number[];
  departamento: number[];
  createdAt: string[];
  searchFor: string;
  tipo: string[];
}

export interface Requisicao {
  id?: number;
  tipoReq: string; // P | S
  solicitante?: Solicitante | null;
  departamento?: Departamento | null;
  codigoSgi?: string;
  local?: string;
  saldo?: number;
  produtoServico?: ProdutoServico;
  descricaoProdutoServico: string;
  unidade: string;
  qtde: number;
  situacao?: Situacao | null;
  prioridade?: Prioridade | null;
  // prioridadeId?: number | undefined;
  obs: string;
  createdAt?: Date;
  updatedAt?: Date;
  cotacaoId?: number;
  ultimaCompra?: UltimaCompra[];
  pedido?: Pedido;
  // fornecedor??
  // preco?:
  // dataPrevista?:
  // obs?:
}

export interface ProdutoServico {
  id: number;
  codigoSgi: string;
  descricao: string;
  descricaoCompleta: string;
  referencia: string;
  unidade: string;
  preco: number;
  peso: number;
  exibeNaPesquisa: number;
  classe: Classe;
  created_at: Date;
  updated_at: Date;
}

export interface UltimaCompra {
  id: number;
  nfe: string;
  entrada: Date;
  valor: string;
  qtde: string;
  fornecedor: Fornecedor;
}

export interface Pedido {
  id: number;
  previsao: string;
  createdAt: string;
  updatedAt: string;
}
