import { createTheme } from '@mui/material/styles';
import { ptBR } from '@mui/material/locale';

export const lightTheme = createTheme(
  {
    palette: {
      mode: 'light',
      primary: {
        // light: will be calculated from palette.primary.main,
        main: process.env.REACT_APP_PRIMARY_COLOR_LIGTH || '#cacaca'
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: process.env.REACT_APP_SECONDARY_COLOR_LIGTH || '#000000'
        // dark: will be calculated from palette.secondary.main,
      },
      background: {
        default: '#e4f0e2'
      }
    },
    typography: {
      fontFamily: 'Poppins'
    },
    shape: {
      borderRadius: 12
    }
  },
  ptBR
);

export const darkTheme = createTheme(
  {
    palette: {
      mode: 'dark',
      primary: {
        // light: will be calculated from palette.primary.main,
        main: process.env.REACT_APP_PRIMARY_COLOR_DARK || '#cacaca'
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: process.env.REACT_APP_SECONDARY_COLOR_DARK || '#ffffff'
        // dark: will be calculated from palette.secondary.main,
      }
      // background: {
      //   default: '#334155'
      // }
    },
    typography: {
      fontFamily: 'Poppins'
    },
    shape: {
      borderRadius: 8
    }
  },
  ptBR
);

export const OUTLET_HEIGHT = '81vh';
