/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import 'react-data-grid/lib/styles.css';

import DataGrid, { SortColumn } from 'react-data-grid';
import {
  Box,
  Divider,
  Fade,
  IconButton,
  List,
  ListItemButton,
  Paper,
  Popper,
  Typography
} from '@mui/material';
import { CotacaoItem } from '../../models';
import columns from './columns';
import { TRow, rowKeyGetter } from './rows';
import './styles.css';
import { handleCellKeyDown } from './utils';
import { EmojiEvents, Save, SaveAlt } from '@mui/icons-material';
import ModalPreencherLote from './modalPreencherLote';
import PageLoading from '../../../../components/PageLoading';
import { LoadingButton } from '@mui/lab';
import { useUpdateItensLoteMutation } from '../../apiSlice';
import { useSnackbar } from 'notistack';
import CircularProgressLabel from '../../../../components/CircularProgressLabel';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import PopoverColumns from './popoverColumns';
// import { useNavigate } from 'react-router-dom';
import { exportToPdf } from '../../../../utils/exportUtils';
import { writeFile, utils } from 'xlsx';
import { useEmpresa } from '../../../../hooks/useEmpresa';
import { useCallbackPrompt } from '../../../../hooks/useCallbackPrompt';
import { last } from 'lodash';
interface Props {
  items: CotacaoItem[];
  cotacaoId?: number;
  updated_at?: string;
  loading?: boolean;
  height?: string;
  disabled?: boolean;
  editable?: boolean;
  width?: string;
  // isChanged?: (value: boolean) => void;
  compare?: (row: any) => void;
}

const DATA_SITUACAO = [
  {
    id: 210,
    nome: 'Cotar',
    corFundo: '#fff5f5',
    corLetra: '#8f0000'
  },
  {
    id: 215,
    nome: 'Não tenho',
    corFundo: '#ffffeb',
    corLetra: '#cc7400'
  },
  {
    id: 220,
    nome: 'Cotado',
    corFundo: '#ffffff',
    corLetra: '#4a90e2'
  },
  {
    id: 230,
    nome: 'Vencedora',
    corFundo: '#ffffff',
    corLetra: '#00e40b'
  }
];

export default function DataGridItemsRequisicaoEsterno2({
  items,
  cotacaoId = 0,
  updated_at = '',
  loading = false,
  height,
  disabled = false,
  // editable = false,
  // isChanged,
  width = '100%',
  compare
}: Props) {
  const [isWhen, setIsWhen] = useState<boolean>(false);
  const [isBlocked] = useCallbackPrompt(isWhen);
  const { qtdeDigitosCampoQtde, qtdeDigitosCampoUnitário } = useEmpresa();
  const { enqueueSnackbar } = useSnackbar();
  // const navigate = useNavigate();
  const cols = columns({
    qtdeDigts: qtdeDigitosCampoQtde,
    precoDigts: qtdeDigitosCampoUnitário
  });
  const [rows, setNewRows] = useState<TRow[]>([]);
  const lastRows = useRef<TRow[] | null>(null);
  const [totalGeral, setTotalGeral] = useState<number>(0);

  // popper exportar
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const toggleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<number> => new Set()
  );

  const calculaTotalGeral = useCallback((rows: any[]): number => {
    return rows.reduce((acc, row) => {
      return acc + row.total;
    }, 0);
  }, []);

  const calculatePrecoComImposto = useCallback((item: any) => {
    const preco = parseStringToFloat(item.preco)
      ? Number.isNaN(Number(item.preco))
        ? 0
        : Number(item.preco)
      : 0;
    const icms = item.icms ? preco * (parseStringToFloat(item.icms) / 100) : 0;
    const ipi = item.ipi ? preco * (parseStringToFloat(item.ipi) / 100) : 0;
    const frete = item.frete
      ? preco * (parseStringToFloat(item.frete) / 100)
      : 0;
    const outrasDespesas = item.outrasDespesas
      ? preco * (parseStringToFloat(item.outrasDespesas) / 100)
      : 0;
    return preco + icms + ipi + frete + outrasDespesas;
  }, []);

  const [updateItensLote, status] = useUpdateItensLoteMutation();

  const calculaTotal = useCallback(
    (item: any) => {
      const precoComImposto = calculatePrecoComImposto(item);
      return (
        Math.round(precoComImposto * parseStringToFloat(item.qtde) * 100) / 100
      );
    },
    [calculatePrecoComImposto]
  );

  const [visebleColumns, setVisebleColumns] = useLocalStorage<string[]>(
    'data-grid-item-req-columns',
    cols.map((column: any) => column.key)
  );

  const renderRow = useCallback(
    (item: any) => {
      return {
        ...item,
        requisicaoId: item?.requisicao?.id,
        codigoSgi: item.requisicao?.produtoServico?.codigoSgi ?? '',
        descricao: item.requisicao?.descricaoProdutoServico ?? '',
        seuCodigo: item.seuCodigo,
        qtde: (item.qtde ?? 0).toLocaleString('pt-br', {
          minimumFractionDigits:
            qtdeDigitosCampoUnitário > 2 ? qtdeDigitosCampoUnitário : 0,
          maximumFractionDigits: qtdeDigitosCampoUnitário
        }),
        preco: (item.preco ?? 0).toLocaleString('pt-br', {
          minimumFractionDigits: qtdeDigitosCampoUnitário,
          maximumFractionDigits: qtdeDigitosCampoUnitário
        }),
        icms: item.icms ?? 0,
        ipi: item.ipi ?? 0,
        frete: item.frete ?? 0,
        outrasDespesas: item.outrasDespesas ?? 0,
        precoComImposto: calculatePrecoComImposto(item),
        total: calculaTotal(item),
        obs: item.obs,
        updated_at: item.updatedAt,
        indisponivel: item.situacao?.id === 215 ? true : false,
        situacao: item.situacao,
        pedido: item.requisicao?.pedido?.id
      };
    },
    [calculaTotal, calculatePrecoComImposto, qtdeDigitosCampoUnitário]
  );

  const getComparator = useCallback((sortColumn: string) => {
    switch (sortColumn) {
      //-> string sort
      case 'descricao':
        return (a: any, b: any) => {
          return a[sortColumn].localeCompare(b[sortColumn]);
        };

      case 'situacao':
        return (a: any, b: any) => {
          return a[sortColumn].nome.localeCompare(b[sortColumn].nome);
        };

      //-> boolean sort
      // case '':
      //   return (a, b) => {
      //     return a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1;
      //   };

      //-> date sort
      // case '':
      //   return (a: any, b: any) => {
      //     return (
      //       new Date(a[sortColumn]).getTime() -
      //       new Date(b[sortColumn]).getTime()
      //     );
      //   };

      case 'codigoSgi':
      case 'seuCodigo':
      case 'qtde':
      case 'icms':
      case 'ipi':
      case 'frete':
      case 'outrasDespesas':
      case 'preco':
      case 'precoComImposto':
      case 'total':
      case 'requisicaoId': // => number
        return (a: any, b: any) => {
          return Number(a[sortColumn]) - Number(b[sortColumn]);
        };
      default:
        enqueueSnackbar(`Não é possível ordenar a coluna: "${sortColumn}"`, {
          variant: 'error'
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // sort rows
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([
    { columnKey: 'requisicaoId', direction: 'ASC' }
  ]);

  const sortedRows = useMemo((): readonly any[] => {
    const source = lastRows.current ?? rows;
    console.log('🚀 ~ sortedRows ~ source:', source);

    if (sortColumns.length === 0) return source;

    const sort = [...source].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        if (!comparator) {
          return 0;
        }
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });

    return sort;
  }, [getComparator, rows, sortColumns]);

  function handleProcessLote(data: Record<string, any>) {
    // campos
    const fields = Object.entries(data);
    const fieldsFilter = fields.filter((item) => item[1] !== null);

    const fieldsMap = fieldsFilter.reduce(
      (acc: Record<string, number>, [key, value]) => {
        acc[key] = value;
        return acc;
      },
      {}
    );

    const newRows = rows.map((row) => {
      const rowId = row.id;
      if (row.pedido) {
        return row;
      }
      if (selectedRows.has(rowId)) {
        return { ...row, ...fieldsMap };
      } else {
        return row;
      }
    });
    const ajusta = newRows.map((item) => ({
      ...item,
      qtde: parseStringToFloat(item.qtde),
      preco: parseStringToFloat(item.preco),
      icms: parseStringToFloat(item.icms),
      ipi: parseStringToFloat(item.ipi),
      frete: parseStringToFloat(item.frete),
      outrasDespesas: parseStringToFloat(item.outrasDespesas)
    }));
    const calc = ajusta.map((item) => ({
      ...item,
      precoComImposto: calculatePrecoComImposto(item),
      total: calculaTotal(item)
    }));
    setNewRows(calc);
    setIsWhen(true);
    setSelectedRows(new Set());
  }

  function parseStringToFloat(value: string | number) {
    return parseFloat(String(value).replace(',', '.'));
  }

  async function handleSave() {
    setSelectedRows(new Set());
    const source = lastRows.current ?? rows;
    const map = source.map((row: TRow) => {
      row.preco = parseStringToFloat(row.preco);
      return {
        id: row.id,
        seuCodigo: row.seuCodigo,
        qtde: row.qtde,
        preco: row.preco,
        // preco: row.indisponivel ? 0 : row.preco,
        precoLiquido: row.preco,
        icms: row.icms,
        vIcms: row.preco * (row.icms / 100),
        ipi: row.ipi,
        vIpi: row.preco * (row.ipi / 100),
        frete: row.frete,
        vFrete: row.preco * (row.frete / 100),
        outrasDespesas: row.outrasDespesas,
        vOutrasDespesas: row.preco * (row.outrasDespesas / 100),
        precoComImposto: row.precoComImposto,
        // precoComImposto: row.indisponivel ? 0 : row.precoComImposto,
        obs: row.obs,
        updated_at: row.updated_at,
        situacaoId: convertSituacao(row)
      };
    });
    setIsWhen(false);
    await updateItensLote({
      cotacaoId,
      updated_at,
      lotes: map as unknown as any
    })
      .then(async () => {
        enqueueSnackbar('Lote salvo com sucesso', { variant: 'success' });
        // await new Promise((resolve) => setTimeout(resolve, 100));
        // goBack();
      })
      .catch((error) => {
        enqueueSnackbar(error.data.message, { variant: 'error' });
      });
  }

  const mapToExcel = ((rows as any[]) ?? []).map((d) => {
    return {
      'Código SGI': d.codigoSgi,
      Descrição: d.descricao,
      'Seu Código': d.seuCodigo,
      Quantidade: d.qtde,
      Preço: d.preco,
      Disponibilidade: d.indisponivel ? 'Não' : 'Sim',
      ICMS: d.icms,
      IPI: d.ipi,
      Frete: d.frete,
      'Outras Despesas': d.outrasDespesas,
      'Preço com Imposto': d.precoComImposto,
      Total: d.total,
      Observação: d.obs,
      Situação: d.situacao.nome
    };
  });

  function handleExport() {
    const ws = utils.json_to_sheet(mapToExcel);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Cotacao');
    writeFile(wb, 'Cotacao-Items.xlsx');
  }

  const [vencedoresQtde, setVencedoresQtde] = useState(0);

  // map items to rows
  useEffect(() => {
    // calcular quantidade de situacao com id = 230 - vencedora
    const totalVencedora = items.filter((item) => item.situacao.id === 230);
    setVencedoresQtde(totalVencedora.length);
    const map = items.map((item) => renderRow(item));
    setNewRows(map.reverse() as any);
    // lastRows.current = map.reverse();
  }, [items, renderRow]);

  // ajusta situação
  function ajustaSituacao(row: TRow): TRow {
    if (row.situacao.id === 230) {
      return {
        ...row,
        cotado: true,
        indisponivel: false,
        situacao:
          DATA_SITUACAO?.find((situacao) => situacao.id === 230) ?? row.situacao
      };
    }

    if (row.preco > 0 && row.indisponivel === false) {
      return {
        ...row,
        cotado: true,
        situacao:
          DATA_SITUACAO?.find((situacao) => situacao.id === 220) ?? row.situacao
      };
    }
    if (row.preco > 0 && row.indisponivel !== false) {
      return {
        ...row,
        cotado: true,
        indisponivel: false,
        situacao:
          DATA_SITUACAO?.find((situacao) => situacao.id === 220) ?? row.situacao
      };
    }

    if (row.preco === 0 && row.indisponivel === false) {
      return {
        ...row,
        cotado: false,
        situacao:
          DATA_SITUACAO?.find((situacao) => situacao.id === 210) ?? row.situacao
      };
    }

    return {
      ...row,
      cotado: row.situacao.id === 220 || row.situacao.id === 215 ? true : false,
      situacao:
        DATA_SITUACAO?.find((situacao) => situacao.id === row.situacao.id) ??
        row.situacao
    };
  }

  // converte situacao em situacaoId
  function convertSituacao(row: TRow): number {
    if (row.situacao.id === 215 && row.preco === 0) return 215;
    if (row.preco === 0 && row.situacao.id !== 215) return 210;
    return 220;
  }

  // calcula cotados

  const map = rows.map((row) => ajustaSituacao(row));
  const total = map.length;
  const totalCotado = map.filter((row) => row.cotado).length;

  // calcula total geral
  useEffect(() => {
    const calc = calculaTotalGeral(rows);
    setTotalGeral(calc);
  }, [calculaTotalGeral, rows]);

  // exibe o linha pelo id
  const showRowId = useCallback(
    (index: number) => {
      const row = rows[index];
      compare && compare(row);
    },
    [compare, rows]
  );

  const changeRows = useCallback(
    (items: TRow[]) => {
      const ajusta = items.map((item) => ({
        ...item,
        qtde: parseStringToFloat(item.qtde),
        preco: parseStringToFloat(item.preco),
        icms: parseStringToFloat(item.icms),
        ipi: parseStringToFloat(item.ipi),
        frete: parseStringToFloat(item.frete),
        outrasDespesas: parseStringToFloat(item.outrasDespesas)
      }));
      const calc = ajusta.map((item) => ({
        ...item,
        precoComImposto: calculatePrecoComImposto(item),
        total: calculaTotal(item)
      }));
      setNewRows(calc);
      setIsWhen(true);
      lastRows.current = calc;
    },
    [calculaTotal, calculatePrecoComImposto]
  );

  const dataGrid = useMemo(() => {
    return (
      <DataGrid
        className="rdg-light"
        style={{ height: '100%', width: '100%' }}
        columns={cols.filter(
          (column: any) =>
            visebleColumns.indexOf(column.key) !== -1 ||
            column.key === 'select-row'
        )}
        rows={sortedRows.map((row) => ajustaSituacao(row))}
        defaultColumnOptions={{
          sortable: true
        }}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        rowKeyGetter={rowKeyGetter}
        onRowsChange={changeRows}
        selectedRows={selectedRows}
        onSelectedRowsChange={setSelectedRows}
        onCellKeyDown={(args, event) =>
          compare
            ? handleCellKeyDown(args, event, rows, 'CHANGE_ROW', showRowId)
            : handleCellKeyDown(args, event, rows, 'CHANGE_ROW')
        }
      />
    );
  }, [
    cols,
    sortedRows,
    sortColumns,
    changeRows,
    selectedRows,
    visebleColumns,
    compare,
    rows,
    showRowId
  ]);

  // function exportCsv() {
  //   if (dataGrid) {
  //     exportToCsv(dataGrid, 'cotacao-precos-externo');
  //   }
  // }

  function exportPdf() {
    if (dataGrid) {
      exportToPdf(dataGrid, 'cotacao-precos-externo');
    }
  }

  const isLoading = loading || status.isLoading;

  return (
    <Box width={width || '100%'}>
      <Paper
        sx={{
          p: 2,
          my: 1,
          height: 50,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <Box sx={{ flex: 1, display: 'flex', gap: 2, alignItems: 'center' }}>
          <PopoverColumns
            value={visebleColumns}
            onChange={setVisebleColumns}
            activeColumns={['seuCodigo', 'codigoSgi', 'obs', 'situacao']}
          />
          <Box>
            <IconButton size="small" color="primary" onClick={toggleOpen}>
              <SaveAlt />
            </IconButton>
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement={'bottom-start'}
              transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <List>
                      <ListItemButton onClick={handleExport}>
                        Exportar Excel
                      </ListItemButton>
                      <Divider />
                      <ListItemButton onClick={exportPdf}>
                        Exportar PDF
                      </ListItemButton>
                    </List>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Typography fontSize={12} variant="h6" component="span">
            Cotados:
          </Typography>
          <Typography
            fontSize={12}
            fontWeight={'bold'}
            variant="h6"
            component="span">
            {totalCotado}/{total}
          </Typography>
          <Divider orientation="vertical" flexItem />
          <CircularProgressLabel
            color="success"
            value={Math.round((totalCotado / total) * 100)}
          />

          <Divider orientation="vertical" flexItem />
          <Typography
            fontSize={12}
            variant="h6"
            component="span"
            display={'flex'}
            gap={1}
            alignItems={'center'}>
            <EmojiEvents /> <span>Vencedoras:</span>
          </Typography>
          <Typography fontSize={12} variant="h6" component="span">
            {vencedoresQtde}
          </Typography>

          <Divider orientation="vertical" flexItem />
          <Typography fontSize={12} variant="h6" component="span">
            Total:
          </Typography>
          <Typography
            fontSize={12}
            fontWeight={'bold'}
            variant="h6"
            component="span">
            {(totalGeral ?? 0).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            })}
          </Typography>
        </Box>
        <Box display={'flex'} gap={2}>
          <ModalPreencherLote
            disabled={disabled}
            action={handleProcessLote}
            selectesRows={selectedRows.size > 0}
          />
          <LoadingButton
            disabled={disabled}
            loading={status.isLoading}
            variant="contained"
            color="success"
            startIcon={<Save />}
            onClick={handleSave}>
            {'Salvar'}
          </LoadingButton>
        </Box>
      </Paper>
      <Paper sx={{ overflow: 'hidden', overflowX: 'auto' }}>
        <Box
          sx={{
            overflow: 'hidden',
            overflowX: 'auto'
          }}
          height={height}>
          {isLoading ? <PageLoading minHeight={50} /> : dataGrid}
        </Box>
      </Paper>
    </Box>
  );
}
