import { boolean, number, object, string } from 'yup';
export interface EmailConfig {
  id: number;
  tipoConfiguracao: string;
  smtpServer: string;
  porta: number;
  isSecure: boolean;
  usuario: string;
  senha: string;
  from: string;
  assunto: string;
  corpo: string;
  corpoView: string;
  imagem: string;
  createdAt?: string;
  updatedAt?: string;
  updated_at?: string;
}

export const formConfigSchema = object().shape({
  smtpServer: string().required('Campo obrigatório'),
  porta: number().required('Campo obrigatório'),
  isSecure: boolean(),
  usuario: string().required('Campo obrigatório'),
  senha: string().required('Campo obrigatório'),
  from: string().required('Campo obrigatório'),
  assunto: string().required('Campo obrigatório'),
  corpo: string().optional(),
  corpoView: string().optional(),
  imagem: string().optional()
});
