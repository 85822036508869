import { Chip } from '@mui/material';
import {
  CotacaoFornecedor,
  CotacaoItem,
  CotacaoRequisicao
} from '../../models';
import { Info } from '@mui/icons-material';

// ############### tela de resumo #####################
interface IMapPanelResumeList {
  fornecedoresList: CotacaoFornecedor[];
  fornecedor: CotacaoFornecedor;
  items: CotacaoItem[];
}
export function mapPanelResumeListObjects({
  fornecedor,
  items,
  fornecedoresList
}: IMapPanelResumeList) {
  //filtra apenas os itens do fornecedor
  const filter = items.filter(
    (i) => i.fornecedor.id === fornecedor.fornecedor.id
  );
  // calcula o total de itens
  const total = filter.length;

  const totalCotado = filter.filter(
    (item) =>
      item.situacao.id === 215 ||
      item.situacao.id === 220 ||
      item.situacao.id === 230
  ).length;

  // requisicoes vencedoras
  const requisicoesVencedoras = filter.filter(
    (item) => item.situacao.id === 230
  );

  // calcula o total de itens vencedores
  const totalGeral = requisicoesVencedoras.reduce((acc, item) => {
    const precoComImposto = item.precoComImposto ?? 0;
    // const precoComImposto = calculatePrecoComImposto(item);
    return acc + precoComImposto * item.qtde;
  }, 0);

  // requisicoes selecionadas
  const requisicoesSelecionadas = filter.filter(
    (item) =>
      item.situacao.id === 220 &&
      item.isSelected &&
      isPedidoGerado(item?.fornecedor?.id ?? 0, fornecedoresList) === false &&
      isRequisicaoComPedido(item?.requisicao?.id ?? 0, items) === false
  );

  const totalSelecionado = requisicoesSelecionadas.reduce((acc, item) => {
    const precoComImposto = item.precoComImposto ?? 0;
    // const precoComImposto = calculatePrecoComImposto(item);
    const mult = precoComImposto * item.qtde;
    return acc + mult;
  }, 0);

  // retorna o objeto
  return {
    total,
    totalCotado,
    totalGeral,
    totalSelecionado,
    requisicoesSelecionadas: requisicoesSelecionadas.length,
    requisicoesVencedoras: requisicoesVencedoras.length
  };
}

export const GetDisabledInfoComponent = ({
  total,
  totalCotado,
  requisicoesVencedoras
}: {
  total: number;
  totalCotado: number;
  requisicoesVencedoras: number;
}) => {
  const disabledInfo: string[] = [];
  if (totalCotado !== total) {
    disabledInfo.push(`(${total - totalCotado}) não cotadas`);
  }

  if (requisicoesVencedoras === 0) {
    disabledInfo.push('Sem vencedoras');
  }

  if (disabledInfo.length > 0) {
    const msg = disabledInfo.join(' | ');
    return (
      <Chip sx={{ fontSize: 9 }} icon={<Info />} size="small" label={msg} />
    );
  }
  return null;
};

export const GetDisabledInfoSemPedidoComponent = ({
  total,
  totalCotado,
  requisicoesSelecionadas
}: {
  total: number;
  totalCotado: number;
  requisicoesSelecionadas: number;
}) => {
  const disabledInfo: string[] = [];
  if (totalCotado !== total) {
    disabledInfo.push(`(${total - totalCotado}) não cotadas`);
  }

  if (requisicoesSelecionadas === 0) {
    disabledInfo.push('Sem selecionadas');
  }

  if (disabledInfo.length > 0) {
    const msg = disabledInfo.join(' | ');
    return (
      <Chip sx={{ fontSize: 9 }} icon={<Info />} size="small" label={msg} />
    );
  }
  return null;
};

// ############### tela de resumo #####################

function calculatePrecoComImposto(item: CotacaoItem) {
  const preco = item.preco ?? 0;
  const icms = item.icms ? preco * (item.icms / 100) : 0;
  const ipi = item.ipi ? preco * (item.ipi / 100) : 0;
  const frete = item.frete ? preco * (item.frete / 100) : 0;
  const outrasDespesas = item.outrasDespesas
    ? preco * (item.outrasDespesas / 100)
    : 0;
  return preco + icms + ipi + frete + outrasDespesas;
}

export const isPedidoGerado = (
  fornecedorId: number,
  fornecedoresCotacao: CotacaoFornecedor[]
) => {
  const find = fornecedoresCotacao.find(
    (f) => f.fornecedor.id === fornecedorId
  );
  return find?.pedidoGerado ?? false;
};

export const isRequisicaoComPedido = (
  requisicaoId: number,
  requisicoes: CotacaoRequisicao[]
) => {
  const find = requisicoes.find((r) => r.requisicao.id === requisicaoId);
  return find?.requisicao?.pedido?.id ?? false;
};

export function fornecedorComPedido(
  fornecedorId: number,
  fornecedores: CotacaoFornecedor[]
) {
  const find = fornecedores.find((f) => f.fornecedor.id === fornecedorId);
  return find?.pedidoGerado ?? false;
}
