/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Tooltip, Typography } from '@mui/material';

import { Pedido } from '../../pedido/models';

import ConstructionIcon from '@mui/icons-material/Construction';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams
} from '@mui/x-data-grid';
import ChangeQtdeModal from '../../cotacao/common/changeQtdeModal';
import {
  DoneAllRounded,
  RemoveDoneRounded,
  SystemUpdateAlt
} from '@mui/icons-material';
import { usePedido } from '../context';
import DataGridRequisicao from '../components/dataGridRequisicao';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import SelectSituacaoModal from '../../../components/SelectSituacaoModal';
import { useGetSituacaosQuery } from '../../situacao/apiSlice';
import ConfirmModal from '../../../components/ConfirmModal';
import { useEmpresa } from '../../../hooks/useEmpresa';

interface Props {
  value: number;
  index: number;
  pedido: Pedido;
  loading?: boolean;
}

export default function TabPanelRequisicao({
  index,
  value,
  pedido,
  loading = false
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { qtdeDigitosCampoQtde, qtdeDigitosCampoUnitário } = useEmpresa();
  const { data: dataSituacoes } = useGetSituacaosQuery(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    {},
    {
      refetchOnMountOrArgChange: true
    }
  );
  const [localLoading, setLocalLoading] = useState(false);
  const {
    baixarRequisicao,
    baixarRequisicaoLote,
    desfazerBaixarRequisicaoLote,
    alterarSituacaoRequisicaoLote,
    baixarPedido
  } = usePedido();

  const handlerBaixarPedido = () => {
    const id = pedido.id;
    if (!id) return;
    baixarPedido(id.toString());
  };

  function mapToRows(reqs: any[]) {
    return reqs.map((req) => ({
      id: req.id,
      tipoReq: req.tipoReq,
      unidade: req.unidade,
      qtde: req.qtde,
      qtdeEntregue: req.qtdeEntregue || 0,
      entrega: req.entrega, // data da entrega
      descricaoProdutoServico: req.descricaoProdutoServico,
      previsao: req.previsao,
      local: req.local,
      obs: req.obs,
      situacaoId: req.situacao.id,
      situacao: req.situacao,
      createdAt: req.createdAt,
      updatedAt: req.updatedAt
    }));
  }

  const formataQtde = (qtde: number) => {
    if (qtdeDigitosCampoQtde > 2) {
      return qtde.toLocaleString('pt-BR', {
        minimumFractionDigits: qtdeDigitosCampoQtde,
        maximumFractionDigits: qtdeDigitosCampoQtde
      });
    }

    return qtde.toLocaleString('pt-BR', {
      minimumFractionDigits: 0,
      maximumFractionDigits: qtdeDigitosCampoQtde
    });
  };

  const columns: GridColumns<any> = [
    {
      field: 'id',
      headerName: 'ID',
      width: 15,
      sortable: false,
      editable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return <Typography fontSize={10}>{params.row.id}</Typography>;
      }
    },
    {
      field: 'tipoReq',
      headerName: 'Tipo',
      width: 60,
      sortable: true,
      editable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        if (params.row.tipoReq === 'P') {
          return <QrCode2Icon titleAccess="Produto" />;
        }

        if (params.row.tipoReq === 'S') {
          return <ConstructionIcon titleAccess="Serviço" />;
        }
      }
    },
    {
      field: 'createdAt',
      headerName: 'Data',
      width: 100,
      sortable: true,
      editable: false,
      filterable: false,
      disableColumnMenu: true,
      valueFormatter: ({ value }: { value: any }) =>
        new Date(value).toLocaleDateString('pt-BR')
    },
    {
      field: 'descricaoProdutoServico',
      headerName: 'Produto/Serviço',
      flex: 1,
      minWidth: 300,
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      disableColumnMenu: true
    },
    {
      field: 'unidade',
      headerName: 'Und',
      width: 60,
      sortable: true,
      editable: false,
      filterable: false,
      disableColumnMenu: true
    },

    {
      field: 'qtde',
      headerName: 'Qtde',
      align: 'center',
      width: 60,
      sortable: true,
      editable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params.row.situacaoId === 8) {
          return (
            <Typography fontSize={10}>
              {formataQtde(params.row.qtde - params.row.qtdeEntregue) || 0} /{' '}
              {formataQtde(params.row.qtde)}
            </Typography>
          );
        }
        return (
          <Typography fontSize={10}>{formataQtde(params.row.qtde)}</Typography>
        );
      }
    },
    {
      field: 'situacao',
      headerName: 'Situação',
      width: 120,
      sortable: true,
      editable: false,
      filterable: false,
      disableColumnMenu: true,
      valueFormatter: ({ value }) => `${value?.nome || ''}`,
      renderCell: (params: any) => {
        return (
          <Typography
            fontSize={10}
            sx={(theme) => ({
              color: params.row.situacao?.corLetra || '#000000',
              background: params.row.situacao?.corFundo,
              py: theme.spacing(0.5),
              px: theme.spacing(1.5),
              borderRadius: theme.shape.borderRadius
            })}>
            {params.row.situacao?.nome}
          </Typography>
        );
      }
    },
    {
      field: 'entrega',
      headerName: 'Recebimento',
      align: 'center',
      width: 120,
      sortable: true,
      editable: false,
      filterable: false,
      disableColumnMenu: true,
      valueFormatter: ({ value }: { value: any }) =>
        value ? new Date(value).toLocaleDateString('pt-BR') : ''
    },
    {
      field: 'previsao',
      headerName: 'Previsão',
      align: 'center',
      width: 120,
      sortable: true,
      editable: false,
      filterable: false,
      disableColumnMenu: true,
      valueFormatter: ({ value }: { value: any }) =>
        new Date(value).toLocaleDateString('pt-BR')
    },
    {
      field: 'obs',
      headerName: 'Obs',
      width: 130,
      sortable: true,
      editable: false,
      filterable: false,
      disableColumnMenu: true
    },
    {
      field: 'actions',
      headerName: 'Ações',
      width: 55,
      disableColumnMenu: true,
      sortable: false,
      hideable: false,
      align: 'center',
      renderCell: function (params: GridRenderCellParams) {
        if (params.row.situacaoId === 5) {
          return [];
        }
        return [
          <ChangeQtdeModal
            title="Receber requisição: parcial"
            description="Informe a quantidade recebida"
            component={
              <Tooltip title="Receber requisição: parcial" arrow>
                <GridActionsCellItem
                  icon={<SystemUpdateAlt />}
                  label={'Receber requisição: parcial'}
                />
              </Tooltip>
            }
            value={params.row.qtde ?? 0 - params.row.qtdeEntregue ?? 0}
            action={(newQtde: number) => {
              baixarRequisicao({
                pedidoId: pedido.id,
                requisicaoId: params.row.id,
                qtde: newQtde,
                updated_at: params.row.updatedAt
              });
            }}
            key={0}
          />
        ];
      }
    }
  ];

  const [selecteds, setSelecteds] = useState<any[]>([]);

  async function handleBaixarRequisicaoLote() {
    if (!selecteds.length) {
      enqueueSnackbar('Selecione ao menos uma requisição', {
        variant: 'warning'
      });
      return;
    }
    const params = {
      pedidoId: pedido.id,
      requisicoes: selecteds.map((s) => ({
        requisicaoId: s.id,
        qtde: s.qtde - s.qtdeEntregue || 0,
        updated_at: s.updatedAt
      }))
    };
    setLocalLoading(true);
    await baixarRequisicaoLote(params as any);
    setLocalLoading(false);
    setSelecteds([]);
  }

  async function handleAlerarSituacaoRequisicaoLote(situacaoId: number) {
    if (!selecteds.length) {
      enqueueSnackbar('Selecione ao menos uma requisição', {
        variant: 'warning'
      });
      return;
    }

    if (selecteds.some((s) => s.situacaoId === 5)) {
      enqueueSnackbar('Existe requisição já recebida, verifique!', {
        variant: 'warning'
      });
      return;
    }

    const params = {
      pedidoId: pedido.id,
      situacaoId: situacaoId,
      requisicoes: selecteds.map((s) => ({
        requisicaoId: s.id,
        qtde: s.qtde - s.qtdeEntregue || 0,
        updated_at: s.updatedAt
      }))
    };
    setLocalLoading(true);
    await alterarSituacaoRequisicaoLote(params as any);
    setLocalLoading(false);
    setSelecteds([]);
  }

  async function handleDesfazerBaixa() {
    if (!selecteds.length) {
      enqueueSnackbar('Selecione ao menos uma requisição', {
        variant: 'warning'
      });
      return;
    }

    const params = {
      pedidoId: pedido.id,
      requisicoes: selecteds.map((s) => ({
        requisicaoId: s.id,
        qtde: s.qtde - s.qtdeEntregue || 0,
        updated_at: s.updatedAt
      }))
    };
    setLocalLoading(true);
    await desfazerBaixarRequisicaoLote(params as any);
    setLocalLoading(false);

    setSelecteds([]);
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}>
      {value === index && (
        <Box sx={{ height: '70vh', p: 2 }}>
          <DataGridRequisicao
            data={mapToRows(pedido.reqs)}
            columns={columns}
            isLoading={loading || localLoading}
            selectedRows={selecteds}
            setSelectedRows={setSelecteds}
            headerElements={[
              // <SelectSituacaoModal
              //   key={0}
              //   situacoes={
              //     dataSituacoes
              //       ? dataSituacoes
              //           .filter((s) => s.id === 4 || s.id === 6)
              //           .map((el) => ({
              //             id: el.id!,
              //             desc: el.nome.toUpperCase(),
              //             corFundo: el.corFundo,
              //             corLetra: el.corLetra
              //           }))
              //       : []
              //   }
              //   action={(situacaoId) => {
              //     // aguardar api para alterar situação
              //     // console.log(situacaoId);
              //     handleAlerarSituacaoRequisicaoLote(situacaoId);
              //   }}
              //   component={
              //     selecteds.length > 0 ? (
              //       <Button key={0} color="primary" variant="contained">
              //         Alterar Situação
              //       </Button>
              //     ) : (
              //       <></>
              //     )
              //   }
              // />,
              <Button
                key={1}
                color="success"
                variant="contained"
                onClick={handleBaixarRequisicaoLote}
                startIcon={<DoneAllRounded />}>
                Receber Selecionadas
              </Button>,
              <Button
                key={2}
                color="warning"
                variant="contained"
                onClick={handleDesfazerBaixa}
                startIcon={<RemoveDoneRounded />}>
                Desfazer Recebimento
              </Button>,
              <ConfirmModal
                key={3}
                title="Receber Todas"
                description={`Confirmar recebimento de todas as requisições do pedido: ${pedido?.id} - Fornecedor ${pedido?.fornecedor.nome}?`}
                action={handlerBaixarPedido}
                component={
                  <Button
                    type="button"
                    startIcon={<SystemUpdateAlt />}
                    color="success"
                    variant="contained">
                    Receber Todas
                  </Button>
                }
              />
            ]}
          />
        </Box>
      )}
    </div>
  );
}
