import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import TotalDashboardCard from '../../components/TotalDashboardCard';
import { useGetRequisicoesQuery } from '../requisicao/apiSlice';
import { useGetSituacaosQuery } from '../situacao/apiSlice';

export default function RequisicoesCount() {
  const { data: dataReq } = useGetRequisicoesQuery();
  const { data: dataSit } = useGetSituacaosQuery();

  if (!dataReq || !dataSit) return null;

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Typography variant="h6">Requisições</Typography>
      </Box>
      <Grid container gap={3}>
        {dataSit.map((sit) => {
          if (!sit?.id) return;
          if ([3, 7].includes(sit.id)) return;
          const count = dataReq.filter(
            (req) => req.situacao?.id === sit.id
          ).length;
          if (count)
            return (
              <Grid item xs={12} md={2} key={sit.id}>
                <TotalDashboardCard
                  label={sit.nome}
                  value={count}
                  color={sit.corFundo}
                />
              </Grid>
            );
        })}
      </Grid>
    </>
  );
}
