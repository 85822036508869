import { object, string } from 'yup';

export const SITUACAO_INITIAL_STATE: Situacao = {
  id: undefined,
  nome: '',
  tela: '',
  corFundo: '#ffffff',
  corLetra: '#ffffff',
  style: '',
  ordenacao: 1,
  isActive: false,
  isVisible: false
};

export const formSituacaoValidationSchemaInsert = object({
  nome: string().required('Campo Requerido')
});

export const formSituacaoValidationSchemaEdit = object({
  nome: string().required('Campo Requerido')
});

export interface Situacao {
  id?: number;
  nome: string;
  tela: string;
  corFundo: string;
  corLetra: string;
  style: string;
  ordenacao: number;
  isActive: boolean;
  isVisible: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}
