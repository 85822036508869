import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import { Brightness4, Brightness7 } from '@mui/icons-material';
import { useAppSelector } from '../app/hooks';
import { selectEmpresa } from '../features/empresa/storeSlice';
import { Empresa } from '../features/empresa/models';
import { alpha } from '@mui/material/styles';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCotacaoExternoQuery } from '../features/cotacao/apiSlice';
import AppConfig from '../config/app';

interface Props {
  title: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function HeaderExterno({ title }: Props) {
  const empresa: Empresa = useAppSelector(selectEmpresa);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const { uuid } = useParams<{
    uuid?: string;
  }>();

  const { data: Cotacao, isLoading } = useGetCotacaoExternoQuery({
    uuid: uuid ?? ''
  });

  return (
    <Toolbar
      sx={(t) => ({
        height: 50,
        mb: 1,
        background: alpha(t.palette.secondary.main, 1),
        color: alpha(t.palette.secondary.contrastText, 0.8),
        zIndex: 10
        // borderRadius: t.shape.borderRadius
      })}>
      <IconButton
        onClick={handleClick}
        color="inherit"
        aria-label="open menu"
        aria-haspopup="true"
        edge="start"
        sx={{
          marginRight: 1
        }}>
        <MenuIcon />
      </IconButton>
      {!isLoading && Cotacao && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}>
          <MenuItem
            onClick={() => {
              handleClose();
              navigate(AppConfig.routes.cotacoesFornecedorExterno, {
                replace: true,
                state: { fornecedorCotacao: Cotacao.fornecedores[0] }
              });
            }}>
            Cotações Anteriores
          </MenuItem>
        </Menu>
      )}
      <Typography variant="h6" noWrap component="span" fontWeight={'bold'}>
        {title}
      </Typography>
      <Box sx={{ flexGrow: 1 }} />
      <Typography variant="h6" noWrap component="span" fontWeight={'bold'}>
        {empresa.nome}
      </Typography>
    </Toolbar>
  );
}
