import React from 'react';
import { RequisicaoFormEdit } from './formEdit';
import { Box } from '@mui/material';

interface Props {
  value: number;
  index: number;
  formik: any;
  isFetching: boolean;
}
export default function TabPanelEdit({
  value,
  index,
  formik,
  isFetching
}: Props) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}>
      <Box sx={{ height: `78vh` }}>
        <RequisicaoFormEdit
          isLoading={isFetching}
          formik={formik}
          findProdutoAction={(produto, local) => {
            formik.setFieldValue('tipoReq', 'P');
            formik.setFieldValue('produtoServico.codigoSgi', produto.codigo);
            formik.setFieldValue('descricaoProdutoServico', produto.descricao);
            formik.setFieldValue('unidade', produto.unidade);
            formik.setFieldValue('local', local);
            formik.setFieldValue('saldo', produto.saldo_atual);
          }}
        />
      </Box>
    </div>
  );
}
