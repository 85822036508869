import { NavigateNext } from '@mui/icons-material';

import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Chip, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

import { Link, useParams } from 'react-router-dom';
import AppConfig from '../../config/app';
import { OUTLET_HEIGHT } from '../../config/theme';
import { UserForm } from './form';
import {
  formUserValidationSchemaEdit,
  User,
  USER_INITIAL_STATE
} from './models';
import { useGetUserQuery, useUpdateUserMutation } from './usersSlice';
import { useCallback, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../auth/authSlice';
import { ALL_PERMISSAO, INITIAL_PERMISSAO } from '../auth/models';
import PageLoading from '../../components/PageLoading';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Inner = styled('div')(({ theme }) => ({
  height: OUTLET_HEIGHT
}));

export default function UsersEdit() {
  // const navigate = useNavigate();
  const authUser = useSelector(selectCurrentUser);
  function isAdmin() {
    return authUser.perfil === 'ADMIN';
  }

  const id = useParams().id as string;

  const {
    data: user,
    isFetching,
    isLoading
  } = useGetUserQuery(
    { id },
    {
      refetchOnMountOrArgChange: true
    }
  );

  const { enqueueSnackbar } = useSnackbar();
  const [updateUser, status] = useUpdateUserMutation();
  // const [deleteUser] = useDeleteUserMutation();

  const formik = useFormik({
    initialValues: USER_INITIAL_STATE,
    validationSchema: formUserValidationSchemaEdit,
    onSubmit: (values) => {
      values.senha && delete values.senha;
      const type = values.perfil === 'ADMIN' ? 'admin' : 'user';
      updateUser({
        user: values,
        type
      })
        .unwrap()
        .then(() => {
          enqueueSnackbar('Usuário Alterado', { variant: 'success' });
        })
        .catch((err) => {
          enqueueSnackbar(err.data.message, { variant: 'error' });
        });
    }
  });

  const loadForm = useCallback(
    async ({ data }: { data: User }) => {
      formik.resetForm();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any

      if (!isAdmin()) {
        const fUser = { ...(data as any) };
        formik.setValues(fUser);
      } else {
        const fUser = { ...(data as any)[0] };
        const _permissoes =
          fUser?.permissoes ?? (INITIAL_PERMISSAO as string[]);
        const array = INITIAL_PERMISSAO;

        array.forEach((_, index) => {
          if (_permissoes[index]) {
            array[index] = _permissoes[index];
          } else {
            array[index] = INITIAL_PERMISSAO[index];
          }
        });
        fUser.permissoes = array;
        // fUser.senha = '';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formik.setValues(fUser);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (user) loadForm({ data: user });
  }, [loadForm, user]);

  useEffect(() => {
    if (status.isSuccess) {
      enqueueSnackbar('Usuário Alterado', { variant: 'success' });
    }
    if (status.error) {
      enqueueSnackbar('Error ao Alterar Usuário', { variant: 'error' });
    }
  }, [enqueueSnackbar, status.error, status.isSuccess]);

  function getInitialPermissoes() {
    const array = [] as string[];
    INITIAL_PERMISSAO.forEach((permissao, index) => {
      array[index] = '_______';
    });
    return array;
  }

  function getAllPermissoes() {
    const array = [] as string[];
    ALL_PERMISSAO.forEach((permissao, index) => {
      array[index] = permissao;
    });
    return array;
  }

  function handleResetPermissoes() {
    const newPermissoes = getInitialPermissoes();
    enqueueSnackbar('Todas as Permissões foram removidas', { variant: 'info' });
    formik.values.permissoes = newPermissoes;
  }

  function handleAllPermissoes() {
    const newPermissoes = getAllPermissoes();
    enqueueSnackbar('Todas as Permissões foram aplicadas', { variant: 'info' });
    formik.values.permissoes = newPermissoes;
  }

  if (isFetching || isLoading) {
    return <PageLoading />;
  }

  return (
    <Inner>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{ my: 1 }}
          display="flex"
          justifyContent="space-between"
          alignItems={'center'}>
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb">
            <Typography>Cadastro</Typography>
            <Link color="inherit" to={AppConfig.routes.cadUser}>
              <Typography>Usuários</Typography>
            </Link>
            <Typography>Alterar</Typography>
            <Chip label={formik.values.nome} />
          </Breadcrumbs>
          <Box display={'flex'} gap={2}>
            <Button
              type="button"
              component={Link}
              to={
                isAdmin()
                  ? AppConfig.routes.cadUser
                  : AppConfig.routes.dashboard
              }
              startIcon={<ReplyAllIcon />}
              variant="outlined">
              Voltar
            </Button>

            {/* excluir */}
            {/* <>
              <IconButton
                onClick={handleClick}
                color="error"
                aria-describedby={id}
                aria-label="delete"
                data-testid="delete-button"
                title="Excluir">
                <Delete />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}>
                <Button onClick={handleDelete} variant="text">
                  Excluir?
                </Button>
              </Popover>
            </> */}
            <Button type="submit" startIcon={<SaveIcon />} variant="contained">
              Salvar
            </Button>
          </Box>
        </Box>
        <Box height="80vh" padding="10px 0">
          <UserForm
            isLoading={isFetching}
            formik={formik}
            reset={handleResetPermissoes}
            applyAll={handleAllPermissoes}
          />
        </Box>
      </form>
    </Inner>
  );
}
