import { NavigateNext } from '@mui/icons-material';
import { Box, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import {
  DataGrid,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid';

import { useNavigate } from 'react-router-dom';
import AppConfig from '../../config/app';
import { OUTLET_HEIGHT } from '../../config/theme';
import { Situacao } from './models';
import { useGetSituacaosQuery } from './apiSlice';
import Paper from '@mui/material/Paper';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Inner = styled('div')(({ theme }) => ({
  height: OUTLET_HEIGHT
}));

export default function Situacaos() {
  const { isLoading, data } = useGetSituacaosQuery(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    {},
    {
      refetchOnMountOrArgChange: true
    }
  );
  const navigate = useNavigate();

  function mapDataToGridRows(data: Situacao[]) {
    return data.map((d) => ({
      id: d.id,
      nome: d.nome,
      tela: d.tela,
      corFundo: d.corFundo,
      corLetra: d.corLetra,
      style: d.style
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // function RenderActionsCell(params: GridRenderCellParams) {
  //   const [deleteSituacao, status] = useDeleteSituacaoMutation();
  //   const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  //   const { enqueueSnackbar } = useSnackbar();
  //   const navigate = useNavigate();

  //   // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   //   setAnchorEl(event.currentTarget);
  //   // };

  //   // const handleClose = () => {
  //   //   setAnchorEl(null);
  //   // };

  //   // const handleDelete = () => {
  //   //   deleteSituacao({ id: params.id as string });
  //   // };

  //   useEffect(() => {
  //     if (status.isSuccess) {
  //       enqueueSnackbar('Situacao Excluído!', { variant: 'success' });
  //     }
  //     if (status.error) {
  //       enqueueSnackbar('Error ao Excluir Situacao', { variant: 'error' });
  //     }
  //   }, [enqueueSnackbar, status.error, status.isSuccess]);

  //   const open = Boolean(anchorEl);
  //   const id = open ? 'simple-popover' : undefined;
  //   return (
  //     <Box sx={{ display: 'flex', alignItems: 'center' }}>
  //       <IconButton
  //         component={Link}
  //         color="secondary"
  //         to={AppConfig.routes.cadSituacaoEdit(params.id as string)}
  //         aria-label="edit"
  //         data-testid="edit-button"
  //         title="Editar">
  //         <Edit />
  //       </IconButton>
  //       {/* <IconButton
  //         onClick={handleClick}
  //         color="error"
  //         aria-describedby={id}
  //         aria-label="delete"
  //         data-testid="delete-button"
  //         title="Excluir">
  //         <Delete />
  //       </IconButton>
  //       <Popover
  //         id={id}
  //         open={open}
  //         anchorEl={anchorEl}
  //         onClose={handleClose}
  //         anchorOrigin={{
  //           vertical: 'bottom',
  //           horizontal: 'left'
  //         }}>
  //         <Button onClick={handleDelete} variant="text">
  //           Excluir?
  //         </Button>
  //       </Popover> */}
  //     </Box>
  //   );
  // }

  return (
    <Inner>
      <Box display="flex" justifyContent="space-between">
        <Breadcrumbs
          sx={{ py: 2 }}
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb">
          <Typography>Cadastro</Typography>
          <Typography fontWeight={'bold'}>Situações</Typography>
        </Breadcrumbs>
        {/* <Button
          startIcon={<Add />}
          component={Link}
          variant="contained"
          to={AppConfig.routes.cadSituacaoInsert}>
          Inserir
        </Button> */}
      </Box>
      <Paper>
        <Box height="78vh">
          <DataGrid
            sx={{
              '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                fontSize: 12
              },
              fontSize: 12
            }}
            loading={isLoading}
            paginationMode="server"
            rows={data ? mapDataToGridRows(data) : []}
            columns={[
              {
                field: 'nome',
                headerName: 'Nome',
                flex: 1,
                sortable: true,
                editable: false,
                filterable: false,
                hideable: false,
                renderCell(params: GridRenderCellParams) {
                  return <Typography>{params.value}</Typography>;
                },
                disableColumnMenu: true
              },
              {
                field: 'tela',
                headerName: 'Tela',
                width: 40,
                sortable: true,
                editable: false,
                filterable: false,
                hideable: false,
                renderCell(params: GridRenderCellParams) {
                  return <Typography>{params.value}</Typography>;
                },
                disableColumnMenu: true
              },
              {
                field: 'id',
                headerName: 'Cores de Destaque',
                width: 200,
                sortable: true,
                editable: false,
                filterable: false,
                hideable: false,
                disableColumnMenu: true,
                renderCell: (params: GridRenderCellParams) => (
                  <Typography
                    sx={{
                      width: '100%',
                      borderRadius: '15px',
                      backgroundColor: params.row.corFundo,
                      color: params.row.corLetra,
                      px: 2,
                      py: 1,
                      textAlign: 'center'
                    }}>
                    {params.row.nome}
                  </Typography>
                )
              }
            ]}
            pageSize={10}
            onRowDoubleClick={(params: GridRowParams) =>
              navigate(AppConfig.routes.cadSituacaoEdit(params.id as string))
            }
          />
        </Box>
      </Paper>
    </Inner>
  );
}
