import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

interface LayoutState {
  isSidebarOpen: boolean;
  currentDrawerWidth?: number;
  cotacaoItensChanged?: boolean;
  cotacaoItensChangedModal?: boolean;
}

const initialState: LayoutState = {
  isSidebarOpen: false,
  currentDrawerWidth: 0,
  cotacaoItensChanged: false,
  cotacaoItensChangedModal: false
};
const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      state.isSidebarOpen = action.payload;
    },
    setDrawerWidth: (state, action) => {
      state.currentDrawerWidth = action.payload;
    },
    setCotacaoItensChanged: (state, action) => {
      state.cotacaoItensChanged = action.payload;
    },
    setCotacaoItensChangedModal: (state, action) => {
      state.cotacaoItensChangedModal = action.payload;
    }
  }
});
export const {
  toggleSidebar,
  setDrawerWidth,
  setCotacaoItensChanged,
  setCotacaoItensChangedModal
} = layoutSlice.actions;

export const selectIsSidebarOpen = (state: RootState) =>
  state.layout.isSidebarOpen;
export const selectCurrentDrawerWidth = (state: RootState) =>
  state.layout.currentDrawerWidth;
export const selectCotacaoItensChanged = (state: RootState) =>
  state.layout.cotacaoItensChanged;
export const selectCotacaoItensChangedModal = (state: RootState) =>
  state.layout.cotacaoItensChangedModal;

export default layoutSlice.reducer;
