import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  TextField
} from '@mui/material';
import { Departamento } from './models';
import { FormikProps } from 'formik';
import Paper from '@mui/material/Paper';

type Props = {
  formik: FormikProps<Departamento>;
  isLoading?: boolean;
};

export function DepartamentoForm({ formik, isLoading = false }: Props) {
  return (
    <Paper>
      <Box height="78vh" p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={Boolean(formik.touched.nome && formik.errors.nome)}>
              <TextField
                name="nome"
                label="Descrição"
                disabled={isLoading}
                inputProps={{ 'data-testid': 'nome' }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nome}
              />
              {formik.touched.nome && formik.errors.nome && (
                <FormHelperText error id="error-nome">
                  {formik.errors.nome}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={Boolean(
                formik.touched.numeroSerie && formik.errors.numeroSerie
              )}>
              <TextField
                name="numeroSerie"
                label="Número de Série"
                disabled={isLoading}
                inputProps={{ 'data-testid': 'numeroSerie' }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.numeroSerie}
              />
              {formik.touched.numeroSerie && formik.errors.numeroSerie && (
                <FormHelperText error id="error-numeroSerie">
                  {formik.errors.numeroSerie}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
