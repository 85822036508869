import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { CotacaoFornecedor, CotacaoItem } from '../models';
import { Edit, QueryStats, Star, Visibility } from '@mui/icons-material';
import CircularProgressLabel from '../../../components/CircularProgressLabel';
import { useLocation, useNavigate } from 'react-router-dom';
import AppConfig from '../../../config/app';
import PageLoading from '../../../components/PageLoading';
import ChipPedido from '../common/chipPedido';
// import ModalUpdateItem from './modalUpdateItem';
// import DataGridItemsRequisicao from './dataGridItemsRequisicao';

interface Props {
  value: number;
  index: number;
  cotacaoId: number;
  fornecedores: CotacaoFornecedor[];
  situacao: number;
  items: CotacaoItem[];
  loading?: boolean;
}

export default function TabPanelItemsList({
  index,
  value,
  cotacaoId,
  situacao,
  fornecedores,
  items,
  loading = false
}: Props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  function goToDigitarCotacao(fornecedorId: number) {
    const id = String(cotacaoId);
    const fId = String(fornecedorId);
    navigate(AppConfig.routes.cadCotacaoEditDigitar(id, fId), {
      state: { path: pathname.replace('//', '/'), tab: index }
    });
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}>
      {!loading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            px: 2,
            py: 2,
            overflowY: 'auto',
            height: '72vh'
          }}>
          {fornecedores.map((fornecedor) => {
            const filter = items.filter(
              (item) => fornecedor.fornecedor.id === item.fornecedor.id
            );

            const total = filter.length;

            const totalCotado = filter.filter(
              (item) =>
                item.situacao.id === 215 ||
                item.situacao.id === 220 ||
                item.situacao.id === 230
            ).length;

            const totalGeral = filter.reduce((acc, item) => {
              if (item.fornecedor.id !== fornecedor.fornecedor.id) return acc;
              const precoComImposto = calculatePrecoComImposto(item);
              return acc + precoComImposto * item.qtde;
            }, 0);

            function calculatePrecoComImposto(item: CotacaoItem) {
              const preco = item.preco ?? 0;
              const icms = item.icms ? preco * (item.icms / 100) : 0;
              const ipi = item.ipi ? preco * (item.ipi / 100) : 0;
              const frete = item.frete ? preco * (item.frete / 100) : 0;
              const outrasDespesas = item.outrasDespesas
                ? preco * (item.outrasDespesas / 100)
                : 0;
              return preco + icms + ipi + frete + outrasDespesas;
            }

            return (
              <Paper
                key={fornecedor.id}
                sx={{
                  p: 2,
                  height: 70
                }}>
                <Grid container alignItems={'center'}>
                  <Grid
                    item
                    xs={4}
                    display={'flex'}
                    gap={1}
                    alignItems={'center'}>
                    <Typography
                      fontSize={12}
                      variant="h6"
                      component="span"
                      fontWeight={'bold'}>
                      {fornecedor.fornecedor.nome}
                    </Typography>
                    {(fornecedor as any).pedido && (
                      <ChipPedido pedidoId={(fornecedor as any).pedido} />
                    )}
                  </Grid>
                  <Grid item display={'flex'} gap={1} xs={2}>
                    <Typography fontSize={12} variant="h6" component="span">
                      Cotadas:
                    </Typography>
                    <Typography
                      fontSize={12}
                      fontWeight={'bold'}
                      variant="h6"
                      component="span">
                      {totalCotado}/{total}
                    </Typography>
                    {/* <Divider orientation="vertical" flexItem /> */}
                  </Grid>
                  <Grid item display={'flex'} xs={2}>
                    <CircularProgressLabel
                      color="success"
                      value={Math.round((totalCotado / total) * 100)}
                    />
                    {/* <Divider orientation="vertical" flexItem /> */}
                  </Grid>
                  <Grid item display={'flex'} gap={1} xs={2}>
                    <Typography fontSize={12} variant="h6" component="span">
                      Total:
                    </Typography>
                    <Typography
                      fontSize={12}
                      fontWeight={'bold'}
                      variant="h6"
                      component="span">
                      {(totalGeral ?? 0).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      })}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      color={!fornecedor.pedidoGerado ? 'success' : 'secondary'}
                      startIcon={
                        !fornecedor.pedidoGerado ? (
                          <QueryStats />
                        ) : (
                          <Visibility />
                        )
                      }
                      onClick={() =>
                        goToDigitarCotacao(fornecedor.fornecedor.id as number)
                      }>
                      {!fornecedor.pedidoGerado ? 'Cotar' : 'Visualizar'}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
        </Box>
      ) : (
        <PageLoading minHeight={70} />
      )}
    </div>
  );
}
