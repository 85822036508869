import { NavigateNext } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

import { Link } from 'react-router-dom';
import AppConfig from '../../../config/app';
import { OUTLET_HEIGHT } from '../../../config/theme';
import {
  formRequisicaoValidationSchemaInsert,
  REQUISICAO_INITIAL_STATE
} from '../models';
import { useCreateRequisicaoMutation } from '../apiSlice';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useRequisicao } from '../context';
import { RequisicaoFormInsert } from './formInsert';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Inner = styled('div')(({ theme }) => ({
  height: OUTLET_HEIGHT
}));

export default function RequisicaoInsert() {
  const { enqueueSnackbar } = useSnackbar();
  const { filterRequisicoes } = useRequisicao();
  const [createRequisicao, status] = useCreateRequisicaoMutation();

  const formik = useFormik({
    initialValues: REQUISICAO_INITIAL_STATE,
    validationSchema: formRequisicaoValidationSchemaInsert,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSubmit: (values: any) => {
      const newValues = { ...values };
      delete newValues.id;
      newValues.solicitanteId = newValues.solicitante.id as unknown as number;
      delete newValues.solicitante;
      newValues.departamentoId = newValues.departamento.id as unknown as number;
      delete newValues.departamento;
      newValues.situacaoId = newValues.situacao.id as unknown as number;
      delete newValues.situacao;
      newValues.prioridadeId = newValues.prioridade.id as unknown as number;
      delete newValues.prioridade;
      newValues.local = newValues?.local?.replace(RegExp(/'|\(|\)/, 'g'), '');
      createRequisicao(newValues).then(() => {
        filterRequisicoes();
      });
    }
  });

  useEffect(() => {
    if (status.isSuccess) {
      enqueueSnackbar('Requisicao criado com sucesso', { variant: 'success' });
      formik.resetForm();
    }
    if (status.error) {
      enqueueSnackbar('Error ao criar Requisicao', { variant: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, status.error, status.isSuccess]);

  return (
    <Inner>
      <form autoComplete="off">
        <Box display="flex" justifyContent="space-between">
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb">
            <Link color="inherit" to={AppConfig.routes.cadRequisicao}>
              <Typography>Requisições</Typography>
            </Link>
            <Typography>Inserir</Typography>
          </Breadcrumbs>
          <Box display={'flex'} gap={2}>
            <Button
              type="button"
              component={Link}
              to={AppConfig.routes.cadRequisicao}
              startIcon={<ReplyAllIcon />}
              variant="outlined">
              Voltar
            </Button>
            <Button
              onClick={() => formik.handleSubmit()}
              startIcon={<SaveIcon />}
              variant="contained">
              Salvar
            </Button>
          </Box>
        </Box>
        <Box height="80vh" padding="10px 0">
          <RequisicaoFormInsert
            isLoading={status.isLoading}
            formik={formik}
            findProdutoAction={(produto, local) => {
              formik.setFieldValue('tipoReq', 'P');
              formik.setFieldValue('codigoSgi', produto.codigo);
              formik.setFieldValue(
                'descricaoProdutoServico',
                produto.descricao
              );
              formik.setFieldValue('unidade', produto.unidade);
              formik.setFieldValue('local', local);
            }}
          />
        </Box>
      </form>
    </Inner>
  );
}
