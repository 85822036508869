import { CssBaseline, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { Fragment, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider
} from 'react-router-dom';
import Page404 from '../components/Page404';
import AppConfig from '../config/app';
import Login from '../features/auth/Login';
import RequireAdmin from '../features/auth/RequireAdmin';
import RequireAuth from '../features/auth/RequireAuth';
import Dashboard from '../features/dashboard';
import Departamento from '../features/departamento';
import DepartamentoEdit from '../features/departamento/edit';
import DepartamentoInsert from '../features/departamento/insert';
import { setEmpresa } from '../features/empresa/storeSlice';
import Prioridade from '../features/prioridade';
import PrioridadeEdit from '../features/prioridade/edit';
// import PrioridadeInsert from '../features/prioridade/insert';
import Requisicao from '../features/requisicao/list';
import { RequisicaoProvider } from '../features/requisicao/context';
import RequisicaoEdit from '../features/requisicao/edit';
import RequisicaoInsert from '../features/requisicao/insert';
import Situacao from '../features/situacao';
import SituacaoEdit from '../features/situacao/edit';
// import SituacaoInsert from '../features/situacao/insert';
import Solicitante from '../features/solicitante';
import SolicitanteEdit from '../features/solicitante/edit';
import SolicitanteInsert from '../features/solicitante/insert';
import Users from '../features/users';
import UsersEdit from '../features/users/edit';
import UsersInsert from '../features/users/insert';
import { useAppTheme } from '../hooks/useAppTheme';
import Layout from '../layout';
import CotacaoList from '../features/cotacao/list';
import CotacaoInsert from '../features/cotacao/insert';
import Fornecedores from '../features/fornecedor';
import FornecedorInsert from '../features/fornecedor/insert';
import FornecedorEdit from '../features/fornecedor/edit';
import CotacaoEdit from '../features/cotacao/edit';
import { CotacaoProvider } from '../features/cotacao/context';
import { PedidoProvider } from '../features/pendencia/context';
import PendenciaList from '../features/pendencia/list';
import PendenciaEdit from '../features/pendencia/edit';
import RequirePermissao from '../features/auth/RequirePermissao';
import { EnumPermissao } from '../features/auth/models';
import Aprovacao from '../features/aprovacao/index';
import CotacaoExterno from '../features/cotacao/externo';
import CotacaoValida from '../features/cotacao/externo/cotacaoValida';
import CotacaoDefault from '../features/cotacao/externo/cotacaoDefault';
import CotacaoExpirada from '../features/cotacao/externo/cotacaoExpirada';
import CotacaoCancelada from '../features/cotacao/externo/cotacaoCancelada';
import CotacaoNaoLocalizada from '../features/cotacao/externo/cotacaoNaoLocalizada';
import CotacoesFornecedor from '../features/cotacao/externo/cotacoesFornecedor';
import { selectCurrentUser } from '../features/auth/authSlice';
import TabPanelItemsListDigitar from '../features/cotacao/edit/tabPanelItemsListDigitar';
import RequisicaoIndex from '../features/requisicao';
import CotacaoIndex from '../features/cotacao';
import PedidoIndex from '../features/pendencia';
import TabPanelResumeListGerarPedido from '../features/cotacao/edit/tabPanelResumeListGerarPedido';
import ConfigEdit from '../features/config';
import { ContextConfig } from '..';
import PageLoading from '../components/PageLoading';
import PageOffline from '../components/PageOffline';
import Categorias from '../features/categoria';
import CategoriaInsert from '../features/categoria/insert';
import CategoriaEdit from '../features/categoria/edit';
import { ProdutoProvider } from '../features/produto/context';
import ProdutoIndex from '../features/produto';
import ProdutoList from '../features/produto/list';
import ProdutoInsert from '../features/produto/insert';
import ProdutoEdit from '../features/produto/edit';
import Root from './Root';

export default function AppRouter() {
  const [currentTheme] = useAppTheme();
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const config = useContext(ContextConfig);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="" element={<Root />}>
        {/* rotas protegidas */}
        <Route
          path="/"
          element={
            <RequireAuth>
              <Layout
                currentTheme={currentTheme}
                // toggleCurrentTheme={toggleCurrentTheme}
              />
            </RequireAuth>
          }>
          <Route index element={<Dashboard />} />

          {/* cadastro de usuarios */}
          <Route
            path={AppConfig.routes.cadUser}
            element={
              user?.perfil && user.perfil === 'ADMIN' ? (
                <Users />
              ) : (
                <Navigate
                  to={user?.id ? AppConfig.routes.cadUserEdit(user.id) : '/'}
                  replace={true}
                />
              )
            }
          />
          <Route
            path={AppConfig.routes.cadUserInsert}
            element={
              <RequireAdmin>
                <UsersInsert />
              </RequireAdmin>
            }
          />
          <Route
            path={AppConfig.routes.cadUserEdit()}
            element={
              // <RequireAdmin>
              <UsersEdit />
              // </RequireAdmin>
            }
          />

          {/* cadastro de prioridades */}
          <Route
            path={AppConfig.routes.cadPrioridade}
            element={
              <RequirePermissao
                feature={EnumPermissao.PRIORIDADE}
                permissoes="r">
                <Prioridade />
              </RequirePermissao>
            }
          />
          {/* <Route
                path={AppConfig.routes.cadPrioridadeInsert}
                element={
                  <RequireAdmin>
                    <PrioridadeInsert />
                  </RequireAdmin>
                }
              /> */}
          <Route
            path={AppConfig.routes.cadPrioridadeEdit()}
            element={
              <RequirePermissao
                feature={EnumPermissao.PRIORIDADE}
                permissoes="u">
                <PrioridadeEdit />
              </RequirePermissao>
            }
          />

          {/* cadastro de situações */}
          <Route
            path={AppConfig.routes.cadSituacao}
            element={
              <RequirePermissao feature={EnumPermissao.SITUACAO} permissoes="r">
                <Situacao />
              </RequirePermissao>
            }
          />
          {/* <Route
                path={AppConfig.routes.cadSituacaoInsert}
                element={
                  <RequireAdmin>
                    <SituacaoInsert />
                  </RequireAdmin>
                }
              /> */}
          <Route
            path={AppConfig.routes.cadSituacaoEdit()}
            element={
              <RequirePermissao feature={EnumPermissao.SITUACAO} permissoes="u">
                <SituacaoEdit />
              </RequirePermissao>
            }
          />

          {/* cadastro de departamentos */}
          <Route
            path={AppConfig.routes.cadDepartamento}
            element={
              <RequirePermissao
                feature={EnumPermissao.DEPARTAMENTO}
                permissoes="r">
                <Departamento />
              </RequirePermissao>
            }
          />
          <Route
            path={AppConfig.routes.cadDepartamentoInsert}
            element={
              <RequirePermissao
                feature={EnumPermissao.DEPARTAMENTO}
                permissoes="c">
                <DepartamentoInsert />
              </RequirePermissao>
            }
          />
          <Route
            path={AppConfig.routes.cadDepartamentoEdit()}
            element={
              <RequirePermissao
                feature={EnumPermissao.DEPARTAMENTO}
                permissoes="u">
                <DepartamentoEdit />
              </RequirePermissao>
            }
          />

          {/* cadastro de solicitante */}
          <Route
            path={AppConfig.routes.cadSolicitante}
            element={
              <RequirePermissao
                feature={EnumPermissao.SOLICITANTE}
                permissoes="r">
                <Solicitante />
              </RequirePermissao>
            }
          />
          <Route
            path={AppConfig.routes.cadSolicitanteInsert}
            element={
              <RequirePermissao
                feature={EnumPermissao.SOLICITANTE}
                permissoes="c">
                <SolicitanteInsert />
              </RequirePermissao>
            }
          />
          <Route
            path={AppConfig.routes.cadSolicitanteEdit()}
            element={
              <RequirePermissao
                feature={EnumPermissao.SOLICITANTE}
                permissoes="u">
                <SolicitanteEdit />
              </RequirePermissao>
            }
          />

          {/* cadastro de categorias */}
          <Route
            path={AppConfig.routes.cadCategoria}
            element={
              <RequirePermissao
                feature={EnumPermissao.CATEGORIA}
                permissoes="r">
                <Categorias />
              </RequirePermissao>
            }
          />
          <Route
            path={AppConfig.routes.cadCategoriaInsert}
            element={
              <RequirePermissao
                feature={EnumPermissao.CATEGORIA}
                permissoes="c">
                <CategoriaInsert />
              </RequirePermissao>
            }
          />
          <Route
            path={AppConfig.routes.cadCategoriaEdit()}
            element={
              <RequirePermissao
                feature={EnumPermissao.CATEGORIA}
                permissoes="u">
                <CategoriaEdit />
              </RequirePermissao>
            }
          />

          {/* cadastro de fornecedores */}
          <Route
            path={AppConfig.routes.cadFornecedor}
            element={
              <RequirePermissao
                feature={EnumPermissao.FORNECEDOR}
                permissoes="r">
                <Fornecedores />
              </RequirePermissao>
            }
          />
          <Route
            path={AppConfig.routes.cadFornecedorInsert}
            element={
              <RequirePermissao
                feature={EnumPermissao.FORNECEDOR}
                permissoes="c">
                <FornecedorInsert />
              </RequirePermissao>
            }
          />
          <Route
            path={AppConfig.routes.cadFornecedorEdit()}
            element={
              <RequirePermissao
                feature={EnumPermissao.FORNECEDOR}
                permissoes="u">
                <FornecedorEdit />
              </RequirePermissao>
            }
          />

          {/* cadastro de configurações */}
          {/* somente admin */}
          <Route
            path={AppConfig.routes.config}
            element={
              <RequireAdmin>
                <ConfigEdit />
              </RequireAdmin>
            }
          />

          {/* produtos */}
          <Route
            path={AppConfig.routes.cadProduto}
            element={
              <ProdutoProvider>
                <RequirePermissao
                  feature={EnumPermissao.PRODUTO}
                  permissoes="r">
                  <ProdutoIndex />
                </RequirePermissao>
              </ProdutoProvider>
            }>
            <Route index element={<ProdutoList />} />
            <Route
              path={AppConfig.routes.cadProdutoInsert}
              element={
                <RequirePermissao
                  feature={EnumPermissao.PRODUTO}
                  permissoes="c">
                  <ProdutoInsert />
                </RequirePermissao>
              }
            />
            <Route
              path={AppConfig.routes.cadProdutoEdit()}
              element={
                <RequirePermissao
                  feature={EnumPermissao.PRODUTO}
                  permissoes="u">
                  <ProdutoEdit />
                </RequirePermissao>
              }
            />
          </Route>

          {/* requisições */}
          <Route
            path={AppConfig.routes.cadRequisicao}
            element={
              <RequisicaoProvider>
                <RequirePermissao
                  feature={EnumPermissao.REQUISICAO}
                  permissoes="r">
                  <RequisicaoIndex />
                </RequirePermissao>
              </RequisicaoProvider>
            }>
            <Route
              path={AppConfig.routes.cadRequisicao}
              element={
                <RequirePermissao
                  feature={EnumPermissao.REQUISICAO}
                  permissoes="r">
                  <Requisicao />
                </RequirePermissao>
              }></Route>
            <Route
              path={AppConfig.routes.cadRequisicaoInsert}
              element={
                <RequirePermissao
                  feature={EnumPermissao.REQUISICAO}
                  permissoes="c">
                  <RequisicaoInsert />
                </RequirePermissao>
              }
            />
            <Route
              path={AppConfig.routes.cadRequisicaoEdit()}
              element={
                <RequirePermissao
                  feature={EnumPermissao.REQUISICAO}
                  permissoes="u">
                  <RequisicaoEdit />
                </RequirePermissao>
              }
            />
          </Route>
          {/* aprovação */}
          <Route
            path={AppConfig.routes.cadAprovacao}
            element={
              <RequirePermissao
                feature={EnumPermissao.REQUISICAO}
                permissoes="ra">
                <Aprovacao />
              </RequirePermissao>
            }
          />

          {/* cotações */}
          <Route
            path={AppConfig.routes.cadCotacao}
            element={
              <CotacaoProvider>
                <CotacaoIndex />
              </CotacaoProvider>
            }>
            <Route
              path={AppConfig.routes.cadCotacao}
              element={
                <RequirePermissao
                  feature={EnumPermissao.COTACAO}
                  permissoes="r">
                  <CotacaoList />
                </RequirePermissao>
              }
            />

            <Route
              path={AppConfig.routes.cadCotacaoInsert}
              element={
                <RequirePermissao
                  feature={EnumPermissao.COTACAO}
                  permissoes="c">
                  <CotacaoInsert />
                </RequirePermissao>
              }
            />

            <Route
              path={AppConfig.routes.cadCotacaoEdit()}
              element={
                <RequirePermissao
                  feature={EnumPermissao.COTACAO}
                  permissoes="u">
                  <CotacaoEdit />
                </RequirePermissao>
              }
            />

            <Route
              path={AppConfig.routes.cadCotacaoEditDigitar()}
              element={
                <RequirePermissao
                  feature={EnumPermissao.COTACAO}
                  permissoes="u">
                  <TabPanelItemsListDigitar />
                </RequirePermissao>
              }
            />
            <Route
              path={AppConfig.routes.cadCotacaoEditGerarPedido()}
              element={
                <RequirePermissao
                  feature={EnumPermissao.COTACAO}
                  permissoes="u">
                  <TabPanelResumeListGerarPedido />
                </RequirePermissao>
              }
            />
          </Route>
          {/* pendencias */}
          <Route
            path={AppConfig.routes.cadPendencia}
            element={
              <PedidoProvider>
                <PedidoIndex />
              </PedidoProvider>
            }>
            <Route
              path={AppConfig.routes.cadPendencia}
              element={
                <RequirePermissao
                  feature={EnumPermissao.PENDENCIA}
                  permissoes="r">
                  <PendenciaList />
                </RequirePermissao>
              }
            />

            <Route
              path={AppConfig.routes.cadPendenciaEdit()}
              element={
                <RequirePermissao
                  feature={EnumPermissao.PENDENCIA}
                  permissoes="u">
                  <PendenciaEdit />
                </RequirePermissao>
              }
            />
          </Route>
        </Route>

        <Route
          path={AppConfig.routes.cotacaoExterno}
          element={<CotacaoExterno />}>
          <Route index element={<CotacaoDefault />} />
          <Route
            path={AppConfig.routes.cotacaoValidaExterno}
            element={<CotacaoValida />}
          />
          <Route
            path={AppConfig.routes.cotacaoExpiradaExterno()}
            element={<CotacaoExpirada />}
          />
          <Route
            path={AppConfig.routes.cotacaoCanceladaExterno}
            element={<CotacaoCancelada />}
          />
          <Route
            path={AppConfig.routes.cotacaoNaoLocalizadaExterno}
            element={<CotacaoNaoLocalizada />}
          />
          <Route
            path={AppConfig.routes.cotacoesFornecedorExterno}
            element={<CotacoesFornecedor />}
          />
        </Route>
        <Route path={AppConfig.routes.login} element={<Login />} />
        <Route path={'*'} element={<Page404 />} />
      </Route>
    )
  );

  useEffect(() => {
    if (config) {
      dispatch(setEmpresa(config));
    }
  }, [config, dispatch]);

  if (config.loading) {
    return (
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        <PageLoading />
      </ThemeProvider>
    );
  }

  if (config.error) {
    return (
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        <PageOffline />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      <SnackbarProvider
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        preventDuplicate={true}>
        <RouterProvider router={router} />
      </SnackbarProvider>
    </ThemeProvider>
  );
}
