import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CalendarMonth } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Stack, TextField } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30vw',
  bgcolor: 'background.default',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'hidden',
  borderRadius: 1
};

interface Props {
  action?: (validade: string) => void;
  disabled?: boolean;
}

export default function DefinirValidadeModal({
  action,
  disabled = false
}: Props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { enqueueSnackbar } = useSnackbar();
  const title = 'Definir Validade';
  const [value, setValue] = useState<string | null>(null);

  // useEffect(() => {
  //   ref.current?.focus();
  //   ref.current?.select();
  //   // ref.current?.value = '123' ?? '';
  // }, [open]);

  return (
    <Box>
      <Button
        startIcon={<CalendarMonth />}
        disabled={disabled}
        variant="contained"
        size="small"
        color="primary"
        onClick={handleOpen}>
        {title}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableRestoreFocus={true}>
        <Box sx={style}>
          <Typography
            bgcolor={'primary.main'}
            color={'primary.contrastText'}
            px={2}
            py={1}
            id="modal-modal-title"
            variant="h6"
            component="h4">
            {title}
          </Typography>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="pt-br">
            <Stack direction={'row'} justifyContent={'space-between'} p={2}>
              <DatePicker
                // PopperProps={{
                //   role: 'dialog'
                // }}
                value={value ?? null}
                onChange={(newValue) => {
                  setValue(newValue?.toString() ?? null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    size="small"
                    label="Data da Validade"
                    variant="outlined"
                    sx={() => ({
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: 'common.white'
                      }
                    })}
                  />
                )}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (value) {
                    action?.(value);
                    handleClose();
                  } else {
                    enqueueSnackbar('Informe a data da validade', {
                      variant: 'warning'
                    });
                  }
                }}>
                Confirmar
              </Button>
            </Stack>
          </LocalizationProvider>
        </Box>
      </Modal>
    </Box>
  );
}
