import { ExitToApp, Check } from '@mui/icons-material';
import { Box, Modal, Typography, Button } from '@mui/material';
import { createElement, useState } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30vw',
  bgcolor: 'background.default',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'hidden',
  borderRadius: 1
};

interface ConfirmModalProps {
  component: JSX.Element;
  action: () => void;
  title?: string;
  description?: string;
}

export default function ConfirmModal({
  component,
  action,
  title,
  description
}: ConfirmModalProps) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      {createElement(component.type, {
        ...component.props,
        onClick: handleOpen
      })}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableRestoreFocus={true}>
        <Box sx={style}>
          <Typography
            bgcolor={'primary.main'}
            color={'primary.contrastText'}
            px={2}
            py={1}
            id="modal-modal-title"
            variant="h6"
            component="h4">
            {title ?? 'Confirmação'}
          </Typography>
          <Box p={2} display={'flex'} justifyContent={'space-between'}>
            <Typography sx={{ flex: 1 }}>
              {description ?? 'Deseja confirmar a ação?'}
            </Typography>
          </Box>
          <Box p={2} display={'flex'} justifyContent={'space-between'}>
            <Button
              startIcon={<ExitToApp />}
              variant="contained"
              color="inherit"
              onClick={() => {
                handleClose();
              }}>
              Fechar
            </Button>
            <Button
              variant="contained"
              startIcon={<Check />}
              color="success"
              onClick={() => {
                action?.();
                handleClose();
              }}>
              Confirmar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
