import { useSelector } from 'react-redux';
import { selectCurrentUser } from './authSlice';
import { Typography, Box } from '@mui/material';
import { ReactComponent as ImgOnlyAdmin } from '../../assets/svg/only-admin.svg';

const RequireAdmin = ({ children }: { children: JSX.Element }) => {
  const user = useSelector(selectCurrentUser);
  if (user.perfil === 'ADMIN') {
    return children;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '80vh'
      }}>
      <ImgOnlyAdmin height={300} />
      <Typography color={'red'} variant="h3">
        Somente Administradores
      </Typography>
    </Box>
  );
};
export default RequireAdmin;
