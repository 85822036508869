import { Stack, Typography } from '@mui/material';
import React from 'react';
import Countdown from 'react-countdown';

interface Props {
  limitTime: number;
}

export default function CountdownComp({ limitTime }: Props) {
  // console.log(
  //   '🚀 ~ file: CountdownComp.tsx:10 ~ CountdownComp ~ limitTime:',
  //   new Date(limitTime).toLocaleDateString('pt-BR', {
  //     day: '2-digit',
  //     month: 'long',
  //     year: 'numeric',
  //     hour: '2-digit',
  //     minute: '2-digit'
  //   })
  // );

  return (
    <Countdown
      date={limitTime}
      renderer={({ days, hours, minutes, seconds }) => (
        <Stack direction={'row'} gap={2}>
          {/* days */}
          <Stack
            direction={'column'}
            alignItems={'center'}
            sx={{
              backgroundColor: 'primary.main',
              color: 'common.white',
              borderRadius: 1,
              py: 0.5,
              px: 2
            }}>
            <Typography fontSize={10}>Dias</Typography>
            <Typography fontSize={16} fontWeight={'bold'}>
              {days}
            </Typography>
          </Stack>
          {/* hours */}
          <Stack
            direction={'column'}
            alignItems={'center'}
            sx={{
              backgroundColor: 'primary.main',
              color: 'common.white',
              borderRadius: 1,
              py: 0.5,
              px: 2
            }}>
            <Typography fontSize={10}>Horas</Typography>
            <Typography fontSize={16} fontWeight={'bold'}>
              {hours}
            </Typography>
          </Stack>

          {/* minutes */}
          <Stack
            direction={'column'}
            alignItems={'center'}
            sx={{
              backgroundColor: 'primary.main',
              color: 'common.white',
              borderRadius: 1,
              py: 0.5,
              px: 2
            }}>
            <Typography fontSize={10}>Min</Typography>
            <Typography fontSize={16} fontWeight={'bold'}>
              {minutes}
            </Typography>
          </Stack>

          {/* seconds */}
          <Stack
            direction={'column'}
            alignItems={'center'}
            sx={{
              backgroundColor: 'primary.main',
              color: 'common.white',
              borderRadius: 1,
              py: 0.5,
              px: 2
            }}>
            <Typography fontSize={10}>Seg</Typography>
            <Typography fontSize={16} fontWeight={'bold'}>
              {seconds}
            </Typography>
          </Stack>
        </Stack>
      )}
    />
  );
}
