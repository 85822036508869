import { Star } from '@mui/icons-material';
import { Chip, Typography } from '@mui/material';

interface Props {
  pedidoId: number | string;
}

export default function ChipPedido({ pedidoId }: Props) {
  return (
    <Chip
      size="small"
      icon={<Star color={'success'} />}
      label={
        <Typography fontSize={8} variant="caption">
          Ped: {pedidoId}
        </Typography>
      }
      variant="outlined"
    />
  );
}
